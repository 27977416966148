<template>

</template>
<script>
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
import { Dialog } from 'vant';
export default {
  name: 'success',
  data() {
    return {
      username: '',
      sex:'',
      password: '',
      provincecity:'',
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      datetime: '',
      showPicker: false,
      showPicker2: false,
      number:100,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      sex: '女',
      loading:false,
      userInfo:{}
   };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
    birthday(){
        return this.$route.query.birthday ? this.$route.query.birthday : ''
    },
    sex(){
        return this.$route.query.sex ? this.$route.query.sex : ''
    },
    Id(){
        return this.$route.query.Id ? this.$route.query.Id : 0
    }
  },
  mounted(){
    if(this.isweixin){
        if(!this.unionid){
            if(!this.code){
                var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/userRegister')
                window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
            } else {
                this.getJsLogin()
            }
        }
    }
  },
/*函数*/
methods: {
    getJsLogin(){
        if(this.isweixin){
            const url = '/user/jslogin'
            postAction(url, {code:this.code}).then( res => {
            if(res.data.code == 200 || res.data.code == 400){
                this.userInfo = res.data.msg
                // render_words({"jsopenid": r_json['openid'], "unionid": unionid,"nickname":nickname,"image":image, "birthday": login_old.birthday, "sex": login_old.sex}, code=200)
                this.$store.commit('setUserInfoLove', this.userInfo)
                }
            })
        }
    },
    //验证是不是微信
    isweixin(){
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true
        } else {
            return false
        }
    },
    //支付接口
    gotoPay(){
    const url = '/'
    var that = this
    getAction(url, {number: this.number*100, openid: this.openid}).then( res => {
        if(res.data.code === 200){
            const result = res.data.msg.result
            wx.config({
            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: result.appId, // 必填，公众号的唯一标识
            timestamp: result.timeStamp, // 必填，生成签名的时间戳
            nonceStr: result.nonceStr, // 必填，生成签名的随机串
            signature: result.paySign, // 必填，签名
            jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
            })
            wx.ready(() => {
            wx.checkJsApi({
                jsApiList: ['chooseWXPay'],
                success:function(res){
                console.log("success")
                wx.chooseWXPay({
                    timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                    nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                    package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: result.paySign, // 支付签名
                    success: function (res) {  // 支付成功后的回调函数
                        alert("支付成功");
                    },
                    fail: function (res) {
                        alert("支付失败");
                    }
                    })
                },
                fail:function(res){
                console.log("fail");
                console.log(res) }
            })
            })
            wx.error(err => {
            Toast.fail(err)
            })
        }else{
            Toast.fail(res.data.msg);
        }
    })
    },
}
}
</script>
<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* 头部样式 */
header {
  background: #333;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
}

header h1 {
  margin: 0;
}

/* 导航栏样式 */
nav {
  background: #555;
  color: #fff;
  padding: 10px 20px;
}

nav ul {
  list-style: none;
}

nav li {
  display: inline;
  margin-right: 10px;
}

nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #ddd;
}

/* 内容区域样式 */
.content {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content h2 {
  margin-bottom: 10px;
}

/* 图片样式 */
img {
  max-width: 100%;
  height: auto;
}

/* 按钮样式 */
.button {
  display: inline-block;
  padding: 10px 20px;
  background: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.button:hover {
  background: #0056b3;
}

/* 表单样式 */
form {
  margin-top: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* 脚注样式 */
footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  margin-top: 20px;
}

footer p {
  margin: 0;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  nav ul {
    text-align: center;
  }

  nav li {
    display: block;
    margin-bottom: 10px;
  }
}
</style>
