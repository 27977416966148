<template>
    <div class="title-container relposition">
          <div class="row-start-col-center ml30 pt150">
             <img src="../assets/index.png" style="width:12px;height:26px" alt="">
             <div class="title2 ml5 mr5"><span class="fs26">2023</span>年度关键词</div>
          </div>
          <div class="row-start wrap pl20 pt30">
              <div class="width-12" v-for="(item,index) in dataList" :key="index">
                    <div class="small-card mt30" :style="transformDeg(index)" >
                        #{{item}}
                    </div>
              </div>
          </div>
          <div class="row-center-col-end mt60 abslutebottom">
                <img style="width:70px;height:70px" src="../assets/qrcode.jpg" alt="code_image"/>
                <div class="tl fwbold ml5">
                  <div>扫码领取</div>
                  <div>2023年度关键词</div>
                </div>
           </div>
    </div>
</template>
<script>
import { postAction } from '@/api/manage'
export default {
  components: {  },
  name: 'Login',
  data() {
    return {
       dataList:["财源滚滚","发财无限","云程发轫","蒸蒸日上"],
       userInfo:{}
    }
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    }
  },
  mounted () {
    if(!this.unionid){
      if(!this.code){
        var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/keyword')
        window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
         this.getJsLogin()
      }
    } else {
      console.log('有unionid')
      this.getDatalist()
    }

  },
  methods: {
    getJsLogin(){
        const url = '/xcxlove/double/jslogin'
        postAction(url, {code:this.code}).then( res => {
          if(res.data.code == 200){
              this.userInfo = res.data.msg
              this.$store.commit('setUserInfoLove', this.userInfo)
              this.getDatalist()
          } else if(res.data.code == 400) {
              this.userInfo = res.data.msg
              this.$router.push({path:'/userRegister'})
          }
        })
    },
    getDatalist(){
      const url = '/newyear/frontpage'
          postAction(url, {unionid:this.unionid}).then( res => {
            if(res.data.code == 200){
              this.dataList = res.data.msg
            } else if(res.data.code == 400){
              this.$router.push({path:'/userRegister'})
            }
      })
    },
    transformDeg(index){
        if(index == 0){
           return {'transform':'rotate(-10deg)','margin-left':'20px'}
        } else if(index == 1){
          return {'transform':'rotate(0deg)','font-size':'24px'}
        } else if(index == 2){
          return {'transform':'rotate(0deg)','margin-top':'50px'}
        } else if(index == 3){
          return {'transform':'rotate(10deg)','margin-top':'70px','font-size':'22px'}
        }
    }
  }
}
</script>
<style scoped>
.title-container{
    width: 100%;
    height: 95vh;
    background-image: url('../assets/tutu.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.title2{
  font-size: 22px;
  font-weight: bold;
  border-bottom-style: solid;
  border-color: rgb(255, 142, 79);
}
.small-card{
  width: 82%;
  font-size: 24px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 0px;
  border-radius: 20px;
}
.abslutebottom{
  position:absolute;
  bottom: 10px;
  left: 42%;
}
</style>
