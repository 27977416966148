<template>
  <div style="padding:0px 20px; background:#f5f5f5; height:100%">
      <div class="headercenter">
          <h2>自选列表</h2>
      </div>
      <div style="text-align:left;margin-bottom:10px">
          <van-button icon="plus" round size="small" type="info" @click="addPlan">添加自选</van-button>
      </div>
        <van-list
         v-model="listloading"
        :finished="finished"
         finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="row-start" v-for="(item,index) in codelist" :key="item.Id">
              <van-cell  :value="`${index+1}. 代码：${item.code}  (${item.codename}) `" @click="openDetail(item.code,item.codename)"  size="large">
              </van-cell>
              <van-button icon="cross" type="danger" @click="deleteCode(item.Id)" />
          </div>
        </van-list>
       <van-popup v-model="addvisible" closeable position="bottom" :style="{ height: '50%',padding:'10px' }" >
            <van-form @submit="onSubmit">
              <van-field
                v-model="code"
                name="代号"
                label="代号"
                placeholder="代号"
                :rules="[{ required: true, message: '请填写代号' }]"
              />
              <van-field
                v-model="codename"
                name="名称"
                label="名称"
                placeholder="名称"
              />
              <div style="margin: 16px;">
                  <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
              </div>
            </van-form>
       </van-popup>
       <van-popup v-model="detailvisible" closeable  position="bottom" :style="{ height: '70%',padding:'10px' }" >
          <h3>{{currentcode}}:{{currentcodename}}</h3>
          <el-button  v-if="tableData.length == 0" type="text" :loading="tableloading">数据加载中,请耐心等待</el-button>
          <div v-if="tableData.length !== 0" >
              <el-table
                  :data="tableData"
                  v-loading="tableloading"
                  style="width: 100%">
                  <el-table-column
                    prop="type"
                    label="项目"
                    align="center"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    prop="big"
                    label="大盘趋势"
                     align="center"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    prop="small"
                    label="个股趋势"
                     align="center"
                    width="120">
                  </el-table-column>
              </el-table>
          </div>
       </van-popup>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: 'Login',
  data() {
    return {
      listloading:false,
      loading: false,
      tableloading:false,
      addvisible:false,
      detailvisible:false,
      finished:false,
      codelist:[{Id:'0',code:'0',name:'0'}],
      code:'',
      codename:'',
      unionid:'111',
      detailform:{},
      tableData:[],
      userInfo:{},
      currentcode:'',
      currentcodename:''
    }
  },
  mounted(){
    if(!this.openid){
      this.getlogin()
    }
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
  },
  methods: {
    openDetail(code,codename){
      this.detailvisible = true
      this.currentcode = code
      this.currentcodename = codename
      this.tableData = []
      const url = '/code/choosecode/single/evaluation'
      this.$nextTick(() => {this.tableloading = true})
        postAction(url,{code:code}).then( res => {
            if( res.data.code == 200) {
                this.tableloading = false
                this.detailform = res.data.msg
                console.log('this.detailform',this.detailform)
                this.tableData[0] = {type:'date', big:this.detailform.big.date, small:this.detailform.small.date}
                this.tableData[1] = {type:'exp', big:this.detailform.big.exp??'-', small:this.detailform.small.exp??'-'}
                this.tableData[2] = {type:'hexp', big:this.detailform.big.hexp??'-', small:this.detailform.small.hexp??'-'}
                this.tableData[3] = {type:'minday', big:this.detailform.big.minday??'-', small:this.detailform.small.minday??'-'}
                this.tableData[4] = {type:'minimun', big:this.detailform.big.minimun??'-', small:this.detailform.small.minimun??'-'}
                this.tableData[5] = {type:'nearmin', big:this.detailform.big.nearmin??'-', small:this.detailform.small.nearmin??'-'}
                this.tableData[6] = {type:'nearminday', big:this.detailform.big.nearminday??'-', small:this.detailform.small.nearminday??'-'}
				this.tableData[7] = {type:'fexp', big:this.detailform.big.fexp??'-', small:this.detailform.small.fexp??'-'}
				this.tableData[8] = {type:'zdf', big:this.detailform.big.zdf??'-', small:this.detailform.small.zdf??'-'}
				this.tableData[9] = {type:'zf', big:this.detailform.big.zf??'-', small:this.detailform.small.zf??'-'}
				this.tableData[10] = {type:'up', big:this.detailform.big.up??'-', small:this.detailform.small.up??'-'}
                console.log('this.tableData',this.tableData)
            }
        }).finally(() => {
            this.tableloading = false
            this.finished = true
        })
    },
    addPlan(){
      this.addvisible = true
    },
    onLoad(){
      this.getCodeList()
    },
    deleteCode(id){
        var that = this
        Dialog.alert({
          title: '提示',
          message: '此操作将删除该条数据, 是否继续?',
        }).then(() => {
          const url = '/code/choosecode/single/deletecode'
          postAction(url, {Id:id}).then(res => {
            Toast.success('删除成功');
            that.getCodeList()
          })
        }).catch(() => {
           Toast.fail('取消删除');
        });
    },
    getCodeList(){
      const url = '/code/choosecode/single/list'
        postAction(url,{unionid:this.unionid}).then( res => {
        if( res.data.code == 200) {
            this.codelist = res.data.msg
        }
        }).finally(() => {
            this.finished = true
        })
    },
    onSubmit(){
        const url = '/code/choosecode/single/addcode'
        const params = {code: this.code,codename:this.codename,unionid:this.unionid}
        this.loading = true
        postAction( url, params).then( res => {
        this.loading = false
            if( res.data.code == 200) {
              Toast.success(res.data.msg);
              this.$message.success(res.data.msg)
              this.addvisible = false
              this.getCodeList()
            }
            else{
              Toast.fail(res.data.msg);
            }
        }).catch(() => {
            this.addvisible = false
            this.loading = false
        })
    },
    gotoCode(){
          window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=https%3A%2F%2Fonline.miaoxingjundd.com%2Fcode&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
    },
    getlogin(){
      if(this.$route.query.code){
        this.getOpenID()
      } else {
        this.gotoCode()
      }
    },
    getOpenID(){
      const url = '/paipan/vxlogin'
          postAction(url, {code:this.$route.query.code}).then( res => {
            this.userInfo = res.data.data
            this.$store.commit('setUserInfoLove', this.userInfo)
          })
    }
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>

