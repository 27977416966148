<template>
  <div class="container">
        <van-notice-bar>
            欢迎访问松旺国学官网，想了解八极灵数、八字风水等学习，欢迎咨询我们！
        </van-notice-bar>
        <div class="row-start-col-center pd5">
            <img src="../assets/sw.jpg" style="height:60px;border-radius:30px" alt="">
            <span class="fs20 fwbold">松旺国学</span>
        </div>
        <div class="pl10 pr10">
            <van-swipe :autoplay="3000">
            <van-swipe-item v-for="(image, index) in images" :key="index">
                <img :src="image" style="width:100%;height:300px" />
            </van-swipe-item>
            </van-swipe>
        </div>
        <van-tabbar v-model="active" @change="chagetab">
            <van-tabbar-item icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item icon="search">业务</van-tabbar-item>
            <van-tabbar-item icon="friends-o">文章</van-tabbar-item>
            <van-tabbar-item icon="setting-o">我的</van-tabbar-item>
        </van-tabbar>
        <div v-if="active == 0">
             <div class="sub-box mt10">
                  公司介绍
             </div>
             <div class="sub-box mt10">
                  关于我们
             </div>
        </div>
        <div v-if="active == 1">
             <div class="sub-box mt10 tl">
                  <div class="tc">业务介绍</div>
                    <van-card
                    num="1"
                    price="13.00"
                    desc="数字磁场"
                    title="八极灵数"
                    thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
                    >
                      <template #footer>
                        <van-button size="mini" @click="gotonumber">立即进入</van-button>
                      </template>
                    </van-card>
             </div>
        </div>
        <div v-if="active == 2">
             <div class="sub-box mt10">
                  往期文章
             </div>
        </div>
        <div v-if="active == 3">
             <div class="sub-box mt10 pt20">
                 <div>昵称：xxxx</div>
                 <div>手机号：xxxx</div>
                 <div>八字：xxxx</div>
                 <div>今日运势：xxxx</div>
                 <div>测算记录：xxxx</div>
             </div>
        </div>
  </div>
</template>
<script>
import axios from 'axios'
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
      active: 0,
      images: [
        require("../assets/sw1.png"),
        require("../assets/sw2.png"),
      ]
   };
  },
  computed:{

  },
  methods:{
    chagetab(val){
       console.log('val',val)
    },
    gotonumber(){
      window.location.href="https://online.miaoxingjundd.com/phoneNumber"
    }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    max-width: 750px;
    height: 100vh;
    /* background-image: url('../assets/swbg.jpg');
    background-size: 99% 100%;
    background-repeat: no-repeat;
    color: white; */
    background: #fff;
    color: #333;
}
.my-swipe .van-swipe-item {
color: #fff;
font-size: 20px;
height: 200px;
text-align: center;
}
.formClass{
  width: 70%;
  margin: 0px auto;
  padding-top: 110px;
}
.sub-box{
  width: 100%;
  height: 260px;
  border: 1px solid #aaa;
}
/deep/.van-field__label{
   width: 5.2em;
}
/deep/.van-cell{
  background: rgba(79,48,167, 0.8);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #f0f0f0; 
}
/deep/.van-radio__label{
  color: #f0f0f0; 
}
/deep/ input{
  color: #f0f0f0; 
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.mt6{
  margin-top: 8px;
}
</style>


