<template>
  <div>
    <div class="title-container">
      <div class="row-center">
        <div class="img-head">
            <img :src="headimg" class="logoStyle" alt="">
        </div>
      </div>
      <div>
          <div class="title2 mt10">{{name}}</div>
      </div>
      <div>
          <div class="title3 mt10">{{birthday}}</div>
      </div>
      <div class="content-container mt20">
          <div class="row-center-col-center pt30 pb20">
              <img src="../assets/yezi1.png" style="width:50px;height:16px" alt="">
              <div class="title4 ml5 mr5">每日运势</div>
              <img src="../assets/yezi2.png" style="width:50px;height:16px" alt="">
          </div>
          <div class="mt20 back mr10">
            <div class="row-start-col-center ml30">
              <img src="../assets/index.png" style="width:7px;height:20px" alt="">
                <div class="title5 ml5 mr5">未来运势曲线图</div>
            </div>
              <div id="main" style="width: 98%;height:260px;"></div>
          </div>
          <el-button size="mini" style="color:#1E1854" class="mb20 fwbold fs16" type="text" @click="payList">解锁记录</el-button>
          <div style="text-align:center;color:#444;padding-top:40px">
                <img src="../assets/qrcode.jpg" style="width:30%"  alt="">
                <div style="font-size:14px;padding:20px;">想了解更多，请关注公众号：喵星君大大</div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import wxlogin from 'vue-wxlogin';
import {postAction} from '@/api/manage'
export default {
  components: {  },
  name: 'Login',
  data() {
    return {
      disabledFlag:false,
      datetime:'',
      showPicker: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      dateNumber:'7',
      showPicker2:false,
      columns:['5','6','7','8','9','10','11','12','13','14']
    }
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
    currentdate(){
      return this.$route.query.currentdate ? this.$route.query.currentdate : ''
    },
    birthday() {
      return this.$route.query.birthday ? this.$route.query.birthday : ''
    },
    sex(){
      return this.$route.query.sex ? this.$route.query.sex : ''
    },
    num(){
      return this.$route.query.num ? this.$route.query.num : ''
    }
  },
  mounted () {
     this.getBackluck()
     this.datetime = this.birthday
  },
  methods: {
      getBackluck(){
        this.showPicker2 = true
        const url = '/daily/backluck'
        postAction(url, {currentdate:this.currentdate,birthday:this.birthday,sex:this.sex,num:Number(this.num)}).then(res => {
              let keyList = Object.keys(res.data.data);
              let valueList = Object.values(res.data.data);
              let cailist = valueList.map(item => item.cai)
              let yinlist = valueList.map(item => item.yin)
              keyList = keyList.map(item => {
                let currentdateTime = new Date().getTime()
                currentdateTime = currentdateTime + 86400000 * Number(item)
                currentdateTime = this.parseTime(new Date(currentdateTime),'{m}-{d}')
                return currentdateTime
              })
              this.drawLine1(keyList,cailist,yinlist,'后运的运势曲线','#3ba272','main')
        })
      },
      onConfirmTime(time) {
        console.log('values',time)
        this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
        console.log('values',this.datetime)
        this.showPicker = false;
      },
      onConfirmnum(value) {
        this.dateNumber = value;
        this.showPicker2 = false;
      },
      selectDate(){
        if(!this.disabledFlag){
          this.showPicker = true
        }
      },
      parseTime (time, cFormat) {
        if (arguments.length === 0) {
          return null
        }
        if (!time) return ''
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
          date = time
        } else {
          if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
          }
          if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
          }
          date = new Date(time)
        }
        const formatObj = {
          y: date.getFullYear(),
          m: date.getMonth() + 1,
          d: date.getDate(),
          h: date.getHours(),
          i: date.getMinutes(),
          s: date.getSeconds(),
          a: date.getDay()
        }
        const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
          let value = formatObj[key]
          // Note: getDay() returns 0 on Sunday
          if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
          if (result.length > 0 && value < 10) {
            value = '0' + value
          }
          return value || 0
        })
        return timeStr
      },
      drawLine1 (yearlist,cailist,yinlist,text,color,divId) {
        var echarts = require('echarts');
        var myChart = echarts.init(document.getElementById(divId));
        // 指定图表的配置项和数据
        var option = {
        title: {
                text: '',
                //标题样式
                textStyle: {
                    fontWeight: 'normal',
                    color: '#fff',
                    fontSize:20
                },
                left: 50,
            },
          color:['#3ba272'],
          xAxis: {
            type: 'category',
            data: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
            axisLine: {
                lineStyle: {
                    color: '#fff'
                }
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#fff'
                }
            },
          },
          series: [
            {
              data: [3, 5, 4, 1, 4, 5, 1,2,2,5],
              type: 'line',
              smooth: true
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.setOptionData(yearlist,cailist,yinlist,text));
      },
      setOptionData(yearlist,cailist,yinlist,text){
      var option = {
          legend: {
              show: true,    // 是否显示图例
                  //图例位置
              right: 0,
              top: 30,
          },
         color:['#0134FF','#FF3E42'],
          xAxis: {
            type: 'category',
            data: yearlist,
            axisLine: {
                lineStyle: {
                    color: '#000'
                }
            },
            axisLabel:{
                interval: 0,
                rotate: 270
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#000'
                }
            },
          },
          series: [
            {
              data: cailist,
              type: 'line',
              smooth: true,
              name: '客观',
            },
            {
              data: yinlist,
              type: 'line',
              smooth: true,
              name: '主观',
            },
          ]
        };
        return option
      },
      payList(){
        this.$router.push({path:'/dailyluckList'})
      }
  }
}
</script>
<style scoped>
.title-container{
    width: 100%;
    min-height: 100vh;
    background-image: url('../assets/dailyhome.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
}
.content-container{
  box-shadow: 0px 3px 15px #211C4D;
  border-radius: 50px 50px 0px 0px;
  /* height: calc(100vh - 170px); */
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 30px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.title2{
  font-size: 18px;
  font-weight: bold;
  color: #1E1854;
}
.title4{
  font-size: 16px;
  font-weight: bold;
  color: #1E1854;
}
.title5{
  font-size: 14px;
  font-weight: bold;
  color: #1E1854;
}
.title3{
  font-size: 14px;
  color: #1E1854;
}
</style>
