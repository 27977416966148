<template>
  <div class="login-container">
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>OA系统制定计划</h2>
      </div>
    <van-form @submit="handleLogin">
    <van-field
        v-model="username"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        disabled
    />
    <van-field
    readonly
    clickable
    name="calendar"
    :value="finishdate"
    label="截至日期"
    placeholder="点击选择截至日期"
    @click="showCalendar = true"
    :rules="[{ required: true, message: '请选择截止日期' }]"
    />
    <van-calendar v-model="showCalendar" @confirm="onConfirm" />
    <van-field
        v-model="plan"
        rows="2"
        autosize
        label="计划"
        type="textarea"
        maxlength="50"
        placeholder="请输入计划"
        show-word-limit
        :rules="[{ required: true, message: '请输入计划' }]"
        />
    <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
    </div>
    </van-form>
  </div>
</template>

<script>
import { postAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      password:'',
      loading: false,
      redirect: undefined,
      value: '',
      showCalendar: false,
      finishdate:'',
      plan:''
    }
  },
//   watch: {
//     $route: {
//       handler: function(route) {
//         this.redirect = route.
//       },
//       immediate: true
//     }
//   },
  computed:{
      username(){
          return this.$route.query.username
      }
  },
  methods: {
    onConfirm(date) {
      this.finishdate = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    handleLogin(values) {
        this.loading = true
        const url = '/oa/plansubmit'
        postAction( url, {username:this.username,finishdate:this.finishdate, plan:this.plan}).then( res => {
        this.loading = false
        if( res.data.code == 200) {
          this.$message.success('提交成功')
          this.$router.push({name: 'oaDashboard'})
        }
        else{
          this.$message.warning(res.data.mag)
        }
        }).catch(() => {
            this.loading = false
        })
          // this.$store.dispatch('user/login', this.loginForm).then(() => {
          //   // this.$router.push({ path: this.redirect || '/' })
          //   this.loading = false
          //    this.$router.push({name: 'UserQuery'})
          // }).catch(() => {
          //   this.loading = false
          // })
    }
  }
}
</script>
<style scoped>
.login-container{
    margin-top: 50px;
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>

