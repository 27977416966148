<template>
  <div class="container">
       <div class="row-center-col-center pt50 pb30">
          <img src="../assets/yezi1.png" style="width:50px;height:16px" alt="">
          <div class="title2 ml5 mr5">解锁记录</div>
          <img src="../assets/yezi2.png" style="width:50px;height:16px" alt="">
      </div>
       <div v-for="(item,index) in recordList" :key="index" class="listBox tl col-center">
           <div class="row-spacea-col-center">
              <span class="fs20 fwbold">{{index + 1}}</span>
              <span class="ml10 fs16" @click="gotoResult(item)">{{item.currentdayC}}-{{item.daynum}}天</span>
              <i @click="deleteReport(item)" class="el-icon-delete"></i>
           </div>
       </div>
  </div>
</template>
<script>
import axios from 'axios'
import { Toast } from 'vant';
import { Dialog } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
     recordList:1,
   };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
        return this.userinfoLove.unionid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
  },
  mounted(){
    this.getList()
  },
  methods:{
    gotoResult(row){
       this.$router.push({ path: '/dailyluckback', query: { currentdate:row.currentday,birthday:row.birthday,sex:row.sex,num:row.daynum}})
    },
    getList(){
        const url = '/daily/lucklist'
        postAction(url, {openid:this.openid, pay:1}).then(res => {
          if (res.data.code == 200) {
              this.recordList = res.data.data
              this.recordList.forEach(item => {
                 const birthdaylist = item.currentday.split('-')
                 item.currentdayC = birthdaylist[0]+'年'+birthdaylist[1]+'月'+birthdaylist[2]+'日'
              })
          }
        })
    },
    deleteReport(row){
        var that = this
        Dialog.alert({
          title: '提示',
          message: '此操作将删除该条数据, 是否继续?',
        }).then(() => {
          const url = '/daily/delluck'
          postAction(url, {Id:row.id,openid:row.openid}).then(res => {
            that.loading = false
            Toast.success('删除成功');
            that.getList()
          })
        });
    }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    min-height: 100vh;
    background-image: url('../assets/dailybg.png');
    /* background-image: linear-gradient(rgb(250,240,241), rgb(255,255,255));; */
    /* background-image: linear-gradient(to bottom right, rgb(242,197,204),rgb(242,197,204), rgb(219,49,111)); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.title2{
  font-size: 18px;
  font-weight: bold;
  color: #1E1854;
}
.listBox{
  width: 75%;
  height: 40px;
  margin: 16px auto;
  padding: 3px;
  border-radius:0px 20px 0px 20px;
  background: #FF3E42;
  color: white;
}
/deep/.van-field__label{
   width: 6em;
}
/deep/.van-cell{
  background: rgb(233,230,230);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-radio__label{
  color: #333;
}
/deep/ input{
  color: #333;
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.user-name{
  text-align: left;
}
.mt6{
  margin-top: 6px;
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.button1Style{
  background:rgb(242,197,204);
  border:1px solid rgb(242,197,204);
  font-size: 20px;
  width:150px;
  border-radius:6px;
}
.button2Style{
  background:rgb(182,182,182);
  border:1px solid rgb(182,182,182);
  font-size: 20px;
  width:150px;
  border-radius:6px;
}
</style>


