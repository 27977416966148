<template>
  <div class="container">
    <el-card>
    <h2 class="pb20">用户列表</h2>
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="喵星君" name="first"></el-tab-pane>
    </el-tabs>
    <el-table
      style="margin-top:30px"
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      :header-cell-style="{ background: '#f8f8f8' }"
    >
      <el-table-column align="center" label="ID" min-width="80">
        <template slot-scope="scope">
          <!-- <el-button type="text" @click="getUserDetail(scope.row.id)">{{ list.length - scope.$index}}</el-button> -->
          <el-button type="text" @click="getUserDetail(scope.row.id)">{{scope.row.id}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="性别" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.gender }}
        </template>
      </el-table-column>
      <el-table-column label="出生时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.date }}
        </template>
      </el-table-column>
      <el-table-column label="出生地" min-width="140" align="center">
        <template slot-scope="scope">
          {{ scope.row.province }}
        </template>
      </el-table-column>
      <el-table-column label="婚否" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.marriage }}
        </template>
      </el-table-column>
      <el-table-column label="工作" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.job }}
        </template>
      </el-table-column>
      <el-table-column label="联系方式" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.contact }}
        </template>
      </el-table-column>
      <el-table-column label="选择项目" min-width="120" align="center">
        <template slot-scope="scope">
         {{ scope.row.choose}}
        </template>
      </el-table-column>
      <el-table-column label="重点关注方向" min-width="120" align="center">
        <template slot-scope="scope">
         {{ scope.row.finish }}
        </template>
      </el-table-column>
      <el-table-column label="从哪看到" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.where }}
        </template>
      </el-table-column>
      <el-table-column label="平台" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.platform }}
        </template>
      </el-table-column>
      <el-table-column label="IP" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.ip }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.calender }}
        </template>
      </el-table-column>
    </el-table>
    <div class="tl mt15" style="width:100%;overflow: scroll;">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.pageNum"
        :page-sizes="pagination.pageSizeOptions"
        :page-size='20'
        layout="total,sizes, prev, pager, next"
        :total="pagination.total">
      </el-pagination>
    </div>
    <el-dialog title="用户详情" :visible.sync="dialogVisible" @close="clearForm" width="750px">
    <el-row style="margin-left:50px">
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">姓名</div>
          <div class="content">{{ form.name }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">性别</div>
          <div class="content">{{ form.gender }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">出生时间</div>
          <div class="content" style="color: blue;cursor:pointer" @click="gotoPaipan(form.date,form.province,form.gender)">{{ form.date }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">出生地</div>
          <div class="content">{{ form.province }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">婚否</div>
          <div class="content">{{ form.marriage }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">用神</div>
          <div class="content">{{ form.gods }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">原生分数</div>
          <div class="content">财:{{ form.family.money}},印:{{ form.family.work}},官:{{ form.family.leader }},比:{{ form.family.friend }},食:{{ form.family.life }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">工作</div>
          <div class="content">{{ form.job }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">联系方式</div>
          <div class="content">{{ form.contact }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">选择项目</div>
          <div class="content">{{ form.choose}}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">重点关注方向</div>
          <div class="content">{{ form.finish }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">公众号历史记录</div>
          <div class="content" style="color: blue;cursor:pointer" @click="gotoGzh(form.unionid)">详细信息</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">IP</div>
          <div class="content">{{ form.ip }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">平台</div>
          <div class="content">{{ form.platform }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
		  <div class="contentTitle">从哪看到</div>
          <div class="content">{{ form.where }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">长期曲线</div>
          <div class="content">
            <el-button type="text" circle @click="openlifeturn()">详情</el-button>
          </div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">提交时间</div>
          <div class="content">{{ form.calender }}</div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">前运</div>
          <div class="content">
              <el-input
                type="textarea"
                v-if="editFlag"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.backward">
              </el-input>
              <span v-else>{{ form.backward }}</span>
          </div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">后运</div>
          <div class="content">
              <el-input
                v-if="editFlag"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.forward">
              </el-input>
               <span v-else>{{ form.forward }}</span>
          </div>
        </div>
      </el-col>
      <el-col :md="8" :sm="12">
        <div class="content-wrap">
          <div class="contentTitle">备注</div>
          <div class="content">
              <el-input
               v-if="editFlag"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.info">
              </el-input>
              <span v-else>{{ form.info }}</span>
          </div>
        </div>
      </el-col>
      </el-row>
      <div class="row-center width-12">
          <el-button type="primary" icon="el-icon-edit" circle @click="editFlag = !editFlag"></el-button>
          <el-button type="primary" plain @click="submitInfo">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="公众号详情" :visible.sync="gzhVisible" width="750px">
    <el-row style="margin-left:50px">
        <div class="content-wrap">
          <div class="contentTitle">是否注册过线上系统</div>
          <div class="content">{{ gzhForm.user ? '是':'否' }}</div>
        </div>
        <div class="content-wrap">
          <div class="contentTitle">公众号记录</div>
          <div class="row-start wrap">
            <div class="width-12 mt5" v-for="(item,index) in gzhForm.gzh" :key="index">
              {{ item.content }}
            </div>
          </div>
        </div>
        <div class="content-wrap mt20">
          <div class="contentTitle">排盘记录</div>
          <div class="row-start wrap width-24">
              <div class="width-12 mb5" v-for="(item,index) in gzhForm.pp" :key="index">
                {{ item.date }} - {{ item.sex }}
              </div>
          </div>
        </div>
      </el-row>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getAction,postAction  } from '@/api/manage'

export default {
  data() {
    return {
      list: [
      ],
      listLoading: false,
      dialogVisible:false,
      activeName:'first',
      pagination: {
        pageNum: 1,
        pageSize: 20,
        pageSizeOptions: [10, 20, 30,50,100],
        total: 0
      },
      form:{
        family:{
          money:'',
          work:'',
          leader:'',
          friend:'',
          life:''
        },
        forward:'',
        backward:'',
        info:'',
      },
      gzhForm:{
        'gzh':[],
        'user':'',
        'pp':[]
      },
      Id:'',
      editFlag:false,
      gzhVisible:false
    }
  },
  created() {
    this.fetchData('admin/userlist')
  },
  methods:{
    clearForm(){
       this.dialogVisible = false
       this.form = {
        family:{
          money:'',
          work:'',
          leader:'',
          friend:'',
          life:''
        },
        forward:'',
        backward:'',
        info:'',
      }
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.fetchData('admin/userlist')
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.fetchData('admin/userlist')
    },
    openlifeturn(){
      this.$router.push({path: '/lifeturn',query:{Id:this.Id}})
    },
    submitInfo(){
      let url = 'admin/add_info'
      postAction(url,{Id:this.Id,forward:this.form.forward,backward:this.form.backward,info:this.form.info}).then( res => {
            if( res.data.code == 200) {
              this.$message.success('提交成功')
              this.editFlag = !this.editFlag
            }
            else{
              this.$message.error(res.data.msg)
            }
      })
    },
    chooseProject(params){
      if(params === '1'){
         return '全面运势咨询'
      } else if(params === '2'){
         return '事业单项咨询'
      } else if(params === '3'){
         return '感情单项咨询'
      } else if(params === '4'){
         return '事情发展占测'
      } else if(params === '5'){
         return '合婚'
      } else if(params === '6'){
        return '流年运势精批'
      } else if(params === '7'){
        return '其他'
      }
    },
    fetchData(params){
      this.listLoading = true
      const url = params
      postAction(url,{pageNum:this.pagination.pageNum, pageSize:this.pagination.pageSize}).then( res => {
         this.list = res.data.data.list
         this.pagination.pageNum = res.data.data.pageNum
         this.pagination.total = res.data.data.total
         this.listLoading = false
      })
    },
    handleClick(){
      if(this.activeName === 'first'){
        this.fetchData('admin/userlist')
      } 
    },
    gotoPaipan(datetime, provincecity,sex){
            let url = '/paipan/submit'
            const datetimeArr = datetime.split(' ')
            const date = datetimeArr[0].split('-')
            const time = datetimeArr[1].split(':')
            const params = {
                year: date[0],
                month: date[1],
                day: date[2],
                hour: time[0],
                minute: time[1],
                sex: sex,
                city: provincecity
            }
            postAction(url,params)
            .then((response) => {
                  console.log( 'responseData', response)
                  console.log('responseData',response.data.context)
                  sessionStorage.setItem("bornDate", JSON.stringify(response.data.context.bornDate))
                  sessionStorage.setItem("birthDay", JSON.stringify(response.data.context.birthDay))
                  sessionStorage.setItem("baziArray", JSON.stringify(response.data.context.baziArray))
                  sessionStorage.setItem("kongwangArray", JSON.stringify(response.data.context.kongwangArray))
                  sessionStorage.setItem("dayunArray", JSON.stringify(response.data.context.dayunArray))
                  sessionStorage.setItem("cangArray", JSON.stringify(response.data.context.cangArray))
                  sessionStorage.setItem("baziGodArray", JSON.stringify(response.data.context.baziGodArray))
                  sessionStorage.setItem("cangGodArray", JSON.stringify(response.data.context.cangGodArray))
                  sessionStorage.setItem("baziWangArray", JSON.stringify(response.data.context.baziWangArray))
                  sessionStorage.setItem("ysdz", JSON.stringify(response.data.context.yswords.dz))
                  sessionStorage.setItem("ystg", JSON.stringify(response.data.context.yswords.tg))
                  sessionStorage.setItem("sex", JSON.stringify(response.data.context.sexArray))
                  sessionStorage.setItem("city", JSON.stringify(response.data.context.cityArray))
                  sessionStorage.setItem("qiyun", JSON.stringify(response.data.context.qiyunArray))
                  this.$router.push({ name: 'PaipanResult'})
            })
    },
    gotoGzh(unionid){
      this.gzhVisible = true
      let url = '/admin/id_detail'
      postAction(url,{unionid:unionid})
      .then((response) => {
         this.gzhForm = response.data.msg
       })
    },
    getUserDetail(id){
      this.dialogVisible = true
      this.Id = id
      let url
      if(this.activeName === 'first'){
        url = 'admin/userdetail/' + id
      } 
      getAction(url).then( res => {
        this.form = Object.assign({},res.data) 
      })
    },
    openIP(url){
      window.open(url)
    }
  }
}
</script>
<style scoped>
.content-wrap {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right:10px ;
  justify-content: flex-start;
}
.content {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding-bottom: 32px;
  /* width: 70%; */
  font-weight: 500;
 }
.contentTitle {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  align-items: center;
  line-height: 20px;
  margin-right: 8px;
  font-weight: 600;
  padding-bottom: 32px;
  white-space: nowrap;
 }
.contentTitle::after {
    content: ':';
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
  }
  .pb20 {
    padding-bottom: 20px;
  }
  .container {
   width: 100%;
   min-height: 600px;
   margin-right: auto;
   margin-left: auto;
}

</style>
