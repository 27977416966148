<template>
  <div style="padding:0px 20px; background:#fff">
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>OA系统员工列表</h2>
      </div>
        <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
         @load="onLoad"
        >
        <van-cell v-for="item in userlist" :key="item.id" :value="`${item.name} , 权限: ${item.auth == 5 ? '管理员' : item.auth == 3 ? '后端' : '前端'}，性别：${item.sex}, 生日：${item.date} `"  is-link :to="{ path: '/messageDetail', query: { username: item.name }}"  size="large"/>
        </van-list>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      userlist:[]
    }
  },
  created(){
    this.getUserlist()
  },
  methods: {
    onLoad(){
      this.getUserlist()
    },
    getUserlist(){
      const url = '/oa/userlist'
        getAction(url).then( res => {
        this.loading = true
        if( res.data.code == 200) {
            this.userlist = res.data.msg
            console.log('this.userlist',this.userlist)
        }
        else if(res.data.code == 400){
           this.$router.push({name: 'oaLogin'})
        }
        else{
          this.$message.warning(res.data.msg)
        }
        }).finally(() => {
            this.finished = true
            this.loading = false
        })
    },
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>

