<template>
    <div class="article-container">
      <!-- 顶部标题 -->
        <h2>文案评估</h2>
        <div class="ml20 mr20">
          <!-- 主体部分 -->
            <el-input v-model="articleContent" placeholder="请输入内容" type="textarea"  :rows="10"></el-input>
            <p>结果如下：</p>
            <p v-if="flag">整体分数：{{ summ }}，微调分数：{{ single }}</p>
            <p>注：{{ msg }}</p>
            <p>说明：</p>
            <p>1. 分数分为0、1、2三个分数档，整体分数中0分代表平均水平以下，不会火。1分代表一般，2分代表平均水平之上，会火。</p>
            <p>2. 微调分数为整体分数做一个补充，0分代表了在第一次评价基础上减少火的概率，1分代表了维持第一次评价，2分代表了在第一次评价基础上增加火的概率</p>
            <p>3. 如果整体分数低而微调分数高的时候，可以视为会火，反之则代表文案一般。火与不火是相比于自己之前的文案数据而定。</p>
            <p>4. 如果提示服务器异常，可将文案粘贴到手机备忘录中，把回车和空格删除再进行尝试（在网页中删除无效）。</p>
        </div>
  
        <!-- 底部按钮 -->
        <div class="row-center">
            <el-button type="primary" v-loading="loading1" @click="payMonth">包月</el-button>
            <el-button type="primary" v-loading="loading2" @click="gotoResult">提交</el-button>
            <el-button type="primary"  @click="gotoList">评估结果</el-button>
            <el-button type="danger"   @click="clear">清除</el-button>
        </div>
      </div>
  </template>
  
  <script>
  import { postAction, getAction } from '@/api/manage'
  import wx from 'weixin-jsapi'
  export default {
    name: "MyComponent",
    data() {
      return {
        summ:'',
        single:'',
        msg:'',
        articleContent:'',
        userInfo:{},
        flag:false,
      };
    },
    computed:{
        openid() {
          // 通过vuex的getters方法来获取state里面的数据
          return this.userinfoCareer.us_openid;
        },
        unionid() {
          // 通过vuex的getters方法来获取state里面的数据
          return this.userinfoCareer.us_unionid ;
        },
        userinfoCareer() {
          // 通过vuex的getters方法来获取state里面的数据
          return this.$store.getters.getUserinfoCareer || {};
        },
        code(){
         return this.$route.query.code ? this.$route.query.code : ''
        }
    },
    mounted() {
      if(!this.unionid){
        this.getlogin()
      }
    },
    methods: {
      login() {
        this.$router.push({ path: '/login' });
      },
      clear() {
        this.articleContent = '';
        this.summ = '';
        this.single = '';
        this.flag = false;
        this.msg = '';
      },
      gotoCode(){
          window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=https%3A%2F%2Fonline.miaoxingjundd.com%2Farticle&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
      },
     getlogin(){
       if(this.$route.query.code){
        this.getOpenID()
       } else {
        this.gotoCode()
       }
     },
      getOpenID(){
      const url = '/pr/vxlogin'
          postAction(url, {code:this.code}).then( res => {
            this.userInfo = res.data.data
            this.$store.commit('setUserInfoCareer', this.userInfo)
          })
      },
      gotoResult() {
        const params = {
          content: this.articleContent,
          openid: this.openid,
          unionid: this.unionid,
          padsize: 30,
        };
        postAction('/article/ptishot', params).then(res=> {
            if(res.data.code == 200){
              this.flag = true
              this.summ = res.data.msg.sum
              this.single = res.data.msg.single
              this.msg = res.data.data
            }
            else{              
              this.msg = res.data.msg
            }
        })
      },
      payMonth() {
        const params = {
          openid: this.openid,
          unionid: this.unionid,
          number:1,
          paynum:'2'
        };
        let that = this
        this.loading1 = true  
        const url = '/article/wxpay'
        getAction(url, params).then( res => {
          if(res.data.code === 200){
            const result = res.data.msg.result
            wx.config({
              debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
              appId: result.appId, // 必填，公众号的唯一标识
              timestamp: result.timeStamp, // 必填，生成签名的时间戳
              nonceStr: result.nonceStr, // 必填，生成签名的随机串
              signature: result.paySign, // 必填，签名
              jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
            })
            wx.ready(() => {
              wx.checkJsApi({
                jsApiList: ['chooseWXPay'],
                success:function(res){
                  console.log("success")
                  wx.chooseWXPay({
                      timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                      nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                      package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                      signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                      paySign: result.paySign, // 支付签名
                      success: function (res) {  // 支付成功后的回调函数
                        alert("支付成功");
                        this.$router.push({ path: '/articleResult' });
                        // that.$router.push({path:'/careerResult', query:{re_id:this.$route.query.re_id, openid:sessionStorage.getItem("us_openid")}})
                      },
                      fail: function (res) {
                        alert("支付失败");
                      }
                    })
                },
                fail:function(res){
                  console.log("fail");
                  console.log(res) }
              })
            })
            wx.error(err => {
              Toast.fail(err)
            })
          }else{
            Toast.fail(res.data.msg);
          }
        }).finally(() => {
          this.loading1 = false
        })
      },
      gotoList() {
        this.$router.push({ path: '/articleResult' });
      }
    }
  };
  </script>
  
  <style scoped>
  .article-container{
    width: 100%;
    height: 100%;
    background: #fff;
  }
  h1 {
    text-align: center;
  }
  p {
    margin-top: 20px;
    text-align: left;
  }
  
  </style>
  