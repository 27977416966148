<template>
  <div class="container">
    <el-card>
    <!-- <img width="100%" src="@/assets/cut.jpeg" /> -->
    <h2 class="titleForm">喵星君大大预约表</h2>
    <span class="discription">为方便为您服务，需要您按要求填写以下信息。</span>
    <el-divider />
    <!-- <div class="discription">
      四柱八字是易经占卜中测算整个人命运的最基本方法。奇门遁甲则是一种利用喜用神作为分析变量与四柱八字进行组合分析的一种测算方法。前者侧重于对于命运整体进行分析，而后者则侧重于某一时间节点具体事件进行分析。两者结合进行命理分析将会比较准确地得到我们需要的信息。
    </div> -->
    <el-form class="mt20" ref="form" :rules="rules" :model="form" label-width="200px" label-position="top" label-suffix=":">
      <el-form-item label="简介">
      <span class="discription">四柱八字是易经占卜中测算整个人命运的最基本方法。奇门遁甲则是一种利用喜用神作为分析变量与四柱八字进行组合分析的一种测算方法。前者侧重于对于命运整体进行分析，而后者则侧重于某一时间节点具体事件进行分析。两者结合进行命理分析将会比较准确地得到我们需要的信息。</span>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <div class="form-description">看是否与命格与喜用神相合。</div>
        <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
         <el-radio-group v-model="form.gender" style="width: 100%;" placeholder="请选择性别">
           <el-radio label="男"></el-radio>
           <el-radio label="女"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="联系方式" prop="contact">
        <div class="form-description">方便大家再次咨询时快速反馈，请填一下信息，微信或者qq都可以，eg. zxzmls(微信)。</div>
        <el-input v-model="form.contact" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="出生时间" prop="date">
        <div class="form-description">八字排盘需要，填写阳历生日，最好能够准确到分如果实在不清楚精确到小时也可以。</div>
        <el-col :span="11">
          <el-date-picker
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择阳历生日"
            v-model="form.date"
            style="width: 100%;"
          ></el-date-picker>
        </el-col>
        <el-col class="tr" :span="2">-</el-col>
        <el-col :span="11">
          <el-time-picker
            placeholder="选择时间"
            value-format="HH:mm:ss"
            v-model="form.time"
            style="width: 100%;"
          ></el-time-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="出生地(省市)" prop="province">
        <div style="border: 1px solid #c3c3c3;border-radius:3px">
          <van-field
            readonly
            clickable
            name="area"
            :value="provincecity"
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
        </div>
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            :columns-num="2"
            @confirm="onConfirmCity"
            @cancel="showArea = false"
          />
        </van-popup>
      </el-form-item>
        <!-- 地区选择器-->
      <el-form-item label="婚否" prop="marriage">
        <el-radio-group v-model="form.marriage" style="width: 100%;">
           <el-radio label="已婚"></el-radio>
           <el-radio label="未婚"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="工作" prop="job">
        <div class="form-description">可以具体填写，如：会计。也可以指出行业，如：金融行业。可以为您提供适合自己五行命格与喜用神的参考意见。</div>
        <el-input v-model="form.job" placeholder="请输入工作"></el-input>
      </el-form-item>
      <el-form-item label="选择项目" prop="choose">
        <div class="form-description">选择全套、单项或者占事</div>
        <el-select v-model="form.choose"  style="width: 100%;" placeholder="请选择项目">
            <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="重点想看的问题" >
        <el-input v-model="form.finish" placeholder="请输入重点想看的问题"></el-input>
      </el-form-item>
      <el-form-item label="在何处看到信息">
      <div class="form-description">方便的话帮忙填写一下</div>
        <el-input v-model="form.where" placeholder="请输入从哪看到的我们"></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否在“喵星君大大”公众号中完成前运测试" >
        <div class="form-description">可关注公众号“喵星君大大”，依据提示可以完成公众号上的前运测试。与人工前运测试符合度。</div>
        <el-radio-group v-model="form.finish" style="width: 100%;">
           <el-radio label="是"></el-radio>
           <el-radio label="否"></el-radio>
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item label="前运正确率" >
        <el-select v-model="form.percent"  style="width: 100%;" placeholder="请选择正确率">
        <el-option label="30以下" value="30以下"></el-option>
        <el-option label="30到50" value="30到50"></el-option>
        <el-option label="50到80" value="50到80"></el-option>
        <el-option label="80到100" value="80到100"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="选择谁" prop="choose">
        <el-radio-group v-model="form.choose" style="width: 100%;">
           <el-radio label="喵星君"></el-radio>
           <el-radio label="喵小君"></el-radio>
        </el-radio-group>
      </el-form-item> -->
      <div class="tr mt40">
        <el-button type="primary" icon="el-icon-check" style="width: 100%;" @click="onSubmit">提交</el-button>
      </div>
    </el-form>
    </el-card>
  </div>
</template>

<script>
import {postAction} from '@/api/manage'
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
export default {
  name: "Home",
  data() {
    return {
      form: {
        name: '',
        gender: '',
        contact: '',
        date: '',
        province: '',
        marriage: '',
        job: '',
        where: '',
        choose:'',
        finish: '',
        percent: ''
      },
      rules:{
        name: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        gender: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        contact: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        date: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        province: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        marriage: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        job: [{required: true, message:"输入不能为空", trigger: 'blur' }],
        choose:[{required: true, message:"输入不能为空", trigger: 'blur' }]
      },
      options:[
          {label:'全面运势咨询',value:'全面运势咨询'},
          {label:'事业单项咨询',value:'事业单项咨询'},
          {label:'感情单项咨询',value:'感情单项咨询'},
          {label:'事情发展占测',value:'事情发展占测'},
          {label:'合婚',value:'合婚'},
          {label:'流年运势精批',value:'流年运势精批'},
          {label:'其他',value:'其他'},
      ],
      provincecity:'',
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
    };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
  },
  mounted(){
    if(!this.openid){
      this.getlogin()
    }
  },
  methods:{
      onConfirmCity(values) {
        this.provincecity = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
          console.log('values',this.form.province)
        this.showArea = false;
      },
      onSubmit() {
        if(this.provincecity == ''){
          Toast.fail('城市输入不能为空');
          return
        }
        const url = '/form/submit'
        const city = this.provincecity.split('/')
        this.form.province = city[1]
        this.form.unionid = this.unionid
        this.$refs.form.validate( (valid) => {
          if(valid){
            postAction( url, this.form).then( res => {
            if( res.data.code == 200) {
              this.$router.push({name: 'SubmitResult'})
            }
            else{
              this.$message.error('提交失败，请重新尝试提交')
            }
           })
         }
        })
      },
      gotoCode(){
            window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=https%3A%2F%2Fonline.miaoxingjundd.com%2Fform&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
      },
        //  else {
        //     this.$router.push({path:'/reportList', query: {code: "1234"}})
        //  }
      getlogin(){
        if(this.$route.query.code){
          this.getOpenID()
        } else {
          this.gotoCode()
        }
      },
      getOpenID(){
        const url = '/paipan/vxlogin'
        // const url = '/pr/test'
            postAction(url, {code:this.$route.query.code}).then( res => {
              this.userInfo = res.data.data
              this.$store.commit('setUserInfoLove', this.userInfo)
              // this.openid = res.data.data.openid;
              // this.unionid = res.data.data.unionid;
              console.log(this.userInfo)
              // this.$store.dispatch("setopenidval", this.userInfo.openid);
              // this.$store.dispatch("setunionidval", this.userInfo.unionid);
            })
      },
  }
};
</script>
<style scoped>
.container {
   text-align: justify;
   width: 100%;
   max-width: 800px;
   margin-right: auto;
   margin-left: auto;
   /* padding-left: 16px;
   padding-right: 16px; */
}
.el-form-item__label{
  font-weight: bold;
  font-size: 1em ;
}
.el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0px;
}
.el-form-item__content{
  font-size: 0.95em ;
}
.titleForm{
  color: #409EFF;
  text-align: center;
  margin: 16px 0px;
  width: 200px;
}
.tr{
  text-align: center;
}
.mt20{
  margin-top: 24px;
}
.mt40{
  margin-top: 40px;
}
.discription{
    margin-top:28px;
    color: rgba(0,0,0,0.6);
    line-height: 1.5em;
}
.form-description{
  color: rgba(0,0,0,0.6);
  font-size:0.9em;
  line-height: 1.75em;
  padding: 2px;
}
img{ opacity: 0.9; }
</style>
