<template>
  <div class="container">
     <!-- 增加注释 -->
     <img src="../assets/home.jpg" style="width:100%;height:100%" alt="">
     <van-button class="generate2 buttonColor2" round block type="info" native-type="submit" @click="gotoDetail">开始测试</van-button>
  </div>
</template>
<script>
import {postAction} from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      loading:false
    }
  },
  computed:{
    userinfoCareer() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoCareer || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_openid;
    },
    uid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_id;
    }
  },
  methods: {
    // getOpenID(){
    //   const url = '/pr/vxlogin'
    //   // const url = '/pr/test'
    //       postAction(url, {code:this.code}).then( res => {
    //         this.userInfo = res.data.data
    //         this.$store.dispatch("setuidval", this.userInfo.us_id);
    //         this.$store.dispatch("setopenidval", this.userInfo.us_openid);
    //         this.$store.dispatch("setunionidval", this.userInfo.us_unionid);
    //         this.$store.dispatch("setheadimgval", this.userInfo.us_headimgurl);
    //         this.$store.dispatch("setnameval", this.userInfo.us_name);
    //         console.log('this.userInfo',this.userInfo,this.$store.getters)
    //         this.$router.push({path:'/reportList'})
    //       })
    // },
     gotoDetail(){
       if(!this.openid || !this.uid){
         var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/reportList')
          //window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=http%3A%2F%2Fzy.miaoxingjundd.cn%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
            window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
       } else {
          this.$router.push({path:'/reportList'})
       }
      //  else {
      //     this.$router.push({path:'/reportList', query: {code: "1234"}})
      //  }
     },
    //  isweixin(){
    //    var ua = window.navigator.userAgent.toLowerCase();
    //     if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //         return true
    //     } else {
    //         return false
    //     }
    //  }
  }
}
</script>
<style scoped>
.container{
  position:relative;
  max-width: 400px;
  margin: 0 auto;
}
.generate1{
  position: absolute;
  top:500px;
  left: 30%;
  width: 140px;
  height: 40px;
  font-size: 18px;
}
.generate2{
  position: absolute;
  bottom:50px;
  left: 25%;
  width: 200px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.buttonColor{
  background: #2DDA82;
  color: white;
}
.buttonColor2{
  background: #35FF79;
  color: white;
  border: 2px solid #35FF79;
}
</style>
