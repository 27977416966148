<template>
    <div class="top-container">
      <div class="row-center" style="padding-top:45%;">
        <div class="textbutton ml10" style="width:135px;height: 43px;" @click="addsart"></div>
      </div>
      <div class="content-box">
        <div v-for="(item,index) in userList" :key="index" class="col-center mt20">
            <div class="row-start itemcard">
              <div class="row-start-col-center width-19">
                  <div class="mr10 ml10">{{ index + 1 }}.</div>
                  <div class="mr10">{{ item.name }}</div>
                  <div class="mr10">{{ item.birthday }}</div>
                  <div class="mr10">{{ item.sex }}</div>
              </div>
              <div class="row-start width-5">
                <el-button icon="el-icon-delete" class="nextButton" circle @click="deleteItem(item)"></el-button>
                <el-button icon="el-icon-arrow-right" class="nextButton" style="margin-left: 4px;" circle @click="gotoDetail(item)"></el-button>
              </div>
           </div>
         </div>
      </div>
      <van-popup v-model="showpop" position="bottom" :style="{ height: '43%',padding:'10px',display:'flex','align-items':'center','justify-content':'center' }" >
          <van-form @submit="onSubmit">
            <div class="formClass">
              <van-field
                v-model="username"
                name="昵称"
                label="昵称"
                placeholder="昵称"
              />
              <van-field
                readonly
                clickable
                name="datetimePicker"
                :value="datetime"
                label="生日"
                style="margin:16px 0px"
                @click="selectDate"
              />
              <van-popup v-model="showPicker2" position="bottom">
                <van-datetime-picker
                  type="datetime"
                  v-model="currentDate"
                  title="选择完整时间"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmTime"
                  @cancel="showPicker2 = false"
                />
              </van-popup>
              <van-field
                readonly
                clickable
                label="出生地"
                name="area"
                :value="provincecity"
                style="margin:16px 0px"
                @click="selectCity"
              />
            <van-popup v-model="showArea" position="bottom">
              <van-area
                :area-list="areaList"
                :columns-num="2"
                @confirm="onConfirmCity"
                @cancel="showArea = false"
              />
              </van-popup>
              <van-field name="radio" label="你的性别" style="margin:16px 0px">
                <template #input>
                  <van-radio-group v-model="sex" :disabled="disabledFlag" direction="horizontal">
                    <van-radio name="男">男生</van-radio>
                    <van-radio name="女">女生</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <div class="row-center">
                <van-button :loading="loading" loading-text="请求中..." class="button1Style" block type="info" native-type="submit">
                   确定
                </van-button>
              </div>
            </div>
        </van-form>
      </van-popup>
    </div>
  </template>

  <script>
  import { areaList } from '@vant/area-data';
  import { postAction } from '@/api/manage'
  import { Dialog, Toast } from 'vant';
  export default {
    name: 'SolarSystemSimulation',
    data() {
      return {
        showModal: false, // 控制对话框的显示
        showpop:false,
        showCalendar:false,
        onConfirmname:false,
        username:'',
        showPicker: false,
        showPicker2: false,
        showPicker3: false,
        minDate: new Date(1960, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(1990, 0, 1),
        loading:false,
        columns:['2023','2024','2025'],
        disabledFlag:false,
        sex:'',
        provincecity:'',
        showArea: false,
        areaList, // 数据格式见 Area 组件文档
        datetime: '',
        userList:[
        ],
        // unionid: 'test',
        // openid: 'test',
        click:'0',
        userInfo:{},
      };
    },
    computed:{
      userinfoLove() {
        // 通过vuex的getters方法来获取state里面的数据
        return this.$store.getters.getUserinfoLove || {};
      },
      openid() {
        // 通过vuex的getters方法来获取state里面的数据
        return this.userinfoLove.jsopenid;
      },
      unionid() {
        // 通过vuex的getters方法来获取state里面的数据
        return this.userinfoLove.unionid;
      },
      code(){
        return this.$route.query.code ? this.$route.query.code : ''
      }
  },
    mounted() {
      // this.getcenterList()
      if(!this.unionid){
          if(!this.code){
            var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/planetList')
            window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
          } else {
            this.getJsLogin()
          }
      } else {
        this.getcenterList()
      }
    },
    methods: {
      getJsLogin(){
          const url = '/xcxlove/double/jslogin'
              postAction(url, {code:this.code}).then( res => {
                if(res.data.code == 200 || res.data.code == 400){
                    this.userInfo = res.data.msg
                    this.$store.commit('setUserInfoLove', this.userInfo)
                } else {
                  Toast('获取用户信息失败,请先关注公众号！')
                }
             })
      },
        getcenterList(){
          const url = '/relationship/weixin/frontpage'
          postAction(url, {unionid:this.unionid}).then(res => {
            if (res.data.code == 200) {
              this.userList = res.data.msg.result
              if(this.userList.length == 0){
                this.getMyself()
              }
            }
          })
        },
        getMyself(){
          const url = '/relationship/verify'
            postAction(url, {unionid:this.unionid}).then(res => {
              if (res.data.code == 200) {
                  this.click = res.data.msg.click
                  if(this.click == '1'){
                    const url1 = '/relationship/weixin/addcenter'
                    postAction(url1, {unionid:this.unionid,click:'1'}).then(res => {
                      if (res.data.code == 200) {
                        Toast('首次使用会默认添加本人信息，可添加其他人作为中心人');
                        const url2 = '/relationship/weixin/frontpage'
                        postAction(url2, {unionid:this.unionid}).then(res => {
                          if (res.data.code == 200) {
                            this.userList = res.data.msg.result
                          }
                        })
                      }
                    })
                  }
              }
            })
        },
        onYearConfirm(value) {
          this.selectedYear = value;
          this.showYearPicker = false;
        },
        onYearMonthConfirm(value) {
          this.selectedYearMonth = `${value.getFullYear()}-${value.getMonth() + 1}`;
          this.showYearMonthPicker = false;
        },
        onSubmit(){
           if(!this.username || !this.datetime || !this.provincecity || !this.sex){
              Toast('请填写完整信息');
              return
           }
           let params = {
            name:this.username,
            date:this.datetime.split(' ')[0],
            hour:this.datetime.split(' ')[1],
            city:this.provincecity,
            sex:this.sex,
            unionid:this.unionid,
            openid:this.openid,
            click:'0'
           }
           if(!this.unionid || !this.openid){
              Toast('获取用户信息失败,请先关注公众号！再重新进入');
              return
           }
           const url1 = '/relationship/weixin/addcenter'
            postAction(url1, params).then(res => {
              if (res.data.code == 200) {
                Toast('添加成功');
                const url2 = '/relationship/weixin/frontpage'
                postAction(url2, {unionid:this.unionid}).then(res => {
                  if (res.data.code == 200) {
                    this.userList = res.data.msg.result
                  }
                })
              }
            }).catch(err => {
              Toast('添加失败');
            }).finally(() => {
              this.showpop = false
            })
          //  this.userList.push(params)
        },
        selectCity(){
          if(!this.disabledFlag){
            this.showArea = true
          }
        },
        addsart(){
          this.showpop = true
        },
        onConfirmTime(time) {
          console.log('values',time)
          // this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
          this.datetime = this.parseTime(time,'{y}-{m}-{d} {h}:{i}')
          this.showPicker2 = false;
        },
        selectDate(){
          if(!this.disabledFlag){
            this.showPicker2 = true
          }
        },
        onConfirmCity(values) {
          this.provincecity = values
            .filter((item) => !!item)
            .map((item) => item.name)
            .join('/');
            console.log('values',this.provincecity)
            const city = this.provincecity.split('/')
            this.provincecity = city[1]
            this.showArea = false;
        },
        parseTime (time, cFormat) {
          if (arguments.length === 0) {
            return null
          }
          if (!time) return ''
          const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
          let date
          if (typeof time === 'object') {
            date = time
          } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
              time = parseInt(time)
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
              time = time * 1000
            }
            date = new Date(time)
          }
          const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
          }
          const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
            if (result.length > 0 && value < 10) {
              value = '0' + value
            }
            return value || 0
          })
          return timeStr
        },
        gotoDetail(item){
            this.$router.push({path:'/planet',query:{centerid:item.centerid,birthday:item.birthday,sex:item.sex,name:item.name}})
        },
        deleteItem(item){
          Dialog.confirm({
            title: '提示',
            message: '确定删除吗？'
          }).then(() => {
            const url = '/relationship/weixin/revisecenter'
            postAction(url, {Id:item.centerid}).then(res => {
              if (res.data.code == 200) {
                Toast('删除成功');
                const url2 = '/relationship/weixin/frontpage'
                postAction(url2, {unionid:this.unionid}).then(res => {
                  if (res.data.code == 200) {
                    this.userList = res.data.msg.result
                  }
                })
              }
            }).catch(err => {
              Toast('删除失败');
            })
          }).catch(() => {
            // on cancel
          });
        }
    }
  }
  </script>
  <style scoped>
  .top-container{
      width:100%;
      height: 100vh;
      background-image: url('../assets/planetAdd.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: white;
      padding:0px;
      margin:0px;
  }
  .planetTitle{
    font-size:20px;
    font-weight:600;
    padding-top:50px;
  }
  .textbutton{
      background-image: url('../assets/addButton.png');
      background-size: 99% 100%;
      background-repeat: no-repeat;
      font-weight: bold;
      text-align: center;
      color: white;
      font-size:14px;
  }
  .content-box{
  width: 90%;
  height: 60vh;
  margin: 0px 20px 0px 20px;
  padding:30px 0px 12px 0px;
  background-image: url('../assets/box2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
  .itemcard{
      width:81%;
      height: 45px;
      font-weight: bold;
      text-align: center;
      color: #333;
      font-size:14px;
      margin-top:6px;
      background: rgba(242, 227, 240, 0.6);
      align-items: center;
      border-radius: 8px;
  }
  /deep/ .choosedata .van-cell{
    width: 100px;
    background: transparent;
    padding: 10px;
    line-height: 20px;
    font-weight: bold;
    background-image: url('../assets/button1.png');
    background-size: 99% 40px;
    background-repeat:no-repeat;
    color: white;
  }
  /deep/ .choosedata .van-field__label{
    width: 30px;
  }
  /deep/ .choosedata .van-field__control{
    color: white;
  }
  /deep/ .chooseRadio .van-radio__label{
    color: white;
    font-size:14px;
  }
  .button1Style{
    background: linear-gradient(to bottom,rgb(89, 179, 248),rgb(83, 35, 246)) ;
    border:1px solid #b1bbe2;
    font-size: 14px;
    width:100%;
    height:30px;
    border-radius:6px;
  }
  .nextButton{
    background: linear-gradient(to bottom,rgb(89, 179, 248),rgb(83, 35, 246)) ;
    border:1px solid #aaa;
    color: #fff;
    font-size: 14px;
    width:22px;
    height:22px;
    border-radius:12px;
    padding: 0px;
    margin: 0px;
  }
  </style>


