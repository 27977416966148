<template>
  <div class="container row-center">
     <div class="homeButton" @click="gotoPlanet"></div>
     <div class="introduce tl">
       <div class="fwbolder introduce-title">使用方法:</div>
       <div class="introduce-text">1. 你在中心，离你越近的人，在这个时间段里对你的帮助越大。离你越远的人在这个时间段里比较容易成为你的小人。</div>
       <div class="introduce-text">2. 点击你身边的人,就会告诉你如何与他相处，他对你的帮助相比其他人有多大，他在这一年更关注什么，满足他这些，对于你的帮助就会更大。</div>
       <div class="introduce-text">注：每一个星球会以昵称的第一个字命名</div>
     </div>
  </div>
</template>
<script>
import wxlogin from 'vue-wxlogin';
export default {
  components: { wxlogin },
  name: 'Login',
  data() {
    return {
       show:false,
       count:0,
       timer:null,
    }
  },
  methods: {
     gotoPlanet(){
        this.$router.push('/planetList')
     }
  }
}
</script>
<style scoped>
.container{
    width:100%;
    height: 100vh;
    background-image: url('../assets/planetHome.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;
    padding:0px;
    margin:0px;
}
.homeButton{
  width:126px;
  height:40px;
  margin-top: 25vh;
  background-image: url('../assets/gotoButton.png');
  background-size: 99% 40px;
  background-repeat: no-repeat;
  cursor:pointer
}
.introduce{
  position:absolute;
  font-size: 14px;
  line-height: 18px;
  padding: 25px;
  bottom: 6px;
}
.introduce-title{
  padding:4px 0px;
  color: white;
  border-radius: 5px;
  font-size: 15px;
}
.introduce-text{
  color: white;
  padding:2px;
}
</style>
