<template>
   <div>
    <el-card>
      <div>
          <h3 class="margin:30px">喵星君大大排盘记录</h3>
          <div v-for="(item, index) in nameList" :key="index" style="margin-left:12px" >
              <div class="card-item row-spaceb">
                 <div @click="gotoPaipan(item.date,item.city,item.sex)">
                    <span style="margin-left:10px">{{index + 1}}.</span>
                    <span style="margin-left:10px">{{item.date}}</span>
                    <span style="margin-left:10px">{{item.sex}}</span>
                    <span style="margin-left:10px">{{item.city}}</span>
                 </div>
                 <el-button type="text" style="padding:0px" @click="handleDelete(item.Id)">删除</el-button>
              </div>
            <el-divider></el-divider>
          </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { postAction, getAction } from '@/api/manage'

export default {
  data () {
    return {
      page: 1,
      name: '',
      nameList: [{city:'n',sex:'a',date:'a'}],
      listLoading: false,
      dialogVisible: false,
      form: {},
      readFlag:'',
    }
  },
  mounted () {
    this.box()
  },
  computed:{
    weiboid() {
      return this.$store.getters.getweiboid || '';
    },
  },
  methods: {
    gotoPaipan(datetime, provincecity,sex){
            let url = '/wbfunc/wbpaipan/submit'
            const datetimeArr = datetime.split(' ')
            const date = datetimeArr[0].split('-')
            const time = datetimeArr[1].split(':')
            const params = {
                year: date[0],
                month: date[1],
                day: date[2],
                hour: time[0],
                minute: time[1],
                sex: sex,
                city: provincecity
            }
            postAction(url,params)
            .then((response) => {
                  console.log( 'responseData', response)
                  console.log('responseData',response.data.context)
                  sessionStorage.setItem("bornDate", JSON.stringify(response.data.context.bornDate))
                  sessionStorage.setItem("birthDay", JSON.stringify(response.data.context.birthDay))
                  sessionStorage.setItem("baziArray", JSON.stringify(response.data.context.baziArray))
                  sessionStorage.setItem("kongwangArray", JSON.stringify(response.data.context.kongwangArray))
                  sessionStorage.setItem("dayunArray", JSON.stringify(response.data.context.dayunArray))
                  sessionStorage.setItem("cangArray", JSON.stringify(response.data.context.cangArray))
                  sessionStorage.setItem("baziGodArray", JSON.stringify(response.data.context.baziGodArray))
                  sessionStorage.setItem("cangGodArray", JSON.stringify(response.data.context.cangGodArray))
                  sessionStorage.setItem("baziWangArray", JSON.stringify(response.data.context.baziWangArray))
                  sessionStorage.setItem("ysdz", JSON.stringify(response.data.context.yswords.dz))
                  sessionStorage.setItem("ystg", JSON.stringify(response.data.context.yswords.tg))
                  sessionStorage.setItem("sex", JSON.stringify(response.data.context.sexArray))
                  sessionStorage.setItem("city", JSON.stringify(response.data.context.cityArray))
                  sessionStorage.setItem("qiyun", JSON.stringify(response.data.context.qiyunArray))
                  this.$router.push({ path: '/paipanResult',query:{date:`${date[0]}-${date[1]}-${date[2]}-${time[0]}-${time[1]}`,city:provincecity}})
            })
    },
    box () {
      const url = '/wbfunc/wbpaipan/wbpplist'
          postAction(url, {weiboid:this.weiboid}).then( res => {
            this.nameList = res.data.data
          })
    },
    handleDelete(Id){
      const url = '/wbfunc/wbpaipan/del'
          postAction(url, {Id: Id}).then( res => {
              if( res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.box()
              }
              else{
                this.$message.warning(res.data.msg)
              }
          })
    }
  }
}
</script>
<style scoped>
  .fr{
     align-self:flex-end
  }
  .fl{
     align-self:flex-start
  }
  .content-head{
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0px 20px;
    background:#FFE0C7;
    border-radius: 10px;
  }
  .body-item{
    display:flex;
    justify-content:space-between
  }
  .small-font{
    font-size: 12px;
    color: #686868;
    margin-right:20px;
  }
  .iconSize{
    font-size: 40px;
  }
  .right-corner{
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 8px;
    position:absolute;
    left:-18px;
    top:-8px;
    text-align: center;
    background-color: #FDEDBE;
    border:2px solid #D2EDC8;
    border-radius:10px
  }
  .card-item{
    text-align: left;
    cursor: pointer;
  }
  .card-item :hover{
    color:blue;
  }
  .row-spaceb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
