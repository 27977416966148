<template>
  <div class="container">
     <img src="../assets/open.jpg" style="width:100%;height:100vh" alt="">
     <div class="count">
         {{count}}s
     </div>
  </div>
</template>
<script>
import wxlogin from 'vue-wxlogin';
export default {
  components: { wxlogin },
  name: 'Login',
  data() {
    return {
       show:false,
       count:0,
       timer:null
    }
  },
  created () {
    this.goGrdoupRecor();
  },
  methods: {
    goGrdoupRecor(){
        const TIME_COUNT = 3;
        if(!this.timer){
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(()=>{
            if(this.count > 0 && this.count <= TIME_COUNT){
            this.count--;
            }else{
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
              this.$router.push({
              path:'/home',
            })
            }
        },1000)
        }
    }
  }
}
</script>
<style scoped>
.container{
  position:relative;
  margin: 0 auto;
}
.count{
   color: #fff;
   position: absolute;
   top:10px;
   right: 20px; 
}

</style>
