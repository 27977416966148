<template>
  <div class="container pb30">
      <div class="color-gray66 pt20 pl30 tl fs20 fwbold">匹配结果<i class="el-icon-refresh-right ml15" @click="$router.go(0)"></i></div>
      <div class="user-info">
        <div class="img-head">
            <!-- <img src="../assets/logo.jpg" class="logoStyle" alt=""> -->
            <img src="@/assets/reliableHome.jpeg" class="logoStyle" alt="">
        </div>
        <div class="user-name ml10">
           <div class="mt6 fs18 fwbold">{{othername}}</div>
           <div class="mt6 fs16">{{otherbirthdayC}} &nbsp; {{othersex}} </div>
        </div>
      </div>
      <div class="content mt20">
          <div class="listBox1 tl">
              <div class="fs18 fwbold color-gray66"> <i class="iconfont icon-aixin"/> 短择可能性</div>
              <div class="percent mt15 tc">{{duanzeNum}}%</div>
              <div class="mt20 font-family-kai">解释：可用于同性或者异性，对于异性来说，该数字体现了短择你的可能性，对于同性来说，则代表了利用你的概率。超过70%则有比较大的短择倾向。</div>
          </div>
          <div class="listBox2 tl">
              <div class="fs18 fwbold color-gray66"> <i class="iconfont icon-aixin"/> 性格强弱对比</div>
              <div class="strong row-start mt20">
                <div class="fs16 mr5">你</div>
                <div class="row-start">
                    <div v-for="(item,index) in strong" :key="index" >
                        <img src='@/assets/progress.png' style="width:20px;height:20px;margin-left:4px"/>
                    </div>
                    <div v-for="(item,index) in (10-strong)" :key="index + 10" >
                      <img src='@/assets/progress2.png' style="width:20px;height:20px;margin-left:4px"/>
                    </div>
                </div>
                <div class="fs16 ml5">TA</div>
              </div>
              <div class="mt20 font-family-kai">解释：<img src='@/assets/progress.png' style="width:13px;height:13px;"/>代表了你强的程度，<img src='@/assets/progress2.png' style="width:13px;height:13px;"/>代表了对方强的程度。性格强弱代表了两个人在相处过程中，谁更有关系中的主动权，谁是两个人的“头”，谁会更听谁的话。“我”越强，“我”越能左右两个人的关系，受伤害的可能性就越小。</div>
          </div>
          <div class="listBox3 tl">
              <div class="fs18 fwbold color-gray66"> <i class="iconfont icon-aixin"/> 性格分布</div>
              <div class="mt20" id="four" style="width: 100%;height:210px;"></div>
              <div class="mt20 font-family-kai">解释：我们将人的缺点分为4类，“过于自信”、“贪图安逸”、“高冷傲慢”、“自私自利”，每一个人都有这四类缺点中的至少两类，并给出各个缺点的多少比例。如果你不能忍受的缺点比例高，那么大概率两个人的相处不会愉快。而如果你能忍受的缺点比例越高，两个人的相处则越容易融洽。
                  “过于自信”： 普信。“贪图安逸”：宅，懒，没有上进心。“高冷傲慢”：需要别人多付出。“自私自利”：以自我为中心考虑。</div>
          </div>
          <div class="listBox4 tl">
              <div class="fs18 fwbold color-gray66 mb20"><i class="iconfont icon-aixin"/> 金钱态度</div>
              <div class="fs16 mb20 fwbold color-gray66">大方程度
                <van-progress class="mt10" :percentage="paylove.clever" color="#f2826a" stroke-width="8" /></div>
              <div class="fs16 mb20 fwbold color-gray66">付出意愿
                <van-progress class="mt10" :percentage="paylove.passion" color="#f2826a" stroke-width="8" /></div>
              <div class="fs16 mb5 fwbold color-gray66">持久度
                <van-progress class="mt10" :percentage="paylove.insist" color="#f2826a" stroke-width="8" /></div>
              <div class="mt20 font-family-kai">解释：在金钱付出中，我们划分了三个数据，“付出意愿”、“大方程度”和“付出持久度”。这三个数据相互独立。“付出意愿”代表了对方为你付出的意愿，“大方程度”代表了单次高付出的概率，“付出持久度”代表了激情期过后的付出意愿。</div>
          </div>
          <div class="listBox5 tl">
              <div class="fs18 fwbold color-gray66"> <i class="iconfont icon-aixin"/> 情感关注项</div>
              <div class="mt20" id="five" style="width: 100%;height:210px;"></div>
              <div class="mt20 font-family-kai">解释：
                以上数据为在今年中对方在感情中会更想要什么，即如果你能优先满足对方最想要的，也就最大概率可以在一起。
                其中：
                <div><strong>自信满足</strong>代表了可以帮助对方完成Ta想做的事；</div>
                <div><strong>稳定感</strong>代表了给到对方在感情中的稳定感觉；</div>
                <div><strong>被崇拜/重视感</strong>代表了可以给对方更多的陪伴或者崇拜感；</div>
                <div><strong>物质满足</strong>代表了可以满足给对方想要的物质；</div>
                <div><strong>情绪价值</strong>代表了可以提供给对方足够的情绪价值。</div>
              </div>
          </div>
          <div class="listBox3 tl">
              <div class="fs18 fwbold color-gray66"> <i class="iconfont icon-aixin"/> 感情享受程度</div>
                <div class="fs16 mb20 fwbold color-gray66 mt20">{{stable.year1}}
                  <van-progress class="mt10" :percentage="stable.score1" color="#f2826a" stroke-width="8" />
                </div>
                <div class="fs16 mb20 fwbold color-gray66">{{stable.year2}}
                  <van-progress  class="mt10" :percentage="stable.score2" color="#f2826a" stroke-width="8" /></div>
                <div class="mt20 font-family-kai">解释：以上数据为今年和明年两年你和对方在感情中的享受程度，比例越高的人在感情中会更加享受。
                这种享受不是物质上的，而是情绪上的。这种情绪来源于对方给自己的情价和自己喜欢程度的总和。</div>
          </div>
          <div style="text-align:center;color:#444;margin-top:25px">
                <img src="@/assets/qrcode.jpg" style="width:30%"  alt="">
                <div style="font-size:14px;padding-top:15px">想了解更多性格分析，长按二维码关注：喵星君大大</div>
          </div>
      </div>
  </div>
</template>
<script>
import axios from 'axios'
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
import echarts from '@/echarts/index'
export default {
  name: 'success',
  data() {
    return {
     recordList:6,
     keylist:[
        {name:'自私自利',max:10},
        {name:'过于自信',max:10},
        {name:'高冷傲慢',max:10},
        {name:'贪图安逸',max:10}
     ],
     valueList:[8,5,1,3],
     otherbirthday:'',
     otherbirthdayC:'',
     othersex:'',
     othername:'',
     paylove:{
      clever:0,
      passion:0,
      insist:0
     },
     duanzeNum:0,
     distribute:{},
     strong:5,
     low:5,
     pieData:[
      { value: 335, name: "自信满足" },
      { value: 335, name: "稳定感" },
      { value: 315, name: "被崇拜/重视感" },
      { value: 200, name: "物质满足" },
      { value: 140, name: "情绪价值" },
     ],
    stable:{score1: 49.5, score2: 21.32, year1: "2023", year2: "2024"}
   };
  },
  computed:{
    weiboid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getweiboid || '';
    }
  },
  mounted(){
    if(this.$route.query.id){
    //    this.getOtherInfo(this.$route.query.id)
    // } else if(this.$route.query.otherbirthday && this.$route.query.othersex && this.$route.query.othername){
    //    this.othername = this.$route.query.othername
    //    if(this.weiboid){
    //      this.getDoubleInfo(this.weiboid, this.$route.query.otherbirthday,this.$route.query.othersex)
    //    }
      this.getDoubleInfo(this.weiboid, this.$route.query.id)
    }
  },
  activated(){
    if(this.$route.query.id){
      this.getDoubleInfo(this.weiboid, this.$route.query.id)
    }
  },
  methods:{
    doOption(keyList, valueList){
      let option = {
          legend: {
              bottom: 5,
              data: [''],
              itemGap: 16,
              textStyle: {
                  color: '#666',
                  fontSize: 16
              },
              selectedMode: 'single'
          },
          radar: {
              indicator: keyList,
              shape: 'circle',
              splitNumber: 5,
              radius:'60%',
              name: {
                  textStyle: {
                      // color: 'rgb(238, 197, 102)'
                      color: '#666',
                      fontSize: 15
                  }
              },
              splitLine: {
                  lineStyle: {
                      color: [
                         'rgba(238, 197, 102, 1)'
                      ].reverse()
                  }
              },

              splitArea: {
                  show: false
              },
              axisLine: {
                  lineStyle: {
                      color: 'rgba(238, 197, 102, 0.5)'
                  }
              }
          },
          series: [
              {
                  name: '北京',
                  type: 'radar',
                  lineStyle: {
                      normal: {
                          width: 3,
                          opacity: 0.5
                      }
                  },
                  data: [valueList],
                  symbol: 'none',
                  itemStyle: {
                      color: '#F9713C'
                  },
                  areaStyle: {
                      opacity: 0.2
                  }
              }
          ]
      }
      return option
    },
    drawLine (keyList, valueList) {
      var echarts = require('echarts');
      var myChart = echarts.init(document.getElementById('four'));
      myChart.setOption(this.doOption(keyList, valueList))
    },
    drawPie (dataList) {
      var echarts = require('echarts');
      var myChart = echarts.init(document.getElementById('five'));
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        color: ["#fd9173", "#f36f8a", "#5f71d2", "#42a4eb", "#4ac7f5"],
        legend: {
          //图例组件，颜色和名字
          left: "0",
          top: "0",
          orient: "vertical",
          itemGap: 12, //图例每项之间的间隔
          itemWidth: 10,
          itemHeight: 10,
          icon: "rect",
          data: ["自信满足", "稳定感", "被崇拜/重视感", "物质满足", "情绪价值"],
          textStyle: {
            color: [],
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 12,
          },
        },
        series: [
          {
            name: "占比",
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 20, //最小的扇区角度（0 ~ 360）
            center: ["60%", "50%"], //饼图的中心（圆心）坐标
            radius: [40, 80], //饼图的半径
            avoidLabelOverlap: true, ////是否启用防止标签重叠
            itemStyle: {
              //图形样式
              normal: {
                borderColor: "#1e2239",
                borderWidth: 1.5,
              },
            },
            label: {
              //标签的位置
              normal: {
                show: true,
                position: "inside", //标签的位置
                formatter: "{d}%",
                textStyle: {
                  color: "#fff",
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontWeight: "bold",
                },
              },
            },
            data: dataList,
          },
          {
            name: "",
            type: "pie",
            clockwise: false,
            silent: true,
            minAngle: 20, //最小的扇区角度（0 ~ 360）
            center: ["60%", "50%"], //饼图的中心（圆心）坐标
            radius: [0, 39], //饼图的半径
            itemStyle: {
              //图形样式
              normal: {
                borderColor: "#1e2239",
                borderWidth: 1.5,
                opacity: 0.21,
              },
            },
            label: {
              //标签的位置
              normal: {
                show: false,
              },
            },
            data: dataList,
          },
        ],
      };
      myChart.setOption(option)
    },
    getOtherInfo(id){
        const url = '/wbfunc/wbduanze/getOtherInfo'
        let birthdaylist = []
        postAction(url, {id:id}).then(res => {
          if (res.data.code == 200) {
                  this.otherbirthday = res.data.msg.birthday,
                  birthdaylist = this.otherbirthday.split('-')
                  this.otherbirthdayC = birthdaylist[0]+'年'+ birthdaylist[1]+'月'+birthdaylist[2]+'日'
                  this.othersex = res.data.msg.sex,
                  this.othername = res.data.msg.name
                  this.getDoubleInfo(this.weiboid, this.otherbirthday,this.othersex)
          }
        })
    },
    getDoubleInfo(weiboid,dbId){
      const url = '/wbfunc/wbduanze/result'
        postAction(url, {weiboid,dbId}).then(res => {
          if (res.data.code == 200) {
              this.paylove = res.data.msg.paylove,
              this.duanzeNum = res.data.msg.duanze.dzNum
              this.distribute = res.data.msg.disad
              this.strong = Number(res.data.msg.char.high)
              this.low = Number(res.data.msg.char.low)
              this.valueList[0] = res.data.msg.disad.love
              this.valueList[1] = res.data.msg.disad.confi
              this.valueList[2] = res.data.msg.disad.cold
              this.valueList[3] = res.data.msg.disad.live
              this.drawLine(this.keylist,this.valueList)
              this.pieData[0].value = res.data.msg.attention.money
              this.pieData[1].value = res.data.msg.attention.work
              this.pieData[2].value = res.data.msg.attention.leader
              this.pieData[3].value = res.data.msg.attention.live
              this.pieData[4].value = res.data.msg.attention.friend
              this.stable = res.data.msg.stable
              this.drawPie(this.pieData)
          }
        })
    },
    addDoubleData(unionid,otherbirthday,othersex,othername){
      const url = '/wbfunc/double/addDouble'
        postAction(url, {unionid,otherbirthday,othersex,othername}).then(res => {
          if (res.data.code == 200) {
              console.log('double数据增加成功')
          }
        })
    }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    min-height: 100vh;
    background-image: url('../../../assets/pink.jpg');
    /* background-image: linear-gradient(rgb(250,240,241), rgb(255,255,255));; */
    /* background-color: rgb(251,239,240); */
    /* background-color: rgba(252,227,210,0.8); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.fs-pink{
    color: rgb(235,170,172);
}
.content{
  /* border-radius: 30px 30px 0px 0px;
  margin: 5px 0px;
  padding-top:20px;
  height: 100%;
  background-image: url('../assets/pink.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 1px solid #fff */
  /* background:rgb(253,247,247); */
}
.listBox1{
  width: 75%;
  height: 200px;
  margin: 0px auto;
  padding: 10px 10px 10px 20px;
  border-radius: 0px 20px 20px 20px;
  /* box-shadow: 0px 5px 15px #bbb; */
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(252, 252, 252);
}
.listBox2{
  width: 75%;
  height: 213px;
  margin: 30px auto;
  padding: 20px 10px 10px 20px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(252, 252, 252);
}
.listBox3{
  width: 75%;
  min-height: 280px;
  margin: 30px auto;
  padding: 20px 10px 10px 20px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(252, 252, 252);
}
.listBox4{
  width: 75%;
  min-height: 110px;
  margin: 30px auto;
  padding: 20px 10px 10px 20px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(252, 252, 252);
}
.listBox5{
  width: 75%;
  min-height: 300px;
  margin: 30px auto;
  padding: 20px 10px 10px 20px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(252, 252, 252);
}
.user-name{
  text-align: left;
}
.mt6{
  margin-top: 6px;
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 30px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.percent{
    font-size: 35px;
    font-weight: 600;
    color:rgb(235,170,172);
}
.strong{
 height: 20px;
 line-height: 20px;
}
.font-family-kai{
  font-family: '华文细黑';
  color: #666;
  font-size: 14px;
  line-height: 24px;
}
</style>


