<template>
  <div class="container">
    <el-card class="box-card">
            <i class="el-icon-question fs20 iconstation" @click="getInfoDetail(item)"></i>
            <div style="text-align: center;font-size: 18px;">
                <span>出生于{{city}}，使用真太阳时，{{ sex }}</span>
            </div>
            <div class="drawRoute"></div>
           <el-row style="margin: 16px 0px 16px 8px; width:100%">
            <el-col :span="6">
                <div class="eightAll">
                    <div class="newDate">{{bornDate[0]}}年</div>
                    <div class="paralle mt10">
                     <div class="dizhiStyle" :style="dynamicColor(baziArray[0],baziWangArray[0])">
                        <span style="font-size: 30px">{{ baziArray[0] }} </span>
                        <!-- <i v-if="findYsT(baziArray[0])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                        <span v-if="findYsT(baziArray[0])" style="font-size: 10px;">用神</span>
                        <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                      </div>
                     <div class="cangFont">{{baziGodArray[0]}}</div>
                    </div>
                    <div class="paralle mt30" >
                     <div class="dizhiStyle" :style="dynamicColor(baziArray[1],baziWangArray[1])">
                      <span style="font-size: 30px">{{ baziArray[1] }} </span>
                      <!-- <i v-if="findYsD(baziArray[1])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                      <span v-if="baziArray[1] === kongwangArray[0] || baziArray[1] === kongwangArray[1]" style="font-size: 10px;">空亡</span>
                      <span v-else-if="findYsD(baziArray[1])" style="font-size: 10px;">用神</span>
                      <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                     </div>
                     <div class="cangFont">{{baziGodArray[1]}}</div>
                    </div>
                    <div class="cangAll">
                        <span class="cang" :style="dynamicColor(item,'empty')"  v-for="(item,index) in cangArray[0]" :key="index">{{item}}</span>
                    </div>
                    <div class="cangAll">
                      <span class="cangGod" v-for="(item,index) in cangGodArray[0]" :key="index">{{item}}</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="eightAll">
                    <div class="newDate" style="margin-left:16px">{{bornDate[1]}}月</div>
                    <div class="paralle mt10">
                      <div class="dizhiStyle" :style="dynamicColor(baziArray[2],baziWangArray[2])">
                        <span style="font-size: 30px">{{ baziArray[2] }} </span>
                        <!-- <i v-if="findYsT(baziArray[2])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                        <span v-if="findYsT(baziArray[2])" style="font-size: 10px;">用神</span>
                        <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                      </div>
                      <div class="cangFont">{{baziGodArray[2]}}</div>
                     </div>
                    <div class="paralle mt30" >
                      <div class="dizhiStyle" :style="dynamicColor(baziArray[3],baziWangArray[3])">
                       <span style="font-size: 30px">{{ baziArray[3] }} </span>
                        <!-- <i v-if="findYsD(baziArray[3])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                       <span v-if="baziArray[3] === kongwangArray[0] || baziArray[3] === kongwangArray[1]" style="font-size: 10px;">空亡</span>
                       <span v-else-if="findYsD(baziArray[3])" style="font-size: 10px;">用神</span>
                       <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                      </div>
                      <div class="cangFont">{{baziGodArray[3]}}</div>
                     </div>
                    <div class="cangAll">
                        <span class="cang" :style="dynamicColor(item,'empty')"  v-for="(item,index) in cangArray[1]" :key="index">{{item}}</span>
                    </div>
                    <div class="cangAll">
                      <span class="cangGod"  v-for="(item,index) in cangGodArray[1]" :key="index">{{item}}</span>
                    </div>
                  </div>
            </el-col>
            <el-col :span="6">
                <div class="eightAll">
                    <div class="newDate" style="margin-left:16px">{{bornDate[2]}}日</div>
                    <div class="paralle mt10">
                      <div class="dizhiStyle" :style="dynamicColor(baziArray[4],baziWangArray[4])">
                        <span style="font-size: 30px">{{ baziArray[4] }} </span>
                        <!-- <i v-if="findYsT(baziArray[4])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                        <span v-if="findYsT(baziArray[4])" style="font-size: 10px;">用神</span>
                        <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                      </div>
                      <div class="cangFont">日 主</div>
                     </div>
                    <div class="paralle mt30" >
                      <div class="dizhiStyle" :style="dynamicColor(baziArray[5],baziWangArray[5])">
                       <span style="font-size: 30px">{{ baziArray[5] }} </span>
                        <!-- <i v-if="findYsD(baziArray[5])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                       <span v-if="baziArray[5] === kongwangArray[0] || baziArray[5] === kongwangArray[1]" style="font-size: 10px;">空亡</span>
                      <span v-else-if="findYsD(baziArray[5])" style="font-size: 10px;">用神</span>
                       <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                      </div>
                      <div class="cangFont">{{baziGodArray[5]}}</div>
                     </div>
                    <div class="cangAll">
                        <span class="cang" :style="dynamicColor(item,'empty')"  v-for="(item,index) in cangArray[2]" :key="index">{{item}}</span>
                    </div>
                    <div class="cangAll">
                      <span class="cangGod"  v-for="(item,index) in cangGodArray[2]" :key="index">{{item}}</span>
                    </div>
                  </div>
            </el-col>
            <el-col :span="6">
                <div class="eightAll">
                    <div class="newDate" style="margin-left:16px">{{bornDate[3]}}时</div>
                    <div class="paralle mt10">
                      <div class="dizhiStyle" :style="dynamicColor(baziArray[6],baziWangArray[6])">
                        <span style="font-size: 30px">{{ baziArray[6] }} </span>
                        <!-- <i v-if="findYsT(baziArray[6])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                        <span v-if="findYsT(baziArray[6])" style="font-size: 10px;">用神</span>
                        <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                        </div>
                      <div class="cangFont">{{baziGodArray[6]}}</div>
                     </div>
                    <div class="paralle mt30" >
                      <div class="dizhiStyle" :style="dynamicColor(baziArray[7],baziWangArray[7])">
                       <span style="font-size: 30px">{{ baziArray[7] }} </span>
                        <!-- <i v-if="findYsD(baziArray[7])" class="iconfont icon-pingfenxingxing" style="color:rgb(229,64,79);position:absolute;top:2px;right:2px;font-size:6px"></i> -->
                       <span v-if="baziArray[7] === kongwangArray[0] || baziArray[7] === kongwangArray[1]" style="font-size: 10px;">空亡</span>
                       <span v-else-if="findYsD(baziArray[7])" style="font-size: 10px;">用神</span>
                       <span v-else style="font-size: 10px; visibility:hidden;">空</span>
                      </div>
                      <div class="cangFont">{{baziGodArray[7]}}</div>
                     </div>
                    <div class="cangAll">
                        <span class="cang" :style="dynamicColor(item,'empty')"  v-for="(item,index) in cangArray[3]" :key="index">{{item}}</span>
                    </div>
                    <div class="cangAll">
                      <span class="cangGod"  v-for="(item,index) in cangGodArray[3]" :key="index">{{item}}</span>
                    </div>
                  </div>
            </el-col>
            </el-row>
            <div class="drawRoute"></div>
            <el-row class="luckRoute">
                  <el-col class="showDYTitle"  v-for="(item, index) in dayunArray"  :key="index" :style="dynamicBackground(item.value)">
                   <div class="showDYValue"> <div>{{item.value - 10}}</div><div >·</div><div>{{item.value - 1 }}</div></div>
                   <div class="showDYLabel"> {{item.label}}</div>
                  </el-col>
             </el-row>
             <div class="drawRoute"></div>
             <el-row class="luckRoute">
                <el-col  v-for="(itemOutSide, indexOutSide) in dayunArray"  :key="indexOutSide">
                    <div class="showDYTitle">
                        <div v-if="(itemOutSide.value - 1934)%60 >= 51">
                            <div class="yearStyle" v-for="(item, index) in JZ60Table" :key="'JZ60a-'+index">
                               <span v-if="index >= (itemOutSide.value - 1934)%60" :style="fontColor(itemOutSide.value, index)">{{TDarray[Math.floor(JZ60Table[index]/100)]}}{{TDarray[JZ60Table[index]%100]}}</span>
                            </div>
                            <div class="yearStyle" v-for="(item, index) in JZ60Table" :key="'JZ60b-'+index">
                                <span v-if="index < (itemOutSide.value - 1934)%60 - 50" :style="fontColor(itemOutSide.value, index)">{{TDarray[Math.floor(JZ60Table[index]/100)]}}{{TDarray[JZ60Table[index]%100]}}</span>
                            </div>
                        </div>
                        <div v-else>
                            <div  class="yearStyle" v-for="(item, index) in JZ60Table" :key="index">
                                <span v-if="(index < (itemOutSide.value - 1934)%60 + 10 && index >= (itemOutSide.value - 1934)%60)" :style="fontColor(itemOutSide.value, index)">{{TDarray[Math.floor(JZ60Table[index]/100)]}}{{TDarray[JZ60Table[index]%100]}}</span>
                             </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="drawRoute"></div>
            <div style="text-align: center; margin-top:30px">
                <img style="width:160px;height:160px" src="../assets/qrcode.jpg" alt="code_image"/>
            </div>
            <div style="text-align: center;margin-top:30px">
                <p>关注微信公众号：喵星君大大</p>
                <p>免费占卜，获取原生家庭性格分析</p>
            </div>


     </el-card>
</div>
</template>
<script>
import {postAction} from '@/api/manage'
import { Toast } from 'vant';
export default {
  name: "PaipanResult",
  data() {
    return {
        currentYear : new Date().getFullYear(),
        JZ60Table :
        [
          111, 212, 313, 414, 515, 616, 717, 818, 919, 1020,//甲子,乙丑,丙寅,丁卯,戊辰,己巳,庚午,辛未,壬申,癸酉
          121, 222, 311, 412, 513, 614, 715, 816, 917, 1018,//甲戌,乙亥,丙子,丁丑,戊寅,己卯,庚辰,辛巳,壬午,癸未
          119, 220, 321, 422, 511, 612, 713, 814, 915, 1016,//甲申,乙酉,丙戌,丁亥,戊子,己丑,庚寅,辛卯,壬辰,癸巳
          117, 218, 319, 420, 521, 622, 711, 812, 913, 1014,//甲午,乙未,丙申,丁酉,戊戌,己亥,庚子,辛丑,壬寅,癸卯
          115, 216, 317, 418, 519, 620, 721, 822, 911, 1012,//甲辰,乙巳,丙午,丁未,戊申,己酉,庚戌,辛亥,壬子,癸丑
          113, 214, 315, 416, 517, 618, 719, 820, 921, 1022//甲寅,乙卯,丙辰,丁巳,戊午,己未,庚申,辛酉,壬戌,癸亥
        ],
          TDarray: ['空','甲','乙','丙','丁','戊','己','庚','辛','壬','癸','子','丑','寅','卯','辰','巳','午','未','申','酉','戌','亥'],
          city:'天津',
          sex:'男',
          bornDate:[1990, 1, 1, 12],
          baziArray:['戊', '辰', '己', '未', '癸', '巳', '丁', '巳'],
          kongwangArray:['午', '未'],
          dayunArray:[{label:'庚申', value: 1999}, {label:'辛酉', value:2009}, {label:'壬戌',value:2019}, {label:'癸亥', value: 2029}, {label:'甲子', value:2039}, {label:'乙丑', value:2049}, {label:'丙寅',value:2059}],
          cangArray:[['乙', '戊', '癸'], ['乙', '己', '丁'], ['丙', '戊', '庚'], ['丙', '戊', '庚']],
          baziGodArray:['正官', '正官', '偏官', '偏官', '偏财', '偏财', '偏财','偏财'],
          cangGodArray:[['食神', '正官', '比肩'], ['食神', '正官', '偏财'], ['偏财', '偏官', '正印'], ['正财', '偏官', '偏印']],
          baziWangArray:['旺', '旺', '旺', '旺', '衰', '旺', '旺', '旺'],
          ysword:{
            dz:[],
            tg:[]
          },
          ysdz:[],
          ystg:[],
          adminOpenIdList:
          ['oELC3s3dU6-9ih86vdizgMh7xrDo','oELC3s2EhhchC639dik5GereZ51w',
          'oELC3s0F_tCgJOnoWtz9dZC3lJwo','oELC3s-WtyTA5oLBHyixJKO1sjbk',
          'oELC3s-Utp8BqBcgYLnhPmn7PMeM','oELC3s95uaxHMiFS1Uwsa3OlM0MA',
          'oELC3syjyeqmJR9f05b6gawLa7Oc'],
          detailForm:{'friend': 0,'leader':0,'life':0,'money':0,'work':0}
    };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid || '';
    },
    isAdmin(){
    //  const findOpenIdIndex =  this.adminOpenIdList.findIndex(item === this.$route.query.openid )
        if(this.openid){
            const findOpenIdIndex = this.adminOpenIdList.findIndex(ele => ele === this.openid)
            if(findOpenIdIndex > -1){
                return 1
            } else {
                return 0
            }
        } else {
          return 0
        }
    },
  },
  mounted (){
      // that = this
      this.sex = JSON.parse(sessionStorage.getItem("sex"))
      this.city = JSON.parse(sessionStorage.getItem("city"))
      this.bornDate = JSON.parse(sessionStorage.getItem("bornDate"))
      this.baziArray = JSON.parse(sessionStorage.getItem("baziArray"))
      this.kongwangArray = JSON.parse(sessionStorage.getItem("kongwangArray"))
      this.dayunArray = JSON.parse(sessionStorage.getItem("dayunArray"))
      this.cangArray = JSON.parse(sessionStorage.getItem("cangArray"))
      this.baziGodArray = JSON.parse(sessionStorage.getItem("baziGodArray"))
      this.cangGodArray = JSON.parse(sessionStorage.getItem("cangGodArray"))
      this.baziWangArray = JSON.parse(sessionStorage.getItem("baziWangArray"))
      if(JSON.parse(sessionStorage.getItem("ysdz"))){
        this.ysdz = JSON.parse(sessionStorage.getItem("ysdz"))
      } else {
        this.ysdz = []
      }
      if(JSON.parse(sessionStorage.getItem("ystg"))){
        this.ystg = JSON.parse(sessionStorage.getItem("ystg"))
      } else {
        this.ysdz = []
      }
      // this.ysdz = JSON.parse(sessionStorage.getItem("ysdz")) || []
      // this.ystg = JSON.parse(sessionStorage.getItem("ystg")) || []
  },
  methods: {
           getInfoDetail(){
              const url = '/admin/paipandetail'
                  postAction(url, {date:this.$route.query.date,province:this.$route.query.city,gender: this.sex}).then( res => {
                   if(res.data.family){this.detailForm = res.data.family}
                   console.log('this.detailForm',this.detailForm)
                   Toast({
                      message: `财:${this.detailForm.money}\n 印:${this.detailForm.work}\n 比:${this.detailForm.friend}\n 食:${this.detailForm.life}\n 官:${this.isAdmin?this.detailForm.leader:'-'}`,
                      icon: 'wap-home-o',
                      duration:0,
                      closeOnClick:true
                    });
               })
           },
           startLuckJZ(year){
                return (year - 1924)%60
            },
            fontColor(year, index){
                if(this.currentYear >= (year - 10) && this.currentYear < year && index == (this.currentYear - 1984)){
                return {'background': '#D3EEF9' }
              } else{
                return {'background': 'white' }
              }
            },
            dynamicColor(word, wang) {
                let color = 'black'
                let backcolor = 'white'
                if (word === '甲'|| word === '乙' || word === '寅' || word === '卯') {
                    color = 'green'
                } else if (word === '丙'|| word === '丁' || word === '巳' || word === '午'){
                    // color = 'red'
                    color = 'rgb(229,64,79)'
                } else if(word === '庚'|| word === '辛' || word === '申' || word === '酉'){
                    // color = '#F6BD16'
                    color = 'rgb(223,146,25)'
                } else if(word === '壬'|| word === '癸' || word === '子' || word === '亥') {
                    color = 'black'
                } else if(word === '戊'|| word === '己' || word === '丑' || word === '辰' || word === '未' || word === '戌')  {
                    // color = 'brown'
                    color = 'rgb(113,100,56)'
                }

                if (wang === '旺') {
                    // backcolor = '#FFE0ED'
                    backcolor = 'rgb(251,221,224)'
                } else if (wang === '衰'){
                    // backcolor = '#CED4DE'
                    backcolor = 'rgb(220,218,218)'
                }
                return { 'color' :color,  'background-color': backcolor}
            },
            dynamicBackground(time){
              if(this.currentYear >= (time - 10) && this.currentYear < time){
                return {'background': '#D3EEF9' }
              } else{
                return {'background': 'white' }
              }
            },
            findYsD(value){
               if(this.ysdz.length > 0 && this.ystg.length === 0){
                  const findIndex = this.ysdz.findIndex(item => item === value)
                  if(findIndex > -1){
                    return 1
                  } else {
                    return 0
                  }
               } else {
                 return 0
               }
            },
            findYsT(value){
               if(this.ystg.length > 0){
                  const findIndex = this.ystg.findIndex(item => item === value)
                  if(findIndex > -1){
                    return 1
                  } else {
                    return 0
                  }
               } else {
                 return 0
               }
            }
  }
};
</script>
<style scoped>
  .tc{
      text-align: center;
  }
  .box-card{
    position: relative;
  }
  .iconstation{
    position:absolute;
    top:20px;
    right: 20px;
    color: #aaa;
  }
.container {
   width: 100%;
   max-width: 600px;
   margin-right: auto;
   margin-left: auto;
}
    .cangAll{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .cang{
        font-size: 14px;
        padding: 2px;
    }
    .cangGod{
        max-height: 38px;
        font-size: 12px;
        margin-right:2px;
        letter-spacing:4px;
        margin-left: 2px;
        text-align-last:justify;
        text-align:justify;
        text-justify:distribute-all-lines;
        writing-mode:vertical-lr;
    }
    .newDate{
        /* margin-left: -45px; */
        text-align: left;
        margin-left: 6px;
        /* margin-bottom: 10px; */
        font-size: 12px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.56) ;
    }
    .eightAll{
        display: flex;
        justify-content: center;
        flex-flow: column;
        flex:1
    }
    .tianganStyle{
        font-size: 30px;
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 0px;
    }
    .dizhiStyle{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        padding: 8px 10px 8px 10px;
        border-radius: 8px;
        margin-bottom: 5px;
        position: relative;
    }
    .paralle{
      display: flex;
      align-items: center;
      justify-content:space-between;
    }
    .cangFont{
      width:80%;
      height: 40px;
      font-size: 12px;
      text-align-last:justify;
      text-align:justify;
      text-justify:distribute-all-lines;
      writing-mode:vertical-lr;
    }
    .drawRoute{
      width:95%;
      height:1px;
      margin:10px auto;
      padding:0px;
      background-color:#D5D5D5;
      overflow:hidden;
    }
    .showDYValue{
      display: flex;
      flex-flow: column;
      justify-content: center;
      font-size:12px;
    }
    .showDYLabel{
      font-size: 16px;
      letter-spacing:2px;
      margin-top: 6px;
    }
    .luckRoute{
      display: flex;
      justify-content: center;
    }
    .showDYTitle{
        display: flex;
        flex-flow: column;
        text-align:center;
        justify-content: center;
        border-radius: 8px;
    }
    .yearStyle{
        margin-left: 4px;
        margin-bottom: 10px;
        font-size: 1em;
        letter-spacing:2px;
    }
   .small-font {
      font-size:10px;
      text-align:center;
      -webkit-transform-origin-x: 0;
      -webkit-transform: scale(0.50);
    }
</style>
