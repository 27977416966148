<template>
  <div style="padding:0px 20px; background:#fff">
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>服务流程</h2>
      </div>
      <el-collapse v-model="activeNames" >
        <el-collapse-item title="工作总则" name="1">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:30px">
              <iframe src="http://www.miaoxingjundd.com/htmlfile/background.html" frameborder="0" style="width:100%;height:500px" id="iframeBox1"></iframe>
            </div>
        </el-collapse-item>
        <el-collapse-item title="工作流程" name="2">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:30px">
              <iframe src="http://www.miaoxingjundd.com/htmlfile/jobDetail.html" frameborder="0" style="width:100%;height:600px" id="iframeBox2"></iframe>
            </div>
        </el-collapse-item>
        <el-collapse-item title="后端流程" name="3">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:30px">
            <iframe src="http://www.miaoxingjundd.com/htmlfile/backendFlow.html" frameborder="0" style="width:100%;height:600px" id="iframeBox3"></iframe>
            </div>
        </el-collapse-item>
        <el-collapse-item title="前端流程" name="4">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:30px">
            <iframe src="http://www.miaoxingjundd.com/htmlfile/frontendFlow.html" frameborder="0" style="width:100%;height:600px" id="iframeBox4"></iframe>
            </div>
        </el-collapse-item>
        <el-collapse-item title="纳新流程" name="5">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:30px">
              <iframe src="http://www.miaoxingjundd.com/htmlfile/inviteNew.html" frameborder="0" style="width:100%;height:500px" id="iframeBox5"></iframe>
            </div>
        </el-collapse-item>
        <el-collapse-item title="晋升流程" name="6">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:30px">
              <iframe src="http://www.miaoxingjundd.com/htmlfile/upFlow.html" frameborder="0" style="width:100%;height:600px" id="iframeBox6"></iframe>
            </div>
        </el-collapse-item>
      </el-collapse>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      userlist:[],
      activeName:'first',
      publicPath: './',
      activeNames:'',
      backgtoundUrl:'<%= BASE_URL %>outside/background.html',
      jobDetailUrl:'<%= BASE_URL %>outside/jobDetail.html',
      backendFlowUrl:'./outside/backendFlow.html',
      frontendFlowUrl:'./outside/frontendFlow.html',
      inviteNewUrl:'./outside/inviteNew.html',
    }
  },
  mounted() {
    this.getActiveName()
  },
  computed:{
      auth(){
          return this.$route.query.auth
      }
  },
  methods: {
    getActiveName(){
      if(this.auth == 5){
        this.activeName = 'first';
      } else if(this.auth == 2) {
        this.activeName = 'second';
      } else if(this.auth == 3) {
        this.activeName = 'third';
      }
    },
    handleClick(){
      if(this.activeName === 'first'){
      } else if(this.activeName === 'second'){

      }
    },
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
.scroll-wrapper { position: fixed; right: 0; bottom: 0; left: 0; top: 0; -webkit-overflow-scrolling: touch; overflow-y: scroll; }
.scroll-wrapper iframe { height: 100%; width: 100%; }
</style>

