<template>
  <div class="container">
    <el-card>
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>长期曲线</h2>
      </div>
      <van-form @submit="onSubmit">
          <!-- 时间选择器-->
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="datetime"
          label="出生时间选择"
          placeholder="点击选择时间"
          style="margin:30px 0px"
          @click="showPicker2 = true"
        />
        <van-popup v-model="showPicker2" position="bottom">
          <van-datetime-picker
            type="datetime"
            v-model="currentDate"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirmTime"
            @cancel="showPicker2 = false"
          />
        </van-popup>
          <!-- 性别选择器-->
        <van-field style="margin:30px 0px" name="radio" label="性别选择">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="女">女生</van-radio>
              <van-radio name="男">男生</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="year" type="number" label="年份跨度" />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">查询</van-button>
        </div>
      </van-form>
      <!-- <h2 class="pb20">用户列表</h2> -->
      <el-table
        style="margin-top:30px"
        :data="list"
        border
        fit
        highlight-current-row
        :header-cell-style="{ background: '#f8f8f8' }"
      >
        <el-table-column align="center" label="序号" min-width="60">
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column label="年份" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.year }}</span>
          </template>
        </el-table-column>
        <el-table-column label="财" min-width="100" align="center">
          <template slot-scope="scope">
            {{ scope.row.cai }}
          </template>
        </el-table-column>
        <el-table-column label="印" min-width="100" align="center">
          <template slot-scope="scope">
            {{ scope.row.yin }}
          </template>
        </el-table-column>
      </el-table>
      <div class="mt20">
          <div id="main" style="width: 100%;height:300px;"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getAction,postAction  } from '@/api/manage'
import echarts from '../echarts/index'
export default {
  data() {
    return {
      list: [
      ],
      showPicker2:false,
      datetime:'',
      sex:'',
      year:'',
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      listLoading:false,
      cailist:[],
      yinlist:[],
      yearlist:[]
    }
  },
  created() {
  },
  computed:{
    Id(){
      return this.$route.query.Id ? this.$route.query.Id : ''
    }
  },
  methods:{
    onSubmit(){
      let url = '/oa/lifeturn'
      const msg = this.datetime + ' ' + this.sex
      if(!this.Id){
        postAction(url,{msg:msg,year:Number(this.year)}).then( res => {
            if( res.data.code == 200) {
                this.cailist = res.data.msg.cai
                this.yinlist = res.data.msg.yin
                this.yearlist = res.data.msg.year
                this.list = []
                this.yearlist.forEach((item,index) => {
                    const params = {
                      year:item,
                      yin:this.yinlist[index],
                      cai:this.cailist[index]
                    }
                    this.list.push(params)
                })
                this.drawLine1(this.yearlist,this.cailist,this.yinlist,'财印曲线对比','#3ba272')
            }
            else{
              this.$message.error(res.data.msg)
            }
        })
      } else {
        postAction(url,{msg:'',year:Number(this.year),Id:this.Id}).then( res => {
            if( res.data.code == 200) {
                this.cailist = res.data.msg.cai
                this.yinlist = res.data.msg.yin
                this.yearlist = res.data.msg.year
                this.list = []
                this.yearlist.forEach((item,index) => {
                    const params = {
                      year:item,
                      yin:this.yinlist[index],
                      cai:this.cailist[index]
                    }
                    this.list.push(params)
                })
                this.drawLine1(this.yearlist,this.cailist,this.yinlist,'财印曲线对比','#3ba272')
            }
            else{
              this.$message.error(res.data.msg)
            }
        })
      }
    },
    onConfirmTime(time) {
      console.log('values',time)
      this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
      console.log('values',this.datetime)
      this.showPicker2 = false;
    },
    parseTime (time, cFormat) {
      if (arguments.length === 0) {
        return null
      }
      if (!time) return ''
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },
    drawLine1 (yearlist,cailist,yinlist,text,color) {
      var echarts = require('echarts');
      var myChart = echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      var option = {
      title: {
              text: '',
              //标题样式
              textStyle: {
                  fontWeight: 'normal',
                  color: '#fff',
              },
              left: 50,
          },
        color:['#3ba272'],
        xAxis: {
          type: 'category',
          data: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        series: [
          {
            data: [3, 5, 4, 1, 4, 5, 1,2,2,5],
            type: 'line',
            smooth: true
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(this.setOptionData(yearlist,cailist,yinlist,text));
    },
    setOptionData(yearlist,cailist,yinlist,text){
     var option = {
      title: {
              text: text,
              //标题样式
              textStyle: {
                  fontWeight: 'normal',
                  color: '#000',
              },
              left: 50,
          },
        legend: {
           show: true
        },
        color:['#ee6666','#3ba272'],
        xAxis: {
          type: 'category',
          data: yearlist,
          axisLine: {
              lineStyle: {
                  color: '#000'
              }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
              lineStyle: {
                  color: '#000'
              }
          },
        },
        series: [
          {
            data: cailist,
            type: 'line',
            smooth: true
          },
          {
            data: yinlist,
            type: 'line',
            smooth: true
          },
        ]
      };
      return option
    },
  }
}
</script>
<style scoped>
.content-wrap {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right:10px ;
  justify-content: flex-start;
}
.content {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding-bottom: 32px;
  /* width: 70%; */
  font-weight: 500;
 }
.contentTitle {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  align-items: center;
  line-height: 20px;
  margin-right: 8px;
  font-weight: 600;
  padding-bottom: 32px;
  white-space: nowrap;
 }
.contentTitle::after {
    content: ':';
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
  }
  .pb20 {
    padding-bottom: 20px;
  }
  .container {
   width: 100%;
   min-height: 600px;
   margin-right: auto;
   margin-left: auto;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>
