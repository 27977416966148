<template>
  <div class="container">
      <div class="user-info">
        <div class="img-head">
             <img src="@/assets/avater.jpg" class="logoStyle" alt="">
            <!-- <img :src="headimg" class="logoStyle" alt=""> -->
        </div>
        <div class="user-name ml10">
           <div class="mt6 fs18">{{username}}</div>
        </div>
      </div>
      <div class="tc">
        <i class="el-icon-refresh-right" @click="$router.go(0)"></i>
      </div>
      <van-form >
        <div class="formClass">
          <van-field
            readonly
            clickable
            :disabled="disabledFlag"
            name="datetimePicker"
            :value="datetime"
            label="你的生日"
            style="margin:16px 0px"
            @click="selectDate"
          />
          <van-popup  v-model="showPicker2" position="bottom">
            <van-datetime-picker
              type="datetime"
              v-model="currentDate"
              title="选择完整时间"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmTime"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            :disabled="disabledFlag"
            label="你的出生地"
            name="area"
            :value="provincecity"
            style="margin:16px 0px"
            @click="selectCity"
          />
          <van-field name="radio" label="你的性别"  style="margin:16px 0px">
            <template #input>
              <van-radio-group v-model="sex" :disabled="disabledFlag" direction="horizontal">
                <van-radio name="男">男生</van-radio>
                <van-radio name="女">女生</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              :columns-num="2"
              @confirm="onConfirmCity"
              @cancel="showArea = false"
            />
          </van-popup>
        </div>

        <div class="formClassOther">
          <van-field
            v-model="usernameOther"
            name="TA的昵称"
            label="TA的昵称"
            placeholder="TA的昵称"
          />
          <van-field
            readonly
            clickable
            name="datetimePicker"
            :value="datetimeOther"
            label="TA的生日"
            style="margin:16px 0px"
            @click="showPicker3 = true"
          />
          <van-popup v-model="showPicker3" position="bottom">
            <van-datetime-picker
              type="date"
              v-model="currentDate"
              title="选择完整时间"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmTime2"
              @cancel="showPicker3 = false"
            />
          </van-popup>
          <van-field name="radio" label="TA的性别" style="margin:16px 0px">
            <template #input>
              <van-radio-group v-model="sexOther" direction="horizontal">
                <van-radio name="男">男生</van-radio>
                <van-radio name="女">女生</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
      </van-form>

      <div class="mt20 ml40 mr40 tl lineheight22">
        <span class="fwbold">介绍</span>：<span style="font-style:15px">如果你想知道和一个人的关系(同性异性均可)，但是不知道Ta的具体时间，那么可以用这个小程序，了解你们俩相处的状态。我们会告诉你对方的短择概率(用于异性)，两个人性格上谁更强势，Ta的缺点分布和对于金钱的态度。因为没有具体时间，只是提供这一类人的大致状况。可点击<span class="fwbold" @click="imageView">预览。</span></span>
      </div>

      <div class="row-center" style="margin: 20px 0px">
        <van-button :loading="loading" loading-text="请求中..." class="button1Style" block type="info" native-type="submit" @click="onSubmit">
          开始测试<span>￥9.9</span>
        </van-button>
        <van-button :loading="loading" loading-text="请求中..." class="button2Style ml20" block type="info" @click="recordList">
          匹配记录
        </van-button>
      </div>
      <div style="text-align:center;color:#666;margin-top:25px">
            <img src="@/assets/kefu.jpg" style="width:30%"  alt="">
             <div style="font-size:14px;margin:10px;padding-bottom:20px">系统问题反馈请长按二维码，添加微信。</div>
      </div>
  </div>
</template>
<script>
import axios from 'axios'
import { areaList } from '@vant/area-data';
import { ImagePreview,Toast } from 'vant';
import {postAction,getAction } from '@/api/manage'
import wx from 'weixin-jsapi'
export default {
  name: 'success',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      username: '',
      usernameOther:'',
      image:'../assets/reliableHome.jpeg',
      sex:'',
      sexOther:'男',
      password: '',
      provincecity:'',
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      datetime: '',
      datetimeOther: '',
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      loading:false,
      infoWord:'',
      status:'',
      disabledFlag:false,
      userInfo:{}
   };
  },
  computed:{
    weiboid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getweiboid || '';
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  mounted(){
    if(!this.weiboid){
      if(!this.code){
        var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/weiboreliaregister')
        window.location.href=`https://api.weibo.com/oauth2/authorize?client_id=3852406503&response_type=code&redirect_uri=`+ url
      } else {
         this.getJsLogin()
      }
    } else {
      console.log('weiboid')
      this.getLoveSingle()
    }
  },
  methods:{
            refreshweb(){
              this.$router.go(0)
            },
            getJsLogin(){
                const url = '/weibo/wblogin'
                postAction(url, {code:this.code}).then( res => {
                  this.userInfo = res.data.data
                  this.$store.commit("setweiboid", this.userInfo.id);
                  this.$store.commit("setweiboname", this.userInfo.name);
                  this.$store.commit("setweiboimg", this.userInfo.cover_image_phone);
                  this.$store.commit("setweibofoll", this.userInfo.followers_count);
                  this.$store.commit("setweibodomin", this.userInfo.description);
                      const url2 = '/wbfunc/wblogin/islg'
                      postAction(url2, {code:this.userInfo.id}).then( res => {
                        if(res.data.code == 400){
                          this.$router.push({path:'/weibouserRegister'})
                        } else {
                           this.getLoveSingle()
                        }
                      })
                })
            },
            getLoveSingle(){
                const url = '/wbfunc/wbduanze/getInfo'
                postAction(url, {weiboid:this.weiboid}).then(res => {
                  if (res.data.code == 200) {
                      this.info = res.data.msg
                      this.username = this.info.name
                      this.datetime = this.info.birthday
                      this.provincecity = this.info.city
                      this.sex = this.info.sex
                      this.status = this.info.status
                      this.disabledFlag = true
                  }
                })
            },
            recordList(){
              this.$router.push({ path: '/weiborelialist'})
            },
            imageView(){
              ImagePreview({images:['http://www.miaoxingjundd.com/reliableResult.jpg'], closeable: true});
            },
            onConfirmCity(values) {
              this.provincecity = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('/');
                console.log('values',this.provincecity)
                const city = this.provincecity.split('/')
                this.provincecity = city[1]
              this.showArea = false;
            },
            selectCity(){
              if(!this.disabledFlag){
                this.showArea = true
              }
            },
            selectDate(){
              if(!this.disabledFlag){
                this.showPicker2 = true
              }
            },
            onConfirmTime(time) {
              console.log('values',time)
              this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
              console.log('values',this.datetime)
              this.showPicker2 = false;
            },
            onConfirmTime2(time) {
              this.datetimeOther = this.parseTime(time,'{y}-{m}-{d}' )
              this.showPicker3 = false;
            },
            parseTime (time, cFormat) {
              if (arguments.length === 0) {
                return null
              }
              if (!time) return ''
              const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
              let date
              if (typeof time === 'object') {
                date = time
              } else {
                if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                  time = parseInt(time)
                }
                if ((typeof time === 'number') && (time.toString().length === 10)) {
                  time = time * 1000
                }
                date = new Date(time)
              }
              const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay()
              }
              const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
                let value = formatObj[key]
                // Note: getDay() returns 0 on Sunday
                if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
                if (result.length > 0 && value < 10) {
                  value = '0' + value
                }
                return value || 0
              })
              return timeStr
            },
            onSubmit(){
              if(this.datetime == '' || this.provincecity == '' || this.datetimeOther == '' || this.usernameOther == ''){
                Toast.fail('输入不能为空');
                return
              }
              if(!this.weiboid){
                Toast.fail('请关闭页面,重新进入获取个人信息');
                return
              }
              this.$router.push({ path: '/reliableAlipay', query:{otherbirthday: this.datetimeOther,othersex: this.sexOther,othername:this.usernameOther }})
            },
            // onSubmit(){
            //   const url = '/wbfunc/wbduanze/addDouble'
            //     postAction(url, {weiboid:this.weiboid,otherbirthday: this.datetimeOther,othersex: this.sexOther,othername:this.usernameOther}).then(res => {
            //       if (res.data.code == 200) {
            //          const url2 = '/wbfunc/wbduanze/payreport'
            //           postAction(url2, {re_id:res.data.data.Id}).then(res => {
            //               window.location.href = "http://localhost:8080/weiboreliaresult?otherbirthday=" + this.datetimeOther + "&othersex=" + this.sexOther + "&othername=" + this.usernameOther
            //           })
            //       }
            //     })
            // }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100vh;
    /* background-image: url('../assets/registerBg.png'); */
    /* background-image: linear-gradient(rgb(250,240,241), rgb(255,255,255));; */
    background-image: linear-gradient(rgb(242,197,204), rgb(255,255,255));;
    background-size: 99% 100%;
    background-repeat: no-repeat;

}
.formClass{
  width: 75%;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 0px 30px 30px 30px;
  background: #fff;
  box-shadow: 0px 4px 6px #cccccc;
}
.formClassOther{
  width: 75%;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 30px 0px 30px 30px;
  box-shadow: 0px 4px 6px #cccccc;
  background: #fff;
}
/deep/.van-field__label{
   width: 6em;
}
/deep/.van-cell{
  background: rgb(233,230,230);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-radio__label{
  color: #333;
}
/deep/ input{
  color: #333;
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.user-name{
  text-align: left;
}
.mt6{
  margin-top: 6px;
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.button1Style{
  /* background:rgb(242,197,204); */
  background:rgb(255,174,191);
  border:1px solid rgb(242,197,204);
  font-size: 19px;
  width:150px;
  padding: 0px 5px;
  border-radius:6px;
}
.button2Style{
  background:rgb(182,182,182);
  border:1px solid rgb(182,182,182);
  font-size: 20px;
  width:150px;
  border-radius:6px;
}
.lineheight22{
  line-height: 24px;
}
.van-field__control:disabled {
    color: #999;
    cursor: not-allowed;
    opacity: 1;
    -webkit-text-fill-color: #c8c9cc;
}
</style>


