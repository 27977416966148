<template>
  <div style="width:100%;height:100vh" class="col-center">
    <div class="container">
      <!-- 增加注释 -->
      <img src="@/assets/reliableHome.jpg" style="width:100%;height:100vh" alt="">
      <!-- <img src="../assets/botton.jpg" style="width:100px;height:40px" alt=""> -->
      <van-button class="generate2 buttonColor2" round block type="info" native-type="submit" @click="gotoDetail">开始测试</van-button>
    </div>
  </div>

</template>
<script>
import {postAction} from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      loading:false,
      userInfo:{}
    }
  },
  computed:{
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
    weiboid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getweiboid || '';
    },
  },
  mounted(){

  },
  methods: {
     gotoDetail(){
       this.$router.push({path:'/weiboreliaregister'})
      //  if(!this.weiboid){
      //     this.getweiboid()
      //   } else {
      //     const url = '/wbfunc/wblogin/islg'
      //     postAction(url, {code:this.weiboid}).then( res => {
      //        if(res.data.code == 400){
      //          this.$router.push({path:'/weibouserRegister'})
      //        } else {
      //          this.$router.push({path:'/weiboreliaregister'})
      //        }
      //     })
      //   }
     },
      getweiboid(){
        if(!this.code){
          var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/weiboreliahome')
          window.location.href=`https://api.weibo.com/oauth2/authorize?client_id=3852406503&response_type=code&redirect_uri=`+ url
        }else {
          this.getJsLogin()
        }
      },
      getJsLogin(){
        const url = '/weibo/wblogin'
            postAction(url, {code:this.code}).then( res => {
               this.userInfo = res.data.data
               this.$store.commit("setweiboid", this.userInfo.id);
               this.$store.commit("setweiboname", this.userInfo.name);
               this.$store.commit("setweiboimg", this.userInfo.cover_image_phone);
               this.$store.commit("setweibofoll", this.userInfo.followers_count);
               this.$store.commit("setweibodomin", this.userInfo.description);
            })
      },
  }
}
</script>
<style scoped>
.container{
  position:relative;
  max-width: 400px;
  margin: 0 auto;
}
.generate2{
  position: absolute;
  bottom:20%;
  left: 22%;
  width: 220px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
}
.buttonColor2{
  background-image: url('../../../assets/botton.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  border: 1px solid #fff;
}
</style>
