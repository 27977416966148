<template>
  <div class="container pd20">
      <div class="fs26">
           题目测试
      </div>
      <div class="mg10">
         <el-tag class="fs24">第一级</el-tag>
      </div>
      <div class="mg20">
            <h4>{{database[currentIndex].title}}</h4>
            <el-radio-group v-model="answer" change="judgeAnswer">
                <el-radio :label="database[currentIndex].answer1">{{database[currentIndex].answer1}}</el-radio>
                <el-radio :label="database[currentIndex].answer2">{{database[currentIndex].answer2}}</el-radio>
                <el-radio :label="database[currentIndex].answer3">{{database[currentIndex].answer3}}</el-radio>
                <el-radio :label="database[currentIndex].answer4">{{database[currentIndex].answer4}}</el-radio>
            </el-radio-group>
      </div>
      <div class="mg10">
         当前正确数：{{rightAnswerNum}}, 当前错误数：{{errorAnswerNum}}
      </div>
      <div class="mt30">
          <el-button type="primary" icon="el-icon-arrow-left" @click="subNum">上一题</el-button>
          <el-button type="primary" @click="addNum">下一题<i class="el-icon-arrow-right el-icon--right"></i></el-button>
      </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
       answer:'',
       currentIndex:0,
       database:[
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'庚属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'金',
          currentAnswer:'',
          grade:1
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'酉属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'金',
          currentAnswer:'',
          grade:1
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'戌属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'土',
          rightAnswer:'土',
          currentAnswer:'',
          grade:1
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'亥属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'水',
          currentAnswer:'',
          grade:1
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'子属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'水',
          currentAnswer:'',
          grade:1,
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'丑属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'土',
          rightAnswer:'土',
          currentAnswer:'',
          grade:1,
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'寅属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'木',
          currentAnswer:'',
          grade:1,
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'卯属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'木',
          currentAnswer:'',
          grade:1,
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'辰属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'土',
          rightAnswer:'土',
          currentAnswer:'',
          grade:1,
        },
        {
          category:'基础知识',
          subcategory:'天干地支',
          title:'巳属于什么五行？',
          answer1:'金',
          answer2:'木',
          answer3:'水',
          answer4:'火',
          rightAnswer:'火',
          currentAnswer:'',
          grade:1,
        }
       ],
       rightAnswerNum:0,
       errorAnswerNum:0,
       totalNum:5,
       grade:0,
       rightRate: 0.8,
       errorRate: 0.5
    }
  },
  created () {

  },
  methods: {
    addNum(){
     if(this.currentIndex < this.totalNum){
        // if(){

        // }
        this.currentIndex =  this.currentIndex + 1
     }
    },
    subNum(){
     if(this.currentIndex > 0){
       this.currentIndex =  this.currentIndex - 1
     }
    },
    judgeAnswer(){

    }
  }
}
</script>
<style scoped>
.container{
  position:relative;
  margin: 0 auto;
  min-height: 700px;
  width: 100%;
}
.count{
  color: #fff;
  position: absolute;
  top:10px;
  right: 20px;
}

</style>
