<template>
  <div class="login-container">
    <h2>客户成交行为预测系统登录</h2>
    <van-form @submit="handleLogin">
      <van-field
        v-model="username"
        name="用户名"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px;">
        <van-button :loading="loading" round block type="info" native-type="submit">提交</van-button>
      </div>
      <div style="text-align:center;margin-top:16px">
         <el-button type="text" @click="changepassword">修改密码</el-button>
      </div>
       <van-popup v-model="show" position="bottom" :style="{ height: '50%',padding:'10px' }" >
            <van-form @submit="changeSubmit">
                <van-field
                  v-model="username"
                  name="用户名"
                  label="用户名"
                  placeholder="用户名"
                />
                <van-field
                  v-model="oldpassword"
                  name="原密码"
                  label="原密码"
                  type="password"
                  placeholder="原密码"
                />
                <van-field
                  v-model="newpassword"
                  name="新密码"
                  label="新密码"
                  type="password"
                  placeholder="新密码"
                />
            <div style="margin: 16px;">
                <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
            </div>
            </van-form>
       </van-popup>
    </van-form>
  </div>
</template>

<script>
import { postAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      username:'',
      password:'',
      loading: false,
      redirect: undefined,
      show:false,
      oldpassword:'',
      newpassword:'',
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    changepassword(){
       this.show = true
    },
    handleLogin(values) {
        this.loading = true
        const url = '/clientCtr/login'
        postAction( url, {username:this.username,password:this.password }).then( res => {
        this.loading = false
        if( res.data.code == 200) {
           localStorage.setItem("clientname", this.username)
           this.$router.push({name: 'clienthome'})
        }
        else{
          this.$message.warning(res.data.mag)
        }
        }).catch(() => {
            this.loading = false
        })
          // this.$store.dispatch('user/login', this.loginForm).then(() => {
          //   // this.$router.push({ path: this.redirect || '/' })
          //   this.loading = false
          //    this.$router.push({name: 'UserQuery'})
          // }).catch(() => {
          //   this.loading = false
          // })
    },
    changeSubmit(){
        this.loading = true
        const url = '/oa/updatePassword'
        postAction( url, {username:this.username,oldpassword:this.oldpassword,newpassword:this.newpassword,type:'client' }).then( res => {
        this.loading = false
        if( res.data.code == 200) {
          this.$message.success('修改成功')
          this.show = false
        }
        else{
          this.$message.warning(res.data.msg)
        }
        }).catch(() => {
            this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.login-container{
  margin-top:50px;
  background: #fff;
}
</style>

