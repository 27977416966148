<template>
  <div>
    <div class="title-container">
      <div class="row-center">
        <div class="img-head">
            <img :src="headimg" class="logoStyle" alt="">
        </div>
      </div>
      <div>
          <div class="title2 mt10">{{name}}</div>
      </div>
        <div>
          <div class="title3 mt10">{{datetime}}</div>
      </div>
      <div class="content-container mt20">
          <div class="row-center-col-center pt30 pb20">
              <img src="../assets/yezi1.png" style="width:50px;height:16px" alt="">
              <div class="title4 ml5 mr5">每日运势</div>
              <img src="../assets/yezi2.png" style="width:50px;height:16px" alt="">
          </div>
          <div class="row-start-col-center ml30">
             <img src="../assets/index.png" style="width:7px;height:20px" alt="">
              <div class="title5 ml5 mr5">上周运势曲线图</div>
          </div>
          <div class="ml15 mr15">
              <div id="main1" style="width: 98%;height:260px;"></div>
          </div>
          <div class="divider-line"></div>
          <div class="row-spaceb mt15">
            <div class="row-start-col-center ml30">
              <img src="../assets/index.png" style="width:7px;height:20px" alt="">
                <div class="title5 ml5 mr5">未来运势曲线图</div>
            </div>
            <div class="row-start choose">
              <van-field
                readonly
                clickable
                label="选择查看天数"
                name="picker"
                :value="dateNumber"
                placeholder="点击选择查看天数"
                @click="showPicker2 = true"
              />
              <van-popup v-model="showPicker2" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="columns"
                  @confirm="onConfirmnum"
                  @cancel="showPicker2 = false"
                />
              </van-popup>
            </div>
          </div>
          <div class="mt20 back mr10">
              <div class="button">
                <el-button :loading="loading" size="small" class="buttonclass" type="primary" @click="gotobackluck">解锁后运(￥0.88/天)</el-button>
              </div>
              <div id="main2" style="width: 98%;height:260px;"></div>
          </div>
          <div class="textcard">
              <div class="fwbold">说明：</div>
              <div class="mt5">1、点击<span class="fwbold" @click="imageView">这里</span>预览你将获得的内容。</div>
			  <div class="mt5">2、请先确认前几日运势符合你的实际运势，再行购买。</div>
			  <div class="mt5">3、该曲线体现了未来若干日相对运势的好坏。</div>
              <div class="mt5">4、运势分为主观部分和客观部分，主观部分代表心情，客观部分代表实际。</div>
              <div class="mt5">5、运势只提供未来若干日走势，峰顶代表了主观/客观部分最好的一天，峰谷代表了主观/客观部分最差的一天。分数越高，运势相比越好。</div>
              <div class="mt5">6、客观曲线走势代表了事情结果的好坏，主观曲线走势代表了心情上的好坏。</div>
          </div>
          <el-button size="mini" class="mb20 fwbold fs16" style="color:#1E1854" type="text" @click="payList">解锁记录</el-button>
          <div style="text-align:center;color:#666;margin-top:20px;">
                <img src="../assets/kefu.jpg" style="width:30%"  alt="">
                <div style="font-size:14px;margin:10px;padding-bottom:20px">系统问题反馈请长按二维码，添加微信。</div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import wxlogin from 'vue-wxlogin';
import { ImagePreview,Toast } from 'vant';
import {postAction,getAction } from '@/api/manage'
import wx from 'weixin-jsapi'
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  name: 'Login',
  data() {
    return {
      disabledFlag:true,
      showPicker: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      dateNumber:'7',
      showPicker2:false,
      columns:['5','6','7','8','9','10','11','12','13','14'],
      loading:false
    }
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
    datetime() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.birthday;
    },
    sex() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.sex;
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  mounted () {
    if(!this.unionid){
      if(!this.code){
        var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/dailyluck')
        window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
         this.getJsLogin()
      }
    } else {
      console.log('有unionid')
      this.getUserinfo()
    }
  },
  activated() {
     this.getUserinfo()
  },
  methods: {
      imageView(){
        ImagePreview({images:['http://www.miaoxingjundd.com/dailyview.jpg'], closeable: true});
      },
      getJsLogin(){
          const url = '/xcxlove/double/jslogin'
          // const url = '/user/test'
              postAction(url, {code:this.code}).then( res => {
                if(res.data.code == 200){
                    this.userInfo = res.data.msg
                    // this.datetime = this.userInfo.birthday
                    // this.sex = this.userInfo.sex
                    this.$store.commit('setUserInfoLove', this.userInfo)
                    this.getPreluck()
                    this.getBackluck()
                } else if(res.data.code == 400) {
                    this.userInfo = res.data.msg
                    this.$router.push({path:'/loveThreeRegister'})
                }
              })
      },
      getUserinfo(){
          const url = '/xcxlove/userInfo'
              postAction(url, {unionid:this.unionid}).then( res => {
                if(res.data.code == 200){
                    this.userInfo = res.data.msg
                    this.$store.commit('setUserInfoLove', this.userInfo)
                    this.getPreluck()
                    this.getBackluck()
                } else if(res.data.code == 400) {
                    this.$router.push({path:'/loveThreeRegister'})
                }
          })
      },
      getPreluck(){
       const url = '/daily/preluck'
       const currentdate = this.parseTime(new Date(),'{y}-{m}-{d}')
       postAction(url, {currentdate:currentdate,birthday:this.datetime,sex:this.sex}).then(res => {
            console.log('res',res.data)
            let keyList = Object.keys(res.data.data);
            let valueList = Object.values(res.data.data);
            let cailist = valueList.map(item => item.cai)
            let yinlist = valueList.map(item => item.yin)
            keyList = keyList.map(item => {
              let currentdateTime = new Date().getTime()
              currentdateTime = currentdateTime - 86400000 *(7-Number(item))
              currentdateTime = this.parseTime(new Date(currentdateTime),'{m}-{d}')
              return currentdateTime
            })
            this.drawLine1(keyList,cailist,yinlist,'上周运势曲线','#3ba272','main1')
       })
      },
      getBackluck(){
        const url = '/daily/backluck'
        const currentdate = this.parseTime(new Date(),'{y}-{m}-{d}')
        postAction(url, {currentdate:currentdate,birthday:this.datetime,sex:this.sex,num:Number(this.dateNumber)}).then(res => {
              console.log('res',res.data)
              let keyList = Object.keys(res.data.data);
              let valueList = Object.values(res.data.data);
              let cailist = valueList.map(item => item.cai)
              let yinlist = valueList.map(item => item.yin)
              keyList = keyList.map(item => {
                let currentdateTime = new Date().getTime()
                currentdateTime = currentdateTime + 86400000 * Number(item)
                currentdateTime = this.parseTime(new Date(currentdateTime),'{m}-{d}')
                return currentdateTime
              })
              // this.drawLine1(keyList,cailist,yinlist,'后运的运势曲线','#3ba272','main2')
              this.drawLine1(keyList,[],[],'后运的运势曲线','#3ba272','main2')
        })
      },
      onConfirmCity(values) {
        this.provincecity = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
          console.log('values',this.provincecity)
          const city = this.provincecity.split('/')
          this.provincecity = city[1]
        this.showArea = false;
      },
      onConfirmTime(time) {
        console.log('values',time)
        this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
        console.log('values',this.datetime)
        this.showPicker = false;
      },
      onConfirmnum(value) {
        this.dateNumber = value;
        this.showPicker2 = false;
        this.getBackluck()
      },
      selectDate(){
        if(!this.disabledFlag){
          this.showPicker = true
        }
      },
      parseTime (time, cFormat) {
        if (arguments.length === 0) {
          return null
        }
        if (!time) return ''
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
          date = time
        } else {
          if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
          }
          if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
          }
          date = new Date(time)
        }
        const formatObj = {
          y: date.getFullYear(),
          m: date.getMonth() + 1,
          d: date.getDate(),
          h: date.getHours(),
          i: date.getMinutes(),
          s: date.getSeconds(),
          a: date.getDay()
        }
        const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
          let value = formatObj[key]
          // Note: getDay() returns 0 on Sunday
          if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
          if (result.length > 0 && value < 10) {
            value = '0' + value
          }
          return value || 0
        })
        return timeStr
      },
      drawLine1 (yearlist,cailist,yinlist,text,color,divId) {
        var echarts = require('echarts');
        var myChart = echarts.init(document.getElementById(divId));
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.setOptionData(yearlist,cailist,yinlist,text));
      },
      setOptionData(yearlist,cailist,yinlist,text){
      var option = {
          legend: {
              show: true,    // 是否显示图例
                  //图例位置
              right: 0,
              top: 30,
          },
          color:['#0134FF','#FF3E42'],
          xAxis: {
            type: 'category',
            data: yearlist,
            axisLine: {
                lineStyle: {
                    color: '#000'
                }
            },
            axisLabel:{
                interval: 0,
                rotate: 270
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#000'
                }
            },
          },
          series: [
            {
              data: cailist,
              type: 'line',
              smooth: true,
              name: '客观',
            },
            {
              data: yinlist,
              type: 'line',
              smooth: true,
              name: '主观',
            },

          ]
        };
        return option
      },
      payList(){
        this.$router.push({path:'/dailyluckList'})
      },
      gotobackluck(){
        const currentdate = this.parseTime(new Date(),'{y}-{m}-{d}')
        const url = '/daily/addluck'
        const params = {
          unionid:this.unionid,
          openid:this.openid,
          sex:this.sex,
          birthday:this.datetime,
          currentday:currentdate,
          daynum:this.dateNumber
        }
        this.loading = true
        var that = this
        getAction(url, params).then( res => {
            if(res.data.code === 200){
              const result = res.data.msg.result
              wx.config({
                debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                appId: result.appId, // 必填，公众号的唯一标识
                timestamp: result.timeStamp, // 必填，生成签名的时间戳
                nonceStr: result.nonceStr, // 必填，生成签名的随机串
                signature: result.paySign, // 必填，签名
                jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
              })
              wx.ready(() => {
                wx.checkJsApi({
                  jsApiList: ['chooseWXPay'],
                  success:function(res){
                    console.log("success")
                    wx.chooseWXPay({
                        timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                        nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                        package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                        paySign: result.paySign, // 支付签名
                        success: function (res) {  // 支付成功后的回调函数
                          alert("支付成功");
                          that.loading = false
                          window.location.href = "https://online.miaoxingjundd.com/dailyluckback?currentdate=" + currentdate + "&birthday=" +that.datetime + "&sex=" + that.sex + "&num=" + Number(that.dateNumber)
                          // that.$router.push({path:'/careerResult', query:{re_id:this.$route.query.re_id, openid:sessionStorage.getItem("us_openid")}})
                        },
                        fail: function (res) {
                          alert("支付失败");
                          that.loading = false
                        }
                      })
                  },
                  fail:function(res){
                    console.log("fail");
                    that.loading = false
                    console.log(res) }
                })
              })
              wx.error(err => {
                that.loading = false
              })
            }
        })
      },
      // gotobackluck(){
      //   const currentdate = this.parseTime(new Date(),'{y}-{m}-{d}')
      //   const url = '/daily/addluck'
      //   const params = {
      //     unionid:this.unionid,
      //     openid:this.openid,
      //     sex:this.sex,
      //     birthday:this.datetime,
      //     currentday:currentdate,
      //     daynum:this.dateNumber
      //   }
      //   this.loading = true
      //   postAction(url, params).then(res => {
      //      this.loading = false
      //      Toast.success('解锁成功！ ');
      //      this.$router.push({ path: '/dailyluckback', query: { currentdate:currentdate,birthday:this.datetime,sex:this.sex,num:Number(this.dateNumber)}})
      //   })
      // }
  }
}
</script>
<style scoped>
.title-container{
    width: 100%;
    min-height: 100vh;
    background-image: url('../assets/dailyhome.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
}
.content-container{
  box-shadow: 0px 3px 15px #211C4D;
  border-radius: 50px 50px 0px 0px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.back{
  position: relative;
}
.button{
  position: absolute;
  top:30%;
  left: 35%;
  z-index: 9;
}
.divider-line{
  width: 86%;
  margin: 0 auto;
  height: 0px;
  border: 1px dashed #557F42;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
}
.textcard{
  font-size: 12px;
  padding:10px 20px;
  margin: 15px;
  text-align: left;
  /* background: rgb(255,252,244); */
  border-radius: 16px;
  color: #1E1854;
  border: 1px dashed #557F42;
}
.title2{
  font-size: 18px;
  font-weight: bold;
  color: #1E1854;
}
.title4{
  font-size: 16px;
  font-weight: bold;
  color: #1E1854;
}
.title5{
  font-size: 14px;
  font-weight: bold;
  color: #1E1854;
}
.title3{
  font-size: 14px;
  color: #1E1854;
}
/deep/ .choose .van-cell{
  width: 160px;
  background: transparent;
  padding: 10px;
  line-height: 24px;
  font-weight: bold;
}
.buttonclass{
  background: #FF3E42;
  font-size: 14px;
  border: 1px solid #FF3E42;
  border-radius: 0px  13px   0px  13px;
  width: 170px;
}
</style>
