import { render, staticRenderFns } from "./oauserlist.vue?vue&type=template&id=48d77375&scoped=true"
import script from "./oauserlist.vue?vue&type=script&lang=js"
export * from "./oauserlist.vue?vue&type=script&lang=js"
import style0 from "./oauserlist.vue?vue&type=style&index=0&id=48d77375&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d77375",
  null
  
)

export default component.exports