<template>
  <div v-html="html" />
</template>
<script>
import {postAction,getAction } from '@/api/manage'
export default {
  data(){
    return {
      html:''
    }
  },
  computed:{
    weiboid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getweiboid || '';
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  mounted(){
    if(!this.weiboid){
        if(!this.code){
          var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/reliableAlipay')
          window.location.href=`https://api.weibo.com/oauth2/authorize?client_id=3852406503&response_type=code&redirect_uri=`+ url
        }else {
          this.getJsLogin()
        }
    } else {
      this.alipayPayClick()
    }

  },
  methods:{
    alipayPayClick(){
      const url1 = '/wbfunc/wbduanze/addDouble'
        postAction(url1, {weiboid:this.weiboid,otherbirthday: this.$route.query.otherbirthday,othersex: this.$route.query.othersex,othername:this.$route.query.othername}).then(res => {
          if (res.data.code == 200) {
              // 页面传参
              const url = '/wbfunc/total/alppay/start'
              postAction(url,
              {
              weiboid:this.weiboid,
              re_id: res.data.data.Id,
              goodType:'靠谱吗',
              price:9.9,
              return_url: "https://online.miaoxingjundd.com/weiborelialist",
              }).then(res2 => {
                this.html = res2.data.data.form;
                //渲染支付宝支付页面
                this.$nextTick(() =>
                  document.forms[0].submit()
                )
              })
          }
        })

    },
    getJsLogin(){
        const url = '/weibo/wblogin'
        postAction(url, {code:this.code}).then( res => {
          this.userInfo = res.data.data
          this.$store.commit("setweiboid", this.userInfo.id);
          this.$store.commit("setweiboname", this.userInfo.name);
          this.$store.commit("setweiboimg", this.userInfo.cover_image_phone);
          this.$store.commit("setweibofoll", this.userInfo.followers_count);
          this.$store.commit("setweibodomin", this.userInfo.description);
        })
    }
  }
}
</script>
