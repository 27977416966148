<template>
  <div style="padding:30px 20px; background:#fff">
    <h2>OA系统</h2>
    <div>
        <!-- <van-button type="primary" style="margin-top:10px" block @click="submitPlan">提交计划</van-button> -->
        <van-button type="info" round style="margin-top:10px" @click="getNotice" block>公告</van-button>
        <van-button type="info" round style="margin-top:10px" @click="typeInWord" block>录入断语</van-button>
        <van-button type="info" round style="margin-top:10px" @click="generateWord" block>断语生成</van-button>
        <van-button type="info" round style="margin-top:10px" @click="getFlow" block>员工手册</van-button>
        <!-- <van-button type="info" round style="margin-top:10px" block @click="getuserlist">员工列表</van-button> -->
        <van-button v-if="userauth >=7" type="info" round style="margin-top:10px" block @click="getadmin">预约表</van-button>
        <!-- <van-button type="info" round style="margin-top:10px" block @click="onceClear">一键清除</van-button> -->
        <!-- <van-button type="info" round style="margin-top:10px" @click="getMessage" block>工作邮件</van-button> -->
        <van-button v-if="userauth >=7" type="info" round style="margin-top:10px" @click="getaccount" block>记账</van-button>
        <van-button type="info" round style="margin-top:10px" @click="getline" block>长期曲线</van-button>

    </div>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      userauth:'',
      username:'',
      userlist:[]
    }
  },
  created(){
    this.getAuth()
  },
  methods: {
    typeInWord(){
      this.$router.push({name: 'Typeinword'})
    },
    generateWord(){
       this.$router.push({name: 'generateword'})
    },
    getTask(){
       this.$router.push({name: 'oaTask'})
    },
    getNotice(){
       this.$router.push({name: 'oaNotice'})
    },
    getFlow(){
       this.$router.push({name: 'oaFlow',query:{auth:this.userauth}})
    },
    getuserlist(){
        this.$router.push({name: 'oaUserlist'})
    },
    getadmin(){
        this.$router.push({name: 'UserQuery'})
    },
    submitPlan(){
        this.$router.push({name: 'oaPlan', query:{username:this.username}})
    },
    getMessage(){
      this.$router.push({name: 'MessageList'})
    },
    getAuth(){
      const url = '/oa/currlist'
        getAction(url).then( res => {
        this.loading = false
        if( res.data.code == 200) {
            this.userauth = res.data.msg.auth
            this.username = res.data.msg.name
        } else if(res.data.code == 400){
           this.$router.push({name: 'oaLogin'})
        }
        else{
          this.$message.warning(res.data.msg)
        }
        }).catch((err) => {
            this.loading = false
        })
    },
    getaccount(){
      this.$router.push({name: 'Account',query:{auth:this.userauth}})
    },
    getline(){
      this.$router.push({name: 'lifeturn'})
    },
    onceClear(){
      const url = '/oa/refresh'
        getAction(url).then( res => {
        if( res.data.code == 200) {
          this.$message.success(res.data.msg)
        }
        else{
          this.$message.warning(res.data.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
}
</style>

