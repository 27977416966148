<template>
  <div class="login-container">
    <h2>客户成交行为预测系统 1.0</h2>
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户列表" name="first">
          <div class="mt10 tl">
              <van-button icon="plus" round size="small" type="info" @click="addPlan">新增用户</van-button>
              <div class="mt20 tl">
                <el-radio v-model="classify" label="0" @change="getList">未成交</el-radio>
                <el-radio v-model="classify" label="1" @change="getList">已成交（已成交数量越多，供训练特征越丰富，结果越准确）</el-radio>
              </div>
              <el-table
              style="margin-top:20px"
              v-loading="listLoading"
              :data="list"
              border
              fit
              highlight-current-row
              :header-cell-style="{ background: '#f8f8f8' }"
              >
              <el-table-column align="center" label="ID" min-width="50">
                  <template slot-scope="scope">
                  <el-button type="text" @click="handleConfirm(scope.row.Id)">{{scope.$index + 1}}</el-button>
                  </template>
              </el-table-column>
              <el-table-column label="名称" min-width="80" align="center">
                  <template slot-scope="scope">
                  <span>{{ scope.row.clientname }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="是否成交" min-width="60" align="center">
                  <template slot-scope="scope">
                  {{ scope.row.classify1 === '1' ? '成交': '未成交' }}
                  </template>
              </el-table-column>
              <el-table-column label="性别" min-width="60" align="center">
                  <template slot-scope="scope">
                  {{ scope.row.sex }}
                  </template>
              </el-table-column>
              <el-table-column label="生日" min-width="80" align="center">
                  <template slot-scope="scope">
                  {{ scope.row.birth }}
                  </template>
              </el-table-column>
              <el-table-column v-if="classify === '0'" label="成交指数" sortable min-width="60" align="center">
                  <template slot-scope="scope">
                   {{ scope.row.sum }}
                  </template>
              </el-table-column>
              <el-table-column label="操作" min-width="60" align="center">
                  <template slot-scope="scope">
                    <el-button v-if="classify === '0'" type="text" style="padding:0px" 
                        @click="handleConfirm(scope.row.Id, scope.$index)">
                        {{!scope.row.sum ? '确认' : '更新'}}
                    </el-button>
                    <el-button type="text" style="padding:0px" @click="handleDetail(scope.row.Id)">详情</el-button>
                    <el-button type="text" style="padding:0px" @click="handleEdit(scope.row.Id)">编辑</el-button>
                    <el-button type="text" style="padding:0px" @click="handleDelete(scope.row.Id)">删除</el-button>
                  </template>
              </el-table-column>
              </el-table>
          </div>
        </el-tab-pane>
       <van-popup v-model="editShow" position="bottom" :style="{ height: '50%',padding:'10px' }" >
            <van-form @submit="editSubmit">
              <van-field
                v-model="clientname"
                name="姓名"
                label="姓名"
                placeholder="姓名"
              />
              <van-field
                    v-model="intro"
                    rows="2"
                    autosize
                    label="用户简介"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入用户简介"
                    show-word-limit
                    :rules="[{ required: false, message: '请输入用户简介' }]"
              />
            <div style="margin: 16px;">
                <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
            </div>
            </van-form>
       </van-popup>
       <van-popup v-model="show" position="bottom" :style="{ height: '50%',padding:'10px' }" >
            <van-form @submit="onSubmit">
              <van-field
                v-model="clientname"
                name="姓名"
                label="姓名"
                placeholder="姓名"
              />
              <van-field name="radio" label="性别：">
                <template #input>
                    <van-radio-group v-model="sex" direction="horizontal">
                    <van-radio name="男">男</van-radio>
                    <van-radio name="女">女</van-radio>
                    </van-radio-group>
                </template>
              </van-field>
              <van-field name="radio" label="成交状态：">
                <template #input>
                    <van-radio-group v-model="classify1" direction="horizontal">
                    <van-radio name="1">已成交</van-radio>
                    <van-radio name="0">未成交</van-radio>
                    </van-radio-group>
                </template>
              </van-field>
              <van-field
                readonly
                clickable
                name="datetimePicker"
                :value="birth"
                label="用户生日"
                style="margin:16px 0px"
                @click="showPicker3 = true"
              />
              <van-popup v-model="showPicker3" position="bottom">
                <van-datetime-picker
                  type="date"
                  v-model="currentDate"
                  title="选择完整时间"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmTime2"
                  @cancel="showPicker3 = false"
                />
              </van-popup>
              <van-field
                    v-model="intro"
                    rows="2"
                    autosize
                    label="用户简介"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入用户简介"
                    show-word-limit
                    :rules="[{ required: false, message: '请输入用户简介' }]"
              />
            <div style="margin: 16px;">
                <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
            </div>
            </van-form>
       </van-popup>
       <el-dialog title="用户详情" :visible.sync="clientVisiable" width="375px">
        <div class="ml20">
            <div class="content-wrap">
              <div class="contentTitle">姓名</div>
              <div class="content">{{ detailForm.name }}</div>
            </div>
            <div class="content-wrap">
              <div class="contentTitle">性别</div>
              <div class="content">{{ detailForm.sex }}</div>
            </div>
            <div class="content-wrap">
              <div class="contentTitle">出生时间</div>
              <div class="content">{{ detailForm.birth }}</div>
            </div>
            <div class="content-wrap">
              <div class="contentTitle">备注</div>
              <div class="content">{{ detailForm.intro }}</div>
            </div>
            <div v-if="classify === '0'">
              <div class="content-wrap">
                <div class="contentTitle">成交分数</div>
                <div class="content">{{ detailForm.sum }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">理性消费指数：</div>
                <div class="content">{{ char.A }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">冲动消费指数：</div>
                <div class="content">{{ char.P }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">品牌消费指数：</div>
                <div class="content">{{ char.C }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">复购指数：</div>
                <div class="content">{{ work['2'] }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">独立判断指数：</div>
                <div class="content">{{ work['3'] }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">新鲜感指数：</div>
                <div class="content">{{ work['4'] }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">自我消费指数：</div>
                <div class="content">{{ money['2'] }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">价值评估指数：</div>
                <div class="content">{{ money['3'] }}</div>
              </div>
              <div class="content-wrap">
                <div class="contentTitle">请客指数：</div>
                <div class="content">{{ money['4'] }}</div>
              </div>
            </div>

          </div>
        </el-dialog>
    </el-tabs>
  </div>
</template>

<script>
import { postAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      username:'',
      password:'',
      loading: false,
      redirect: undefined,
      activeName:'first',
      listAll:[],
      list:[],
      listLoading:false,
      showCalendar:false,
      show:false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      showPicker3:false,
      birth:'',
      classify:'0',
      classify1:'',
      clientname:'',
      intro:'',
      sex:'',
      analysis:'',
      editShow:false,
      currentRowId:'',
      clientVisiable:false,
      detailForm:{},
      char:{
        A:'',
        P:'',
        C:''
      },
      money:{
        2:'',
        3:'',
        4:''
      },
      work:{
        2:'',
        3:'',
        4:''
      }
    }
  },
  watch: {

  },
  created() {
    const clientName = localStorage.getItem("clientname");
    if (!clientName){
      this.$router.push({name:'clientLogin'})
    }else{
      this.fetchData()
    }    
  },
  methods: {
     handleClick(){
       if(this.activeName === 'second'){
          const url = 'clientCtr/clientpost'
          const name = localStorage.getItem("clientname")
          postAction(url,{name:name}).then( res => {
              this.analysis = JSON.stringify(res.data)
          })
       }
     },
    onConfirmTime2(time) {
      this.birth = this.parseTime(time,'{y}-{m}-{d}' )
      this.showPicker3 = false;
    },
    addPlan(){
      this.show = true
      console.log('新增用户')
    },
    parseTime (time, cFormat) {
      if (arguments.length === 0) {
        return null
      }
      if (!time) return ''
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },
    onSubmit(){
      let url = 'clientCtr/input'
      const name = localStorage.getItem("clientname")
      this.loading = true
      postAction(url,
      {name:name, classify1:this.classify1,clientname:this.clientname,
      intro:this.intro, birth: this.birth,sex:this.sex
      }).then( res => {
            if( res.data.code == 200) {
              this.loading = false
              this.show = false
              this.fetchData()
              this.$message.success('提交成功')
            }
            else{
               this.loading = false
              this.$message.error(res.data.msg)
            }
      })
    },
    editSubmit(){
      const url = 'clientCtr/del_rev'
          postAction(url, {Id: this.currentRowId,status:'R',clientname:this.clientname,intro:this.intro}).then( res => {
              if( res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.fetchData()
                this.editShow = false
              }
              else{
                this.$message.warning(res.data.msg)
              }
          })
    },
    fetchData(){
      this.listLoading = true
      const url = 'clientCtr/list'
      const name = localStorage.getItem("clientname")
      postAction(url,{name:name}).then( res => {
         this.listAll = res.data.msg
         this.list = res.data.msg.F
         this.listLoading = false
      })
    },
    getList(label){
      if(label === '0'){
         this.list = this.listAll.F
      }else {
         this.list = this.listAll.T
      }
    },
    handleDelete(Id){
      const url = 'clientCtr/del_rev'
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          postAction(url, {Id: Id,status:'D'}).then( res => {
              if( res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.fetchData()
              }
              else{
                this.$message.warning(res.data.msg)
              }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    handleEdit(Id){
      this.editShow = true
      this.currentRowId = Id
    },
    handleDetail(Id,index){
      const url0 = 'clientCtr/clientpost'
      const url1 = 'clientCtr/detail'
      const name = localStorage.getItem("clientname")
      this.clientVisiable = true
      postAction(url0,{name:name}).then( res => {
          postAction(url1, {Id: Id}).then( res => {
              if( res.data.code == 200) {
                this.detailForm = res.data.msg
                 if(this.detailForm.charac){
                        this.char = this.detailForm.charac.char
                        this.money= this.detailForm.charac.money
                        this.work= this.detailForm.charac.work
                 }
              }
              else{
                this.$message.warning(res.data.msg)
              }
          })
      })
    },
    handleConfirm(Id,index){
      const url0 = 'clientCtr/clientpost'
      const url1 = 'clientCtr/detail'
      const name = localStorage.getItem("clientname")
      postAction(url0,{name:name}).then( res => {
          this.analysis = JSON.stringify(res.data)
          postAction(url1, {Id: Id}).then( res => {
              if( res.data.code == 200) {
                this.list[index].sum = res.data.msg.sum
              }
              else{
                this.$message.warning(res.data.msg)
              }
          })
      })
    }
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
  padding: 30px;
}
.content-wrap {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right:10px ;
  justify-content: flex-start;
}
.content {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding-bottom: 32px;
  /* width: 70%; */
  font-weight: 500;
 }
.contentTitle {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  align-items: center;
  line-height: 20px;
  margin-right: 8px;
  font-weight: 600;
  padding-bottom: 32px;
  white-space: nowrap;
 }
.contentTitle::after {
    content: ':';
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
  }
</style>

