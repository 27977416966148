<template>
  <div style="padding:0px 20px; background:#fff">
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>公告</h2>
      </div>
      <div style="text-align:left;margin-bottom:10px">
          <van-button icon="plus" round size="small" type="info" @click="show = true">新建公告</van-button>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane v-for="(item, index) in noticeList" :key="index" :label="item.name" :name="`${index}`">
            <div class="scroll-wrapper" style="border: 2px solid rgb(128,128,128);margin-top:20px" @click="openNotice">
              <iframe :src="item.url" frameborder="0" style="width:100%;height:1000px;" id="iframeBox"></iframe>
            </div>
        </el-tab-pane>
      </el-tabs>
      <van-popup v-model="show" position="bottom" :style="{ height: '50%',padding:'10px' }" >
          <van-form @submit="onSubmit">
            <van-field
              v-model="name"
              name="name"
              label="标题"
              placeholder="请填写标题"
            />
            <van-field
              v-model="url"
              name="url"
              label="路径"
              placeholder="请填写路径"
            />
          <div style="margin: 16px;">
              <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
          </div>
          </van-form>
      </van-popup>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      userlist:[],
      activeName:0,
      publicPath: './',
      backgtoundUrl:'<%= BASE_URL %>outside/background.html',
      jobDetailUrl:'<%= BASE_URL %>outside/jobDetail.html',
      backendFlowUrl:'./outside/backendFlow.html',
      frontendFlowUrl:'./outside/frontendFlow.html',
      inviteNewUrl:'./outside/inviteNew.html',
      show:false,
      name:'',
      url:'',
      noticeList:[]
    }
  },
  mounted() {
    this.getNoticeList()
  },
  computed:{
      auth(){
          return this.$route.query.auth
      }
  },
  methods: {
    getActiveName(){
      if(this.auth == 5){
        this.activeName = 'first';
      } else if(this.auth == 2) {
        this.activeName = 'second';
      } else if(this.auth == 3) {
        this.activeName = 'third';
      }
    },
    handleClick(){
      if(this.activeName === 'first'){
      } else if(this.activeName === 'second'){
      }
    },
    onSubmit(){
        const url = '/oa/notice/addlist'
        const params = {name:this.name, url:this.url}
        this.loading = true
        postAction( url, params).then( res => {
        this.loading = false
        if( res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.show = false
          this.getNoticeList()
        }
        }).catch(() => {
            this.show = false
            this.loading = false
        })
    },
    getNoticeList(){
      const url = '/oa/notice/getlist'
        getAction(url).then( res => {
        if( res.data.code == 200) {
            this.noticeList = res.data.msg
        }
        })
    },
    openNotice(url){
       window.open(url)
    }
  }
}
</script>

<style scoped>
.login-container{
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
.scroll-wrapper { 
  -webkit-overflow-scrolling: touch; 
  overflow-y: scroll;
   width: 100%;
 }
.scroll-wrapper iframe { height: 100%; width: 100%; }
</style>

