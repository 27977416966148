<template>
    <div class="table-container">
      <h2 class="mt30 mb30">文案评估结果</h2>
      <el-table :data="items" style="width: 100%" class="ml10 mr10">
        <el-table-column prop="Id" label="序号"></el-table-column>
        <el-table-column prop="content" label="文案内容">
          <template slot-scope="{ row }">
            <span v-if="row.content.length <= 50">{{ row.content }}</span>
            <span v-else class="ellipsis" @mouseover="showTooltip($event, row.content)">{{ row.content.slice(0, 50) }}...</span>
          </template>
        </el-table-column>
        <el-table-column prop="sumevalue" label="基础评估"></el-table-column>
        <el-table-column prop="sumaccuracy" label="基础准确率"></el-table-column>
        <el-table-column prop="singleevalue" label="微调"></el-table-column>
        <el-table-column prop="singleaccuracy" label="微调准确率"></el-table-column>
      </el-table>
      <div class="tooltip" v-if="tooltipVisible" :style="{ top: tooltipTop + 'px', left: tooltipLeft + 'px' }">{{ tooltipContent }}</div>
    </div>
  </template>
  
  <style>
  .table-container {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  
  .ellipsis {
    position: relative;
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .tooltip {
    position: absolute;
    z-index: 999;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    white-space: pre-wrap;
  }
  </style>
  
  <script>
  import { postAction, getAction } from '@/api/manage'
  export default {
    data() {
      return {
        items: [
          { Id: 1,title:'title', sumevalue: '' , content: '这是一段很长很长的文案内容，超过50个字符需要省略', singleevalue: 80, sumaccuracy: '修改建议',singleaccuracy:'80' },
        ],
        tooltipVisible: false,
        tooltipContent: '',
        tooltipTop: 0,
        tooltipLeft: 0,
      };
    },
    computed:{
        openid() {
          // 通过vuex的getters方法来获取state里面的数据
          return this.userinfoCareer.us_openid;
        },
        unionid() {
          // 通过vuex的getters方法来获取state里面的数据
          return this.userinfoCareer.us_unionid;
        },
        userinfoCareer() {
          // 通过vuex的getters方法来获取state里面的数据
          return this.$store.getters.getUserinfoCareer || {};
        },
    },
    mounted() {
      this.getList();
    },
    methods: {
      showTooltip(event, content) {
        this.tooltipContent = content;
        this.tooltipTop = event.clientY + 10;
        this.tooltipLeft = event.clientX + 10;
        this.tooltipVisible = !this.tooltipVisible;
      },
      getList() {
        const params = {
          content: this.articleContent,
          openid: this.openid,
          unionid: this.unionid,
        };
        postAction('/article/hotlist', params).then((res) => {
          if (res.data.code === 200) {
            this.items = res.data.msg;
          }
        });
      }
    },
  };
  </script>