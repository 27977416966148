<template>
  <div v-html="html" />
</template>
<script>
import {postAction,getAction } from '@/api/manage'
export default {
  data(){
    return {
      html:''
    }
  },
  computed:{
    weiboid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getweiboid || '';
    },
  },
  mounted(){
    this.alipayPayClick()
  },
  methods:{
    alipayPayClick(){
      // 页面传参
      const url = '/wbfunc/total/alppay/start'
      postAction(url,
      {
       weiboid:this.weiboid,
       re_id: this.$route.query.re_id,
       goodType:'职业规划',
       price:68,
       return_url:`https://online.miaoxingjundd.com/weibocareerpay?re_id=${this.$route.query.re_id}`,
       quit_url:`https://online.miaoxingjundd.com/weibocareernopay?re_id=${this.$route.query.re_id}`
       }).then(res => {
        this.html = res.data.data.form;
        //渲染支付宝支付页面
        this.$nextTick(() =>
          document.forms[0].submit()
        )
      })
    }
  }
}
</script>
