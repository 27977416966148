<template>
  <div class="content">
        <div class="title fs20 fwbold row-start-col-center pl20">
           <div><img width="60px" style="border-radius:33px" class="mt5"  src="@/assets/logo.jpg" /></div>
           <div class="ml10">喵星君大大奇门遁甲排盘</div>
        </div>
        <div>
            <img style="width:100%" src="@/assets/qimen.png" />
        </div>
        <div class="content pt30 pb50">
            <van-form  @submit="onSubmit">
              <!-- 时间选择器-->
              <van-field
                readonly
                clickable
                name="datetimePicker"
                :value="datetime"
                label="出生时间"
                placeholder="点击选择时间"
                @click="showPicker = true"
                :rules="[{ required: true, message: '请选择出生时间' }]"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker
                  type="datetime"
                  v-model="currentDate"
                  title="选择完整时间"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmTime"
                  @cancel="showPicker = false"
                />
              </van-popup>
              <!-- 方法选择器-->
              <van-field style="margin:30px 0px" name="radio" label="排盘方式">
                <template #input>
                  <van-radio-group v-model="type" direction="horizontal">
                    <van-radio name="1">拆补法</van-radio>
                    <van-radio name="2">置闰法</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <div class="mt20 row-center wrap">
                 <van-button class="roundbutton" @click="quicksubmit">快速排盘(使用当前时间)</van-button>
                 <van-button class="roundbutton mt20" type="info" native-type="submit">排盘</van-button>
              </div>
            </van-form>
        </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      loading:false,
      datetime:'',
      showPicker:false,
      type:'1',
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(1990, 0, 1)
    }
  },
  computed:{

  },
  methods: {
     gotoDetail(){

     },
    onConfirmTime(time) {
      this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}')
      this.showPicker = false;
    },
    onSubmit(){
        this.$router.push({path:'/qimenresult',query:{date:this.datetime,type:this.type,fast:'0'}})
    },
    quicksubmit(){
        const newdate = new Date()
        const current = this.parseTime(newdate,'{y}-{m}-{d}-{h}-{i}' )
        this.$router.push({path:'/qimenresult',query:{date:current,type:this.type,fast:'1'}})
    },
    parseTime (time, cFormat) {
      if (arguments.length === 0) {
        return null
      }
      if (!time) return ''
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },
  }
}
</script>
<style scoped>
.title{
    width: 100%;
    height: 80px;
    background: #C83C23;
    color: #fff;
    font-family: 'Courier New', Courier, monospace;
}
.content{
   background: #fffbf8;
   width: 100%;
}
.roundbutton{
  background:#C83C23;
  border:1px solid #C83C23;
  width:80%;
  padding: 0px 5px;
  border-radius:6px;
  text-align: center;
  font-size: 16px;
  color: #FFF;
}
/deep/.van-field__label{
   width: 6em;
   font-size: 16px;
}
/deep/.van-cell{
  background: #FFF8F2;
  border-radius: 10px;
  font-size: 16px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-radio__label{
  color: #333;
}
/deep/ input{
  color: #333;
  background-color: transparent;
}
</style>
