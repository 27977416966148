<template>
  <div class="content">
      <div class="title fs20 fwbold row-start-col-center">
        <div><img width="60px" style="border-radius:33px" class="mt5"  src="@/assets/logo.jpg" /></div>
        <div class="ml10">喵星君大大奇门遁甲排盘</div>
      </div>
      <div>
          <img style="width:100%" src="@/assets/qimen.png" />
      </div>
      <div class="pt20 pb30 pl25 row-center">
        <div>
            <div><strong>方式：</strong> {{type}}</div>
            <div class="mt10"><strong>时间：</strong> {{datepj}}</div>
            <div class="mt10"><strong>干支：</strong> {{bazi[0]}}{{bazi[1]}}年 {{bazi[2]}}{{bazi[3]}}月 {{bazi[4]}}{{bazi[5]}}日 {{bazi[6]}}{{bazi[7]}}时</div>
            <div class="mt10"><strong>旬空：</strong>
                <span> -- --</span><span class="ml20">-- --</span>
                <span class="ml20">{{kwday[0]}}{{kwday[1]}}</span>
                <span class="ml20">{{kwhour[0]}}{{kwhour[1]}}</span>
            </div>
            <div class="mt10"><strong>节气：</strong> {{jieqi}}
                <span class="ml10">{{bazi[6]}}{{bazi[7]}}时<strong>旬首</strong>为{{zhishi[0].tg}}</span>
                <span class="ml10">{{junum.dun == 1 ? '阳':'阴'}}遁{{big[junum.junum]}}局</span>
            </div>
            <div class="mt10"></div>
            <div class="mt10">
              <span><strong>值符：</strong>{{zhishi[0].star}}落{{big[Number(zhishi[0].gw)]}}宫 </span>
              <span class="ml10"><strong>值使：</strong>{{zhishi[0].door}}落{{big[Number(zhishi[0].gwdoor)]}}宫</span>
            </div>
            <div class="mt20 row-start wrap">
              <!-- 第四宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[4]}}</div>
                          <div class="mt5">{{doorchange[4]}}</div>
                          <div class="mt5">{{tianpanchange[4].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[4].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[4]}}</div>
                    </div>
              </div>
              <!-- 第九宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[9]}}</div>
                          <div class="mt5">{{doorchange[9]}}</div>
                          <div class="mt5">{{tianpanchange[9].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[9].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[9]}}</div>
                    </div>
              </div>
              <!-- 第二宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[2]}}</div>
                          <div class="mt5">{{doorchange[2]}}</div>
                          <div class="mt5">{{tianpanchange[2].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[2].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[2]}}</div>
                    </div>
              </div>
              <!-- 第三宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[3]}}</div>
                          <div class="mt5">{{doorchange[3]}}</div>
                          <div class="mt5">{{tianpanchange[3].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[3].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[3]}}</div>
                    </div>
              </div>
                <!-- 第五宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[5]}}</div>
                          <div class="mt5">{{doorchange[5]}}</div>
                          <div class="mt5">{{tianpanchange[5].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[5].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[5]}}</div>
                    </div>
              </div>
                <!-- 第七宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[7]}}</div>
                          <div class="mt5">{{doorchange[7]}}</div>
                          <div class="mt5">{{tianpanchange[7].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[7].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[7]}}</div>
                    </div>
              </div>
              <!-- 第八宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[8]}}</div>
                          <div class="mt5">{{doorchange[8]}}</div>
                          <div class="mt5">{{tianpanchange[8].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[8].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[8]}}</div>
                    </div>
              </div>
              <!-- 第一宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[1]}}</div>
                          <div class="mt5">{{doorchange[1]}}</div>
                          <div class="mt5">{{tianpanchange[1].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[1].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[1]}}</div>
                    </div>
              </div>
              <!-- 第六宫 -->
              <div class="smallblock width-8 row-center-col-end pt10 pb10">
                    <div class="width-15 col-center">
                          <div>{{godschange[6]}}</div>
                          <div class="mt5">{{doorchange[6]}}</div>
                          <div class="mt5">{{tianpanchange[6].star}}</div>
                    </div>
                    <div class="width-9 col-center">
                        <div v-for="(item,index) in tianpanchange[6].tg" :key="index">{{item}}</div>
                        <div>{{dipanchange[6]}}</div>
                    </div>
              </div>
            </div>
        </div>
      </div>
      <div class="drawRoute"></div>
      <div style="text-align: center; margin-top:20px">
          <img style="width:130px;height:130px" src="../assets/qrcode.jpg" alt="code_image"/>
      </div>
      <div class="tc mt10 pb40 mb10">
          <p>关注微信公众号：喵星君大大</p>
          <p>免费占卜，获取原生家庭性格分析</p>
      </div>
  </div>
</template>
<script>
import {postAction} from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      loading:false,
      datetime:'',
      showPicker:false,
      big:['零','一','二','三','四','五','六','七','八','大','九'],
      junum: {'junum': 7, 'dun': 1},
      dipan:[{'gw': '0', 'dptg': '戊'},{'gw': '7', 'dptg': '戊'}, {'gw': '8', 'dptg': '己'}, {'gw': '9', 'dptg': '庚'}, {'gw': '1', 'dptg': '辛'}, {'gw': '2', 'dptg': '壬'}, {'gw': '3', 'dptg': '癸'}, {'gw': '4', 'dptg': '丁'}, {'gw': '5', 'dptg': '丙'}, {'gw': '6', 'dptg': '乙'}],
      zhishi:[{'gw': '3', 'star': '天任星', 'tg': '戊', 'door': '生门', 'gwdoor': '8'}],
      tianpan: [{'gw': '0', 'star': '天蓬星', 'tg': ['辛']},{'gw': '8', 'star': '天蓬星', 'tg': ['辛']}, {'gw': '3', 'star': '天任星', 'tg': ['己']}, {'gw': '4', 'star': '天冲星', 'tg': ['癸']}, {'gw': '9', 'star': '天辅星', 'tg': ['丁']}, {'gw': '2', 'star': '天英星', 'tg': ['庚']}, {'gw': '7', 'star': '天芮星、天禽星', 'tg': ['壬']}, {'gw': '7', 'star': '天芮星、天禽星', 'tg': ['壬', '丙']}, {'gw': '6', 'star': '天柱星', 'tg': ['戊']}, {'gw': '1', 'star': '天心星', 'tg': ['乙']}],
      door:[{'gw': '0', 'door': '休门'},{'gw': '6', 'door': '休门'}, {'gw': '1', 'door': '生门'}, {'gw': '8', 'door': '伤门'}, {'gw': '3', 'door': '杜门'}, {'gw': '4', 'door': '景门'}, {'gw': '9', 'door': '死门'}, {'gw': '2', 'door': '惊门'}, {'gw': '7', 'door': '开门'}],
      gods: [{'gw': '0', 'gods': '值符'},{'gw': '8', 'gods': '值符'}, {'gw': '3', 'gods': '腾蛇'}, {'gw': '4', 'gods': '太阴'}, {'gw': '9', 'gods': '六合'}, {'gw': '2', 'gods': '白虎'}, {'gw': '7', 'gods': '玄武'}, {'gw': '6', 'gods': '九地'}, {'gw': '1', 'gods': '九天'}],
      bazi: ['癸', '卯', '乙', '卯', '辛', '未', '己', '亥'],
      kwday:['戌', '亥'],
      kwhour: ['辰', '巳'],
      jieqi: '惊蛰',
      godschange:[],
      doorchange:[],
      tianpanchange:[],
      dipanchange:[],
    }
  },
  computed:{
     type(){
       if(this.$route.query.type === '2'){
          return '置闰法'
       } else {
          return '拆补法'
       }
     },
     datepj(){
       if(this.$route.query.fast === '0'){
          const dateList = this.$route.query.date.split('-')
          return dateList[0] + '年' + dateList[1] + '月' + dateList[2] + '日' + dateList[3] + '时' + dateList[4] + '分'
       } else {
          const newdate = new Date()
          const current = this.parseTime(newdate,'{y}-{m}-{d}-{h}-{m}')
          const currentList = this.$route.query.date.split('-')
          return currentList[0] + '年' + currentList[1] + '月' + currentList[2] + '日' + currentList[3] + '时' + currentList[4] + '分'

       }
     }
  },
  mounted(){
    if(this.$route.query.fast === '0'){
       this.getpaipanres('0',this.$route.query.date)
    } else {
       this.getpaipanres('1','0-0-0-0')
    }
  },
  methods: {
   getpaipanres(fast, date){
      const url = '/paipan/qimenpaipan'
      const datelist = date.split('-')
      postAction(url, {fast:fast, year:datelist[0],month:datelist[1],day:datelist[2],hour:datelist[3]}).then( res => {
        this.junum = res.data.msg.junum,
        this.dipan = res.data.msg.dipan,
        this.zhishi = res.data.msg.zhishi,
        this.tianpan = res.data.msg.tianpan,
        this.door = res.data.msg.door,
        this.gods = res.data.msg.gods,
        this.bazi = res.data.msg.bazi,
        this.kwday =res.data.msg.kwday,
        this.kwhour = res.data.msg.kwhour,
        this.jieqi = res.data.msg.jieqi,
        this.tianpan.forEach(item => {
            this.tianpanchange[Number(item.gw)] = {'star': item.star, 'tg': item.tg}
        })
        this.gods.forEach(item => {
            this.godschange[Number(item.gw)] = item.gods
        })
        this.door.forEach(item => {
            this.doorchange[Number(item.gw)] = item.door
        })
        this.dipan.forEach(item => {
            this.dipanchange[Number(item.gw)] = item.dptg
        })
        console.log(this.tianpanchange,this.godschange.length,this.doorchange,this.dipanchange)
      })
    },
    parseTime (time, cFormat) {
      if (arguments.length === 0) {
        return null
      }
      if (!time) return ''
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },
  }
}
</script>
<style scoped>
.title{
    width: 100%;
    height: 80px;
    background: #C83C23;
    color: #fff;
    font-family: 'Courier New', Courier, monospace;
}
.content{
   text-align: left;
   background: #fffbf8;
   width: 100%;
   color: #C4814D;
}
.smallblock{
   background: #fff6f0;
   border: 1px dotted #C83C23;
}
  .drawRoute{
    margin:0px 25px;
    width:88%;
    height:1px;
    padding:0px;
    border-bottom: 1px dotted #C83C23;
    overflow:hidden;
  }
  .width-8{
    width: 32%;
  }
</style>
