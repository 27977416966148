<template>
  <div>
    <div class="love-container">
       <div style="margin-top:200px;text-align:center">
         <img width="80px" src="@/assets/logo.jpg" />
      </div>
      <h3 style="text-align:center;">喵星君大大微博平台</h3>
      <el-divider />
      <van-grid clickable :column-num="3" class="mt30">
        <van-grid-item icon="home-o" text="预约喵君" url="https://mp.weixin.qq.com/s?__biz=MzAxNTE1OTgyNA==&mid=2455422184&idx=1&sn=b2673081e4eb7890f6e4c615a4de2b75&chksm=8c262397bb51aa814a333444b43ff9a53fb763021126757fb3269bb2fee132aa40b1dd77ddd8#rd"/>
        <van-grid-item icon="orders-o" text="使用方法" url="https://mp.weixin.qq.com/s?__biz=MzAxNTE1OTgyNA==&mid=2455422242&idx=1&sn=031179f0dc171242e1affb6e4c40ae98&chksm=8c26225dbb51ab4bfe5562296c7acf27542bc25b714cce1363aee25272d2d62a8d07107dd566#rd"/>
        <van-grid-item icon="wap-home-o" text="原生家庭" url="https://weibo.com/1457750002/4860854515010356"/>
        <van-grid-item icon="bar-chart-o" text="职业规划" @click="openCareer"/>
        <van-grid-item icon="apps-o" text="排盘" @click="openPaipan" />
        <van-grid-item icon="thumb-circle-o" text="靠谱吗" @click="openReliable"/>
      </van-grid>
       <!-- <van-button class="generate2 buttonColor2" round block type="info" native-type="submit" @click="gotoDetail">开始测试</van-button> -->
    </div>
  </div>
</template>
<script>
import { postAction, getAction } from '@/api/manage'
import { Toast } from 'vant';
export default {
  name: 'Login',
  data() {
    return {
        info:{},
        unionid:'',
        userInfo:{}
    }
  },
  computed:{
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
    weiboid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getweiboid || '';
    },
  },
  created() {

  },
  mounted(){
    if(!this.weiboid){
      this.gotoDetail()
    }
  },
  methods: {
      gotoDetail(){
        if(!this.code){
          var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/weiboHome')
          window.location.href=`https://api.weibo.com/oauth2/authorize?client_id=3852406503&response_type=code&redirect_uri=`+ url
        }else {
          this.getJsLogin()
        }
      },
      getJsLogin(){
        const url = '/weibo/wblogin'
            postAction(url, {code:this.code}).then( res => {
               this.userInfo = res.data.data
               this.$store.commit("setweiboid", this.userInfo.id);
               this.$store.commit("setweiboname", this.userInfo.name);
               this.$store.commit("setweiboimg", this.userInfo.cover_image_phone);
               this.$store.commit("setweibofoll", this.userInfo.followers_count);
               this.$store.commit("setweibodomin", this.userInfo.description);
            })
      },
      openCareer(){
        this.$router.push('/weibocareerhome')
      },
      openPaipan(){
        this.$router.push('/paipanWeibo')
      },
      openReliable(){
        // Toast.fail('该功能正在开发中，敬请期待！');
        this.$router.push('/weiboreliahome')
      }
  }
}
</script>
<style scoped>
/**index.wxss**/
.radarSize{
  width: 259px;
  height:191px;
  margin-top: 100px;
}
.listIcon{
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
  /* box-shadow:3px 3px 3px #888888; */
  cursor: pointer;
}
.iconSize{
  font-size: 32px;
}
.sharebutton{
  font-size: 16px;
  background: rgba(255, 145, 124, 0.5);
  width: 100%;
  margin: 0px;
}
.number{
  position: absolute;
  top: 66px;
  left: 50px;
  color: rgb(243,174,176);
  font-size: 18px;
}
.birthday{
  position: absolute;
  top: 40px;
  left: 36%;
  color: rgb(243,174,176);
  font-size: 14px;
}
.threeText{
  color: rgb(243,174,176);
  font-size: 16px;
  text-align: left;
  margin-left: 80px;
}
.char_words{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  margin: 30px 30px 10px 30px;
  min-height: 120px;
  color: rgb(3,0,0);
  font-size: 16px;
  background-image:url('http://www.miaoxingjundd.com/bg2.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  /* font-weight: 600; */
}
.truth_words{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  margin: 10px 30px 0px 30px;
  min-height: 120px;
  color: #030000;
  font-size: 16px;
  background-image:url('http://www.miaoxingjundd.com/bg2.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  /* font-weight: 600; */
}

.content{
  padding: 10px 16px 0px 16px;
  color:#333
}
.love-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: flex-start; */
  box-sizing: border-box;
  background-image: url('../../assets/dailyhome.png');;
  background-repeat:no-repeat;
  background-size: 100% 100%;
  position: relative;
  font-family: 'xinghan';
}

.mt10{
 margin-top: 10rpx;
}

.linkword{
  color: #1890ff;
  text-decoration:underline;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  position: absolute;
  top: 50%;
  margin: 0px 100px;
  z-index: 99999;
  /* margin-top:100rpx */
}
.shared-wrap{
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity:0.5;
  position: absolute;
  z-index: 9999999;
  top:0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shared{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999999;
  top:0px;
  left: 0px;
  opacity:0.8
}

</style>
