<template>
  <div class="top-container">
    <div class="row-center">
      <img src="../assets/title.png" style="width:70%;height: 35px;margin-top: 50px;" />
    </div>
    <div class="pt20 pl30 row-center mt20">
        <div class="pick1" style="padding:0px" @click="addsart">添加成员</div>
        <div class="row-start chooseRadio">
          <div>
              <div class="choosedata" v-if="dateFilterType === 'year'">
                  <van-field
                    v-model="selectedYear"
                    label=""
                    placeholder="选择年份"
                    readonly
                    @click="showYearPicker = true"
                  />
              </div>
              <div class="choosedata" v-if="dateFilterType === 'year-month'">
                  <van-field
                    v-model="selectedYearMonth"
                    label=""
                    placeholder="选择年月"
                    readonly
                    @click="showYearMonthPicker = true"
                  />
              </div>
          </div>
          <van-radio-group class="mr10 ml10" v-model="dateFilterType" @change="selecteYear">
            <van-radio name="year">按年</van-radio>
            <van-radio name="year-month">按年月</van-radio>
          </van-radio-group>
          <van-popup v-model="showYearPicker" position="bottom">
            <van-picker
              v-model="currentYear"
              show-toolbar
              :columns="columns"
              @confirm="onYearConfirm"
              @cancel="showYearPicker = false"
            />
          </van-popup>
          <van-popup v-model="showYearMonthPicker" position="bottom">
            <van-datetime-picker
              v-model="currentYearMonth"
              type="year-month"
              title="选择年月"
              @confirm="onYearMonthConfirm"
              @cancel="showYearMonthPicker = false"
            />
          </van-popup>
        </div>
        <i class="el-icon-question ml5" @click="openHelp"></i>
    </div>
    <div class="centerstar">
      {{name[0]}}
    </div>
    <div class="circle-container">
        <div @click="getInfo(circle)" v-for="circle in circles"
            :key="circle.id"
            class="small-circle"
            :style="{ top: circle.top + '%', left: circle.left + '%',background: circle.gradient }">
            {{circle.othername[0]}}
        </div>
    </div>
    <div v-if="openVisible" class="help">
       <div class="fwbolder">使用方法:</div>
       <div class="tl">1. 你在中心，离你越近的人，在这个时间段里对你的帮助越大。离你越远的人在这个时间段里比较容易成为你的小人。</div>
       <div class="tl">2. 点击你身边的人,就会告诉你如何与他相处，他对你的帮助相比其他人有多大，他在这一年更关注什么，满足他这些，对于你的帮助就会更大。</div>
       <div class="tl">注：每一个星球会以昵称的第一个字命名</div>
       <van-button class="button2Style mt20" block type="info" @click="openVisible = false">确定</van-button>
    </div>
    <div v-if="selectedVisible" class="stardetail">
              <p><strong>他的信息：</strong>{{ currentName }} / {{ currentSex }} / {{ currentBirth }}</p>
              <p><strong>关系：</strong>{{ currentRelation}}</p>
              <p><strong>能力值：</strong>{{ selectedStar.long}}</p>
              <p><strong>相处建议：</strong>{{ selectedStar.along }}</p>
              <p><strong>相处关键词：</strong>{{ selectedStar.detail }}</p>
              <div class="row-spacea">
                <el-button icon="el-icon-delete"  size="mini" class="deleteButton"  @click="deleteItem(circle)">删除</el-button>
                <el-button icon="el-icon-close" size="mini"  class="closeButton" style="margin-left: 4px;" @click="selectedVisible=false">关闭</el-button>
              </div>
     </div>
    <van-popup v-model="showpop" position="bottom" :style="{height: '43%',padding:'10px',display:'flex','align-items':'center','justify-content':'center' }" >
        <van-form @submit="onSubmit">
          <div class="formClass">
            <van-field
              v-model="username"
              name="昵称"
              label="昵称"
              placeholder="昵称"
            />
            <van-field
              readonly
              clickable
              name="datetimePicker"
              :value="otherbirth"
              label="生日"
              style="margin:16px 0px"
              @click="selectDate"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-datetime-picker
                type="date"
                v-model="currentDate"
                title="选择完整时间"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmTime"
                @cancel="showPicker= false"
              />
            </van-popup>
            <van-field name="radio" label="性别" style="margin:16px 0px">
              <template #input>
                <van-radio-group v-model="othersex" :disabled="disabledFlag" direction="horizontal">
                  <van-radio name="男">男生</van-radio>
                  <van-radio name="女">女生</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
                readonly
                clickable
                name="picker"
                :value="relationship"
                label="关系"
                placeholder="点击选择你们的关系"
                @click="showRelationPicker = true"
              />
              <van-popup v-model="showRelationPicker" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="columns2"
                  @confirm="onConfirmrela"
                  @cancel="showRelationPicker = false"
                />
              </van-popup>
            <div class="row-center mt20">
              <van-button :loading="loading" loading-text="请求中..." class="button2Style" block type="info" native-type="submit">
                确定
              </van-button>
            </div>
          </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import { postAction,getAction } from '@/api/manage'
import wx from 'weixin-jsapi'
export default {
  name: 'SolarSystemSimulation',
  data() {
    return {
      selectedVisible: false,
      selectedStar: {
        'along':'',
        'detail':'',
      }, // 当前选中的星球
      showpop:false,
      username:'',
      relationship:'',
      relation:'',
      showRelationPicker:false,
      showPicker: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      loading:false,
      columns:['2023','2024','2025','2026','2027','2028'],
      relationColumns:[{label:'领导',value:'leader'},
                      {label:'朋友',value:'friend'},
                      {label:'同事',value:'colleague'},
                      {label:'下属',value:'subordinate'},
                      {label:'爱人',value:'lover'},
                      {label:'父亲',value:'dad'},
                      {label:'长辈',value:'elder'},
                      {label:'母亲',value:'mum'}],
      columns2:['领导','朋友','同事','下属','爱人','父亲','长辈','母亲'],
      disabledFlag:false,
      image:'../assets/reliableHome.jpeg',
      othersex:'',
      otherbirth: '',
      dateFilterType: 'year', // 'year' 或 'year-month'
      selectedYear: 2023,
      selectedYearMonth: '2023-12',
      showYearPicker: false,
      showYearMonthPicker: false,
      currentYear: new Date(),
      currentYearMonth: new Date(),
      time:0,
      circles: [
          {
              "birthday": "1989-12-31-23",
              "centerid": "1",
              "long": "8.45",
              "name": "111",
              "otherbirth": "1993-07-01-00",
              "othername": "赵",
              "othersex": "男",
              "radius": 0.5,
              "relation": "lover",
              "relationId": 6,
              "sex": "女"
          }
      ],
      currentId:'',
      currentime:2023,
      currentName:'',
      currentSex:'',
      currentBirth:'',
      currentRelation:'',
      openVisible: false,
    };
  },
  mounted() {
    if(this.centerid){
      this.relationList()
    }
  },
  computed: {
    centerid() {
      return this.$route.query.centerid;
    },
    name(){
      return this.$route.query.name;
    },
    birthday(){
      return this.$route.query.birthday;
    },
    sex(){
      return this.$route.query.sex
    },
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
        return this.userinfoLove.unionid;
    }
  },
  methods: {
      selecteYear(){
        console.log('this.dateFilterType',this.dateFilterType)
         if(this.dateFilterType === 'year'){
            this.currentime = this.selectedYear
         } else {
            this.currentime = this.selectedYearMonth
         }
      },
      addRelationship(params){
        if(this.circles.length < 10){
          const url = '/relationship/weixin/addrelation'
          postAction(url, params).then(res => {
            if (res.data.code == 200) {
              this.relationList()
              Toast('点击星星可查看详情');
            }
          })
        } else {
          Dialog.alert({
            message: '最多添加10个成员',
          }).then(() => {
            // on close
          });
        }
      },
      relationList(){
        const location = [
        {x:57,y:63,color1:'#59B3F8',color2:'#5323F6'},
        {x:44,y:53,color1:'#5323F6',color2:'#008CDC'},
        {x:33,y:60,color1:'#A898FB',color2:'#3D0EFF'},
        {x:51,y:71,color1:'#A898FB',color2:'#3D0EFF'},
        {x:70,y:53,color1:'#59B3F8',color2:'#5323F6'},
        {x:35,y:71, color1:'#5323F6',color2:'#008CDC'},
        {x:77,y:70,color1:'#A898FB',color2:'#C00EFF'},
        {x:24,y:50,color1:'#A898FB',color2:'#3D0EFF'},
        {x:60,y:42,color1:'#59B3F8',color2:'#5323F6'},
        {x:30,y:40,color1:'#5323F6',color2:'#008CDC'}
      ]
        const url2 = '/relationship/weixin/subpage'
        postAction(url2, {centerid:this.centerid,birthday:this.birthday,sex:this.sex,name:this.name,payyear:this.currentime}).then(res => {
          if (res.data.code == 200) {
                let sotableId = res.data.msg.sort
                let circlelist = res.data.msg.result 
                let findIndex = {}
                this.circles = sotableId.map((id, index) => {
                  findIndex = circlelist.find(item => item.relationId === id);
                  let gradient = `linear-gradient(90deg, ${location[index].color1}, ${location[index].color2})`;
                  return {
                    top: location[index].y,
                    left: location[index].x,
                    gradient,
                    ...findIndex
                  }
              })
          } else {
            this.circles = []
          }
        })
      },
      getInfo(item){
        var that = this
        const url = '/relationship/weixin/calrelation'
        const selectdItem = this.relationColumns.find(ele => item.relation === ele.value);
        this.currentRelation = selectdItem.label;
        this.currentId = item.relationId
        this.currentName = item.othername
        this.currentSex = item.othersex
        this.currentBirth = item.otherbirth
        if (!this.currentime){
          Toast('请选择年份或年月');
          return
        }
        // if(this.dateFilterType === 'year'){
        //   if(this.currentime >= 2024){
        //     Toast('需要支付解锁查看后面的年份')
        //     return
        //   }
        // } else if(this.dateFilterType === 'year-month'){
        //   const year = this.currentime.split('-')[0]
        //   if(year >= 2024){
        //     Toast('需要支付解锁查看后面的年份')
        //     return
        //   }
        // }
        postAction(url, {Id:item.centerid,payyear:this.currentime,otherbirth:item.otherbirth ,othersex:item.othersex,relationship:item.relation}).then(res => {
          if (res.data.code == 200) {
            this.selectedStar = res.data.msg
            this.selectedVisible = true
          } else if(res.data.code == 400){
                Dialog.confirm({
                title: '提示',
                message: res.data.msg
              }).then(() => {
                const payurl = '/relationship/wxpay'
                getAction(payurl, {centerid: this.centerid, openid: this.openid,payyear:this.currentime}).then( res => {
                    if(res.data.code === 200){
                        const result = res.data.msg.result
                        wx.config({
                          debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                          appId: result.appId, // 必填，公众号的唯一标识
                          timestamp: result.timeStamp, // 必填，生成签名的时间戳
                          nonceStr: result.nonceStr, // 必填，生成签名的随机串
                          signature: result.paySign, // 必填，签名
                          jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
                        })
                        wx.ready(() => {
                          wx.checkJsApi({
                            jsApiList: ['chooseWXPay'],
                            success:function(res){
                              wx.chooseWXPay({
                                  timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                  nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                                  package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                  signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                  paySign: result.paySign, // 支付签名
                                  success: function (res) {  // 支付成功后的回调函数
                                    alert("支付成功");
                                    // 刷新当前页面
                                    that.relationList()
                                  },
                                  fail: function (res) {
                                    alert("支付失败");
                                  }
                                })
                            }
                          })
                        })
                      }
                  })
                }).catch(() => {
                  // on cancel
                });
          }
        })
      },
      deleteItem(){
        Dialog.confirm({
            title: '提示',
            message: '确定删除这颗星星吗？'
          }).then(() => {
            const url = '/relationship/weixin/reviserel'
            postAction(url, {Id:this.currentId,judge:0}).then(res => {
              if (res.data.code == 200) {
                Toast('删除成功');
                this.relationList()
                this.selectedVisible = false
              }
            })
          }).catch(() => {
            // on cancel
          });
      },
      onYearConfirm(value) {
        this.selectedYear = value;
        this.currentime = this.selectedYear
        this.showYearPicker = false;
        this.relationList()
      },
      onYearMonthConfirm(value) {
        this.selectedYearMonth = `${value.getFullYear()}-${value.getMonth() + 1}`;
        this.currentime = this.selectedYearMonth
        this.showYearMonthPicker = false;
        this.relationList()
      },
      onConfirmrela(value) {
      this.relationship = value;
      const selectdItem = this.relationColumns.find(item => item.label === value);
      this.relation = selectdItem.value;
      this.showRelationPicker = false;
    },
    onSubmit(){
        if(!this.username || !this.otherbirth || !this.relation || !this.othersex){
              Toast('请填写完整信息');
              return
        }
        this.showpop = false
        const params = {Id:this.centerid,otherbirth:this.otherbirth,othersex:this.othersex,relationship:this.relation,name:this.username}
        this.addRelationship(params)  
    },
    openHelp(){
      this.openVisible = true
        // Dialog.alert({
        //   message: '用法：1. 你在中心，离你越近的人，在这个时间段里对你的帮助越大。离你越远的人在这个时间段里比较容易成为你的小人。 2. 不同的人，我们取他昵称的第一个字为标记，所以尽量使第一个字不同，以便明显区分。 3. 点击每一个人，都会告诉你如何与他相处，和他在这个时间段里最关注和最想要的东西。满足他这些，对于你的帮助就会更大。',
        //   className: 'custom-alert-message', 
        // }).then(() => {
        //   // on close
        // });
    },
    addsart(){
        this.showpop = true
        this.username = ''
        this.otherbirth = ''
        this.othersex = ''
        this.relationship = ''
    },
    onConfirmTime(time) {
        console.log('values',time)
        this.otherbirth = this.parseTime(time,'{y}-{m}-{d}' )
        console.log('values',this.otherbirth)
        this.showPicker = false;
    },
    selectDate(){
        if(!this.disabledFlag){
          this.showPicker = true
        }
    },
    parseTime (time, cFormat) {
        if (arguments.length === 0) {
          return null
      }
        if (!time) return ''
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
          date = time
        } else {
          if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
          }
          if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
          }
          date = new Date(time)
        }
        const formatObj = {
          y: date.getFullYear(),
          m: date.getMonth() + 1,
          d: date.getDate(),
          h: date.getHours(),
          i: date.getMinutes(),
          s: date.getSeconds(),
          a: date.getDay()
        }
        const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
          let value = formatObj[key]
          // Note: getDay() returns 0 on Sunday
          if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
          if (result.length > 0 && value < 10) {
            value = '0' + value
          }
          return value || 0
        })
        return timeStr
    }
  }
}
</script>
<style scoped>
.top-container{
    width:100%;
    height: 100vh;
    background-image: url('../assets/planetThird.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;
}
.centerstar{
  background: linear-gradient(to bottom,#FFCD75 ,#FF4600) ;
  position: absolute;
  top: 60%;
  left: 51%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.planetTitle{
  padding-top:50px;
  background-image: url('../assets/title.png');
  background-size: 99% 40px;
  background-repeat: no-repeat;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  width: 120px;
  color: white;
  font-size:20px;
}
.circle-container {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-top: 25%;
  margin-left: 10%;
  overflow: visible;
}

.small-circle {
  position: absolute;
  width: 26px;
  height: 26px;
  text-align: center;
  transform: translate(-50%, -50%);
  line-height: 26px; /* 使文本垂直居中 */
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
}

.pick1{
    background-image: url('../assets/chooseButton1.png');
    background-size: 99% 40px;
    background-repeat: no-repeat;
    line-height: 40px;
    text-align: center;
    width: 110px;
    color: white;
    font-size:14px;
}
/deep/ .choosedata .van-cell{
  width: 110px;
  background: transparent;
  line-height: 20px;
  text-align: center;
  background-image: url('../assets/chooseButton2.png');
  background-size: 99% 40px;
  background-repeat:no-repeat;
  padding-left:30px;
  color: white;
}
.chooseRadio{
  margin-left:-10px;
}
/deep/ .van-radio__icon .van-icon {
  width: 1em;
  height: 1em;
  font-size: 0.7em;
  line-height: 1em;
}
/deep/ .choosedata .van-field__label{
  width: 30px;
}
/deep/ .choosedata .van-field__control{
  color: white;
}
/deep/ .chooseRadio .van-radio__label{
  color: rgba(177, 187, 226, 1);
  font-size:14px;
}
/deep/ .chooseRadio .van-radio__icon--checked .van-icon{
  color: #fff;
  background-color: rgba(177, 187, 226, 1);
  border-color: rgba(177, 187, 226, 1);
}
.button2Style{
  background: linear-gradient(to bottom,rgb(89, 179, 248),rgb(83, 35, 246)) ;
  border:1px solid #b1bbe2;
  font-size: 14px;
  text-align:center;
  width:100%;
  height:30px;
  border-radius:6px;
}
.stardetail{
  position: absolute;
  top: 22%;
  left: 6%;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  width: 77%;
  min-height: 40%;
  max-height: 62%;
  overflow: auto;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #b1bbe2;
  padding: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.deleteButton{
  background: linear-gradient(to right,rgb(255, 94, 52),rgb(240, 18, 38)) ;
  border:1px solid white;
  font-size: 14px;
  text-align:center;
  width:90px;
  height:30px;
  border-radius:6px;
  padding: 0px;
  margin: 0px;
  color: white;
}
.closeButton{
  background: linear-gradient(to right,rgb(89, 179, 248),rgb(83, 35, 246)) ;
  border:1px solid white;
  font-size: 14px;
  text-align:center;
  width:90px;
  height:30px;
  border-radius:6px;
  margin-left: 4px;
  padding: 0px;
  margin: 0px;
  color: white;
}
.help{
  position: absolute;
  top: 10%;
  left: 10%;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  width: 70%;
  height: 35%;
  overflow: auto;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #b1bbe2;
  padding: 20px;
}
</style>


