<template>
  <div style="padding:0px 20px; background:#f5f5f5; height:100%">
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>OA系统任务单列表</h2>
      </div>
      <div style="text-align:left;margin-bottom:10px">
          <van-button icon="plus" round size="small" type="info" @click="addPlan">新建计划</van-button>
      </div>
      <van-tabs @click="onClick">
        <van-tab title="本周待完成">
          <van-address-list
          v-model="chosenAddressId"
          :list="listun"
          default-tag-text="默认"
          add-button-text="删除任务"
          @add="onAdd"
          @edit="onEdit"
          @select="onSelect"
          />
        </van-tab>
        <van-tab title="本周已完成">
          <van-address-list
          v-model="chosenAddressId"
          :list="listdo"
          default-tag-text="默认"
          add-button-text="删除任务"
          @add="onAdd"
          @edit="onlook"
          @select="onSelect"
          />
        </van-tab>
      </van-tabs>
       <van-popup v-model="show" position="bottom" :style="{ height: '50%',padding:'10px' }" >
            <van-form @submit="onSubmit">
              <van-field
                readonly
                clickable
                name="picker"
                :value="username"
                label="用户名"
                placeholder="点击选择用户"
                @click="showPicker = true"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="columns"
                  @confirm="onConfirmname"
                  @cancel="showPicker = false"
                />
              </van-popup>
                <van-field
                readonly
                clickable
                name="calendar"
                :value="finishdate"
                label="截至日期"
                placeholder="点击选择截至日期"
                @click="showCalendar = true"
                :rules="[{ required: true, message: '请选择截止日期' }]"
                />
                <van-calendar v-model="showCalendar" @confirm="onConfirmdate" />
                <van-field
                    v-model="plan"
                    rows="2"
                    autosize
                    label="计划"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入计划"
                    show-word-limit
                    :rules="[{ required: true, message: '请输入计划' }]"
                    />
                <van-field name="radio" label="完成状态：">
                <template #input>
                    <van-radio-group v-model="status" :disabled="type === 'add'" direction="horizontal">
                    <van-radio name="待完成">待完成</van-radio>
                    <van-radio name="已完成">已完成</van-radio>
                    </van-radio-group>
                </template>
                </van-field>
            <div style="margin: 16px;">
                <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
            </div>
            </van-form>
       </van-popup>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      chosenAddressId:'',
      loading: false,
      tasklist:[],
      disabledList:[],
      listun:[],
      listdo:[],
      show:false,
      currId:'',
      status:'待完成',
      type:'add',
      value: '',
      columns: ['张旭诏', '许馨', '孙洁婷'],
      showPicker: false,
      username:'',
      password:'',
      redirect: undefined,
      showCalendar: false,
      finishdate:'',
      plan:''
    }
  },
  created(){
    this.getTaskistun()
    this.getTaskistdo()
  },
  methods: {
    onSelect(item){
      this.currId = item.id
    },
    onlook(){
      this.$message.warning('已完成状态下无法编辑')
    },
    onClick(){},
    onConfirmname(value) {
      this.username = value;
      this.showPicker = false;
    },
    onConfirmdate(date) {
      this.finishdate = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    addPlan(){
      this.show = true
      this.type = 'add'
      this.status = '待完成'
    },
    onAdd() {
        if(this.currId){
            const url = '/oa/deletePlan'
            this.loading = true
            postAction( url, {id: this.currId}).then( res => {
            this.loading = false
            if( res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.currId = ''
            this.show = false
            this.getTaskistun()
            this.getTaskistdo()
            }
            else{
            this.$message.warning(res.data.msg)
            }
            }).catch(() => {
                this.show = false
                this.loading = false
            })
        } else {
            this.$message.warning('请勾选数据')
        }
    },
    onEdit(item, index) {
      console.log('item',item)
      const telarray = item.tel.split(',')
      this.type = 'edit'
      this.currId = item.id
      this.username = item.name
      this.finishdate = telarray[0]
      this.plan = item.address
      this.status = telarray[1]
      this.show = true
    },
    getTaskistun(){
      const url = '/oa/planlist/undo'
        getAction(url).then( res => {
        if( res.data.code == 200) {
            this.tasklist = res.data.msg
            if(this.tasklist.length > 0){
                this.listun = this.tasklist.map(item => {
                    return { id:item.id, name:item.name, tel: item.time +','+ item.status, address: item.content  }
                })
            }else{
                this.listun = []
            }
            console.log('this.tasklist',this.tasklist)
        }
        else if(res.data.code == 400){
           this.$router.push({name: 'oaLogin'})
        }
        else{
          this.$message.warning(res.data.msg)
        }
        }).finally(() => {
            this.finished = true
        })
    },
    getTaskistdo(){
      const url = '/oa/planlist/hasdo'
        getAction(url).then( res => {
        if( res.data.code == 200) {
            this.tasklist = res.data.msg
            if(this.tasklist.length > 0){
                this.listdo = this.tasklist.map(item => {
                    return { id:item.id, name:item.name, tel: item.time +','+ item.status, address: item.content  }
                })
            }else{
                this.listdo = []
            }
            console.log('this.tasklist',this.tasklist)
        }
        else if(res.data.code == 400){
           this.$router.push({name: 'oaLogin'})
        }
        else{
          this.$message.warning(res.data.msg)
        }
        }).finally(() => {
            this.finished = true
        })
    },
    onSubmit(){
        const url = this.type === 'add' ? '/oa/plansubmit' : '/oa/updatePlan'
        const params = {id: this.currId,username:this.username,finishdate:this.finishdate, plan:this.plan,status: this.status}
        this.loading = true
        postAction( url, params).then( res => {
        this.loading = false
        if( res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.show = false
          this.currId = ''
          this.getTaskistun()
          this.getTaskistdo()
        }
        else{
          this.$message.warning(res.data.msg)
        }
        }).catch(() => {
            this.show = false
            this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>

